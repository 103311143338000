import { Component, OnInit } from "@angular/core";
import { LoadingController, ModalController, NavParams } from "@ionic/angular";
import { Chapter, UserBook, UserChapter } from "../../interfaces/firebase-interfaces";
import { FirebaseService } from "../../services/firebase.service";

@Component({
  selector: "add-to-popover",
  templateUrl: "./add-to-popover.component.html",
  styleUrls: ["./add-to-popover.component.scss"],
})
export class AddToPopoverComponent implements OnInit {
  chapters: (Chapter | UserChapter)[];
  newListTitle: string;
  selectedBook: string;
  userBooks: UserBook[] = [];
  //libraryTitle: string;

  constructor(
    private modalCtrl: ModalController,
    private fireServ: FirebaseService,
    private navParams: NavParams,
    private loadCtrl: LoadingController
  ) {}

  ngOnInit() {
    // this keeps a wierd bug from popping up when adding to a part
    this.chapters = this.navParams.get("chapters") as (Chapter | UserChapter)[];
    this.getUserListsTitle();
  }

  getUserListsTitle() {
    this.userBooks = this.fireServ.userBooks;
    if (this.userBooks.findIndex((userBook) => userBook.title == "Favorites") == -1) {
      this.userBooks.unshift({ id: "-1", title: "Favorites", owner: "", chapters: [], updatedAt: null, createdAt: null });
    }

    if (this.userBooks.findIndex((userBook) => userBook.title == "Read Later") == -1) {
      this.userBooks.unshift({ id: "-1", title: "Read Later", owner: "", chapters: [], updatedAt: null, createdAt: null });
    }
    this.userBooks.sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  async addToUserList(book?: UserBook) {
    if (!book) {
      if (this.newListTitle == null) {
        this.newListTitle = "myBook #" + (this.userBooks.length + 1);
      }

      book = { id: "-1", title: this.newListTitle, chapters: [], owner: null, createdAt: null, updatedAt: null };

      const foundIndex = this.userBooks.findIndex(
        (storedCollection) => book?.title?.toUpperCase().split(" ").join("") == storedCollection?.title?.toUpperCase().split(" ").join("")
      );

      if (foundIndex > -1) {
        if (!confirm("This part already exists. Would you like to add it to that?")) {
          return;
        }

        book = this.userBooks[foundIndex];
      }
    }
    book.chapters.forEach((chap) => {
      this.chapters.unshift(chap);
    });
    let loadingPopover = await this.loadCtrl.create({ message: "Loading... Please refresh after 30 seconds" });
    await loadingPopover.present();
    await this.fireServ.updateBookList(true, this.chapters, book).finally(() => loadingPopover.dismiss());
    return this.close();
  }

  close() {
    return this.modalCtrl.dismiss();
  }
}
