import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'text-options',
  templateUrl: './text-options.component.html',
  styleUrls: ['./text-options.component.scss'],
})
export class TextOptionsComponent implements OnInit {

  constructor(
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit() {}

  async highlight() {
    await this.popoverCtrl.dismiss({action: 'highlight'})
  }

  async makeNote() {
    await this.popoverCtrl.dismiss({action: 'note'})
  }

}
