import { Injectable } from "@angular/core";
import firebase from "firebase";
import { BehaviorSubject } from "rxjs";
import { ReaderOptions } from "../components/reader-options/reader-options.component";
import { Profile } from "../pages/profile/profile.page";
import { FirebaseService } from "./firebase.service";
import { HelperService } from "./helper.service";
import { ModalController } from "@ionic/angular";
import { Router } from "@angular/router";
import { EditContentDataComponent } from "../components/edit-content-data/edit-content-data.component";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private fireUser: BehaviorSubject<firebase.User> = new BehaviorSubject(null);
  $fireUser = this.fireUser.asObservable();

  private userProfile: BehaviorSubject<Profile> = new BehaviorSubject(null);
  $userProfile = this.userProfile.asObservable();

  constructor(
    private fireServ: FirebaseService, 
    private helperServ: HelperService,
    private modalCtrl: ModalController, 
    private router: Router
    ) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        this.fireUser.next(user);
        this.fireServ.getUserProfileData().then((profile) => {
          this.userProfile.next(profile);
        });
      } else {
        this.fireUser.next(null);
      }
    });
  }

  getFireUser() {
    return this.fireUser.value;
  }

  getUserProfile() {
    return this.userProfile.value;
  }

  setUserProfile(user: Profile) {
    this.userProfile.next({ ...this.userProfile, ...user });
  }

  async saveUserProfile(user: Profile): Promise<void> {
    return this.fireServ
      .saveProfile(user)
      .then(() => {
        this.setUserProfile(user);
      })
      .catch((err) => {
        console.error("Error saving profile", err);
        alert("Could not save your profile. Please try again");
      });
  }

  async saveReaderOptions(options: ReaderOptions) {
    if (this.userProfile.value.readerPreferences && JSON.stringify(options) === JSON.stringify(this.userProfile.value.readerPreferences)) {
      return;
    }

    const profile = { ...this.userProfile.value, readerPreferences: options };
    return this.saveUserProfile(profile);
  }

  isEqual(...objects) {
    return objects.every((obj) => JSON.stringify(obj));
  }

  async uploadEpub() {
    
    let profile: Profile = this.getUserProfile();
    let signedIn: boolean = profile ? true : false;
    
    if (!signedIn) {
      this.router.navigate(["login"], { state: { redirectTo: "know-bitz" } });
      return;
    }

    if (!profile || (!profile.firstName && !profile.lastName)) {
      console.log("Profile: ", profile);
      await this.helperServ.presentToast("Please complete your profile before uploading", false, true, -1, {
        title: "Profile",
        path: ["profile"],
      });
      return;
    }

    const modal = await this.modalCtrl.create({
      component: EditContentDataComponent,
      backdropDismiss: true,
      cssClass: "chapter-popover",
      componentProps: { profile: profile },
    });

    modal.onWillDismiss().then((res) => {
      if (res.data) {
        window.location.reload()
        //  const found = this.profile.bits.findIndex((bit) => bit.id == res.data.id);
        //  if (found != -1) {
        //    this.profile.bits[found] = res.data;
        //  } else {
        //    this.profile.bits.push(res.data);
        //  }
      }
    });
    await modal.present();
  }
}
