import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { PopoverController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { stringSimilarity } from "string-similarity-js";
import { OptionsPopoverComponent } from "../../components/options-popover/options-popover.component";
import { Chapter, Library } from "../../interfaces/firebase-interfaces";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";

interface SearchResultLibrary extends Library {
  subscribed: boolean;
}

interface SearchResultChapter extends Chapter {
  subscribed: boolean;
  display: string;
  description: string;
  selected: boolean;
}

@Component({
  selector: "search-results",
  templateUrl: "./search-results.page.html",
  styleUrls: ["./search-results.page.scss"],
})
export class SearchResultsPage implements OnInit, OnDestroy {
  searchTerm: string;
  searching: boolean = false;

  Libraries: SearchResultLibrary[] = [];

  subscriptions: Subscription[] = [];

  searchResults: { chapters: SearchResultChapter[]; libraries: SearchResultLibrary[] } = { chapters: [], libraries: [] };

  selectMode: boolean = false;
  constructor(
    private router: Router,
    private fireServ: FirebaseService,
    private helperServ: HelperService,
    private activeRoute: ActivatedRoute,
    private popoverCtrl: PopoverController,
    private location: Location,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  ngOnInit() {
    const sub1 = this.activeRoute.queryParams.subscribe((params) => {
      if (this.searchTerm != params.q) {
        this.searchTerm = params?.q;
        if (this.Libraries.length > 0) {
          this.getSearchResults();
        }
      }
    });

    const sub2 = this.fireServ.LibrariesObs.subscribe(async (libraries) => {
      if (libraries) {
        this.Libraries = libraries.map((library) => {
          return { ...library, subscribed: false };
        });
        this.getSubscribedContent();
      } else {
        await this.helperServ.presentLoader();
        this.fireServ.getAllContent().finally(async () => {
          this.getSubscribedContent();
          await this.helperServ.dismissLoader();
        });
      }
    });
    this.subscriptions.push(sub1, sub2);
  }

  getSubscribedContent() {
    const sub2 = this.fireServ.getSubscribedContent().subscribe(async (libraryIds) => {
      if (!libraryIds) {
        this.getSearchResults();
        await this.helperServ.dismissLoader();
        return;
      }
      for (let id of libraryIds) {
        const newId = await id;
        const libraryIndex = this.Libraries.findIndex((library) => library.id === newId);
        if (newId && libraryIndex >= 0) {
          this.Libraries[libraryIndex].subscribed = true;
        }
      }
      this.getSearchResults();
      await this.helperServ.dismissLoader();
    });

    this.subscriptions.push(sub2);
  }

  getSearchResults() {
    this.searchResults.chapters = [];
    this.searchResults.libraries = [];
    const search = this.searchTerm.toLowerCase().trim();
    this.setSearchContent(search);
    if (search.match("cafe")) {
      this.setSearchContent(search.split("cafe").join("café"));
    } else if (search.match("café")) {
      this.setSearchContent(search.split("café").join("cafe"));
    }
  }

  setSearchContent(search) {
    this.Libraries.forEach((library) => {
      const match = library.title.toLowerCase().match(search);
      if ((match && match.length > 0) || stringSimilarity(library.title.toLowerCase(), search) > 0.5) {
        this.searchResults.libraries.push({ ...library });
      }

      if (library.chapters) {
        library.chapters.forEach((chapter) => {
          if (chapter.title.toLowerCase().match(search) || stringSimilarity(chapter.title.toLowerCase(), search) > 0.5) {
            const authors = chapter.authors;
            let authorDisplay: string = this.helperServ.buildAuthorDisplay(authors);

            const display = `${chapter.title} | ${library.title}`;
            this.searchResults.chapters.push({
              ...chapter,
              subscribed: library.subscribed,
              display: display,
              description: authorDisplay,
              libraryId: library.id,
              selected: false,
            });
          } else {
            const authors = chapter.authors;
            let authorMatch = false;
            for (let author of authors) {
              if (author.toLowerCase().match(search) || stringSimilarity(author.toLowerCase(), search) > 0.5) {
                authorMatch = true;
              }
            }
            if (authorMatch) {
              let authorDisplay: string = this.helperServ.buildAuthorDisplay(authors);

              const display = `${authorDisplay} | ${chapter.title}`;
              this.searchResults.chapters.push({
                ...chapter,
                subscribed: library.subscribed,
                display: display,
                description: library.title,
                libraryId: library.id,
                selected: false,
              });
            }
          }
        });
      }
    });
    this.cdRef.detectChanges();
  }

  async navigateToOtherPage(chapter: SearchResultChapter) {
    if (chapter == null) {
      console.log("chapterId not found");
      return;
    }

    const index = this.Libraries.findIndex((library) => library.chapters.findIndex((chap) => chap.id === chapter.id) !== -1);
    const library = this.Libraries[index];

    let showPreview = true;
    if (chapter.subscribed) {
      showPreview = false;
    }
    let navigationExtras: NavigationExtras = {
      state: {
        chapter: chapter,
        showPreview: showPreview,
      },
    };
    this.router.navigate(["/" + library.id + "/chapter/" + chapter.id], navigationExtras);
  }

  presentSelectOptions(event) {
    const selectedChapterSections = this.searchResults.chapters.filter((chapter) => chapter.selected);
    if (selectedChapterSections.length === 0) {
      this.helperServ.presentToast("Please select one or more.");
      return;
    }

    const selectedChapters: SearchResultChapter[] = [];
    selectedChapterSections.forEach((chapter) => {
      selectedChapters.push(chapter);
    });
    this.presentOptionsPopover(null, selectedChapters);
  }

  async presentOptionsPopover(event: Event, chapters: SearchResultChapter[]) {
    const popover = await this.popoverCtrl.create({
      component: OptionsPopoverComponent,
      componentProps: { chapters: chapters, contentType: "chapter", library: chapters[0].libraryId },
      event,
      translucent: true,
      backdropDismiss: true,
    });
    popover.onWillDismiss().then((res) => {
      if (res.data && res.data.select) {
        this.selectMode = true;
        chapters[0].selected = true;
      }
    });

    await popover.present();
  }

  disableSelectMode() {
    this.searchResults.chapters.forEach((chapter) => {
      chapter.selected = false;
    });
    this.selectMode = false;
  }

  back() {
    this.location.back();
  }
}
