import { Component, Input, OnInit } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { Plugins, SharePluginWeb } from "@capacitor/core";
import { ActionSheetController, AlertController, LoadingController, ModalController, NavParams, PopoverController } from "@ionic/angular";
import { AngularFireAuth } from "angularfire2/auth";
import { UserBook, UserCollection } from "../../interfaces/firebase-interfaces";
import { AnalyticsService } from "../../services/analytics.service";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";
import { UserService } from "../../services/user.service";
import { MyLibraryAddPopoverComponent } from "../my-library-add-popover/my-library-add-popover.component";

@Component({
  selector: "my-library-popover",
  templateUrl: "./my-library-popover.component.html",
  styleUrls: ["./my-library-popover.component.scss"],
})
export class MyLibraryPopoverComponent implements OnInit {
  @Input() collection: UserCollection;
  @Input() collectionsWithBook: UserCollection[];
  @Input() book: UserBook;
  @Input() segment: string;
  @Input() userCreator: string;
  //myBooksList: any[];
  //bookTitle: string;
  copyLink: string;

  guestMode: boolean = false;

  constructor(
    public navParams: NavParams,
    public popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    public fireServ: FirebaseService,
    public alertController: AlertController,
    private router: Router,
    private actionSheetCtrl: ActionSheetController,
    private analytics: AnalyticsService,
    private helperServ: HelperService,
    private fireAuth: AngularFireAuth,
    private userServ: UserService,
    private loadCtrl: LoadingController
  ) {}

  ngOnInit() {
    if (!this.fireAuth.auth.currentUser) {
      console.log("Not signed in");
      this.guestMode = true;
      return;
    }
    console.log("Book", this.book);
  }

  async removeBook() {
    if (this.guestMode) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    let alert = await this.alertController.create({
      message: "Are you sure you want to remove this?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Yes, I'm sure",
          role: "submit",
          handler: async () => {
            let loadingPopover = await this.loadCtrl.create({ message: "Loading... Please refresh after 30 seconds" });
            await loadingPopover.present();
            if (this.segment === "book") {
              console.log(this.collectionsWithBook);
              this.fireServ
                .removeBook(this.book, this.collectionsWithBook)
                .then((value) => {
                  this.popoverCtrl.dismiss({ removed: true });
                })
                .catch((err) => {
                  console.error(err);
                  this.popoverCtrl.dismiss({ removed: false });
                })
                .finally(async () => await loadingPopover.dismiss());
            } else {
              this.fireServ
                .updateCollectionList(false, [this.book], this.collection)
                .then((value) => {
                  this.popoverCtrl.dismiss({ removed: true });
                })
                .catch((err) => {
                  console.error(err);
                  this.popoverCtrl.dismiss({ removed: false });
                })
                .finally(async () => await loadingPopover.dismiss());
            }
          },
        },
      ],
    });
    await alert.present();
  }

  async addToOptions() {
    if (this.guestMode) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    const modal = await this.modalCtrl.create({
      component: MyLibraryAddPopoverComponent,
      componentProps: { content: this.book },
      backdropDismiss: true,
      cssClass: "add-to-popover",
    });
    await modal.present();
    modal.onDidDismiss().then(() => {
      this.popoverCtrl.dismiss({ removed: true });
    });
  }

  async displayPrintOptions() {
    if (this.guestMode) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    let printing = "this " + this.segment;
    if (this.segment == "chapters") {
      printing = "these chapters";
    }
    let alert = await this.alertController.create({
      message: "Print on-demand is still under development and is not yet available. Coming Soon!", // Are you sure you want to send a print request for ' + printing + '?',
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Okay",
          role: "submit",
          handler: async () => {
            let printRequest = {
              ...this.book,
              chapters: this.book.chapters.map((chapter) => {
                return { title: chapter.title, id: chapter.id, originalChapterId: chapter.originalChapterId, libraryId: chapter.libraryId };
              }),
            };

            let success = await this.fireServ.sendPrintRequest(JSON.stringify(printRequest));
            /* if (success) {
            const toast = await this.toastCtrl.create({
              message: 'We successfully received your print request! We should get back to you in 24 hours with more info.',
              showCloseButton: true,
              position: 'bottom',
              closeButtonText: `Dismiss`,
              duration: 5000
            });
            await toast.present()
          } else {
            const toast = await this.toastCtrl.create({
              message: 'Something went wrong, please try again.',
              showCloseButton: true,
              position: 'bottom',
              closeButtonText: `Dismiss`,
              duration: 3000
            });
            await toast.present()
          } */
          },
        },
      ],
    });
    await alert.present();
  }

  async share() {
    if (this.book.id === "-1") {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    this.copyLink = window.document.location.href
      .replace(this.router.routerState.snapshot.url, "/" + this.userCreator + "/user-book/" + this.book.id)
      .replace(" ", "%20");

    const actionSheet = await this.actionSheetCtrl.create({
      header: "Share link to your custom " + this.book.title + " part?",
      mode: "ios",
      buttons: [
        {
          text: "Copy Link",
          handler: () => {
            //// Different Browser capabilties
            if (window.navigator.userAgent.includes("Chrome")) {
              Plugins.Clipboard.write({ url: this.copyLink })
                .then((value) => {
                  this.copiedMessage("Successfully copied the link to your clipboard!");
                  this.analytics.event_share({ share_method: "link", link: this.copyLink });
                })
                .catch((error) => {
                  console.log(error);
                  this.copiedMessage("Something went wrong. Please try again.");
                });
            } else {
              // Create new element
              var el = document.createElement("textarea");
              // Set value (string to be copied)
              el.value = this.copyLink;
              // Set non-editable to avoid focus and move outside of view
              el.setAttribute("readonly", "");
              //el.style = {position: 'absolute', left: '-9999px'};
              document.body.appendChild(el);
              // Select text inside element

              el.select();
              // Copy text to clipboard
              if (document.execCommand("copy").valueOf()) {
                this.copiedMessage("Successfully copied the link to your clipboard!");
                this.analytics.event_share({ share_method: "link", link: this.copyLink });
              } else {
                this.copiedMessage("Something went wrong. Please try again.");
              }
              // Remove temporary element
              document.body.removeChild(el);
            }
          },
        },
        {
          text: "Share via ... (for mobile & some browsers)",
          handler: () => {
            this.presentShareOptions();
          },
        },
        {
          text: "Cancel",
          role: "cancel",
        },
      ],
    });

    await actionSheet.present();
  }

  async presentShareOptions() {
    await SharePluginWeb.prototype
      .share({
        title: "See cool stuff",
        text: this.copyLink,
        url: this.copyLink,
        dialogTitle: "Share with buddies",
      })
      .then(() => {
        this.analytics.event_share({ share_method: "Native options", link: this.copyLink });
      })
      .catch((error) => {
        console.log(error, "Dang it");
      });
  }

  async copiedMessage(message: string) {
    this.helperServ.presentToast(message, false, true, 5000);
  }

  async navigateTo() {
    if (this.book.id === "-1") {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature");
      return;
    }

    await this.popoverCtrl.dismiss();
    console.log(this.book);
    if (this.book == undefined) {
      console.log("book not found");
    } else {
      let navigationExtras: NavigationExtras = {
        state: {
          showPreview: false,
        },
      };
      await this.router.navigate(["/r/" + this.userServ.getFireUser().uid + "/user-book/" + this.book.id], navigationExtras);
    }
  }
}
