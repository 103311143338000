import { Injectable } from "@angular/core";
import { firestore } from "firebase";
import { UpdateMessageData } from "../components/update-message/update-message.component";
import { getBackendData } from "./cloud-functions";

export interface UpdateMessagesDoc {
  messages: UpdateMessageData[];
}

@Injectable({
  providedIn: "root",
})
export class UpdateMessageService {
  rawUpdateData: UpdateMessageData;

  constructor() {}

  async getMessage(
    page: string,
    messageNum: number = 0,
    collectionId: string = "LatestUpdates",
    docId: string = "update-messages"
  ): Promise<UpdateMessageData | null> {
    const updateMessages: UpdateMessagesDoc = (
      await getBackendData(`${collectionId}/${docId}`, [], ["data"]
      ) as firestore.DocumentData).data as UpdateMessagesDoc;
    this.rawUpdateData = updateMessages.messages[messageNum];
    this.rawUpdateData.scheduledEnd = new Date(this.rawUpdateData.scheduledEnd._seconds * 1000);
    if (!this.rawUpdateData.pages.includes(page) ||
      this.rawUpdateData.scheduledEnd.getTime() < new Date().getTime()) {
      return null;
    }
    this.rawUpdateData.scheduledAt = new Date(this.rawUpdateData.scheduledAt._seconds * 1000);
    return this.rawUpdateData;
  }
}
