import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { IonSelect, ModalController } from "@ionic/angular";
import Epub, { Book, Rendition } from "epubjs";
import html2canvas from "html2canvas";
import { Profile } from "../../pages/profile/profile.page";
import { ChapterService } from "../../services/chapters.service";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";
import { StorageService } from "../../services/storage.service";
import { BIT_TOPICS, BIT_TYPES, RemoteConfigService } from "./../../services/remote-config.service";

var currentEpub: Book;
var rendition: Rendition;

export enum REVIEW_STATUS {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}
export interface UserContent {
  status: "hidden" | "unlisted" | "public";
  title: string;
  type: BIT_TYPES;
  topics: BIT_TOPICS[];
  cover_url: string;

  review_status?: REVIEW_STATUS;

  description?: string;

  path?: string;
  id?: string;
  chapterNumber?: number;
  authors?: string[];
  active?: boolean;

  media?: string[];
  resources?: string[];
  links?: string[];
}

@Component({
  selector: "app-edit-content-data",
  templateUrl: "./edit-content-data.component.html",
  styleUrls: ["./edit-content-data.component.scss"],
})
export class EditContentDataComponent implements OnInit {
  BIT_TYPES_MAP_KEYS = Object.keys(this.remoteConfigServ.BIT_TYPES_MAP);
  BIT_TYPES_MAP = this.remoteConfigServ.BIT_TYPES_MAP;

  BIT_TOPICS_MAP_KEYS = Object.keys(this.remoteConfigServ.BIT_TOPICS);
  BIT_TOPICS_MAP = this.remoteConfigServ.BIT_TOPICS;

  cssPopoverData = {
    cssClass: "editContentSelectItem",
  };

  @Input() contentData: UserContent & { libraryId: string };
  @Input() profile: Profile;

  @ViewChild("subTopicSelect") subTopicSelect: IonSelect;

  statuses = [
    { key: "hidden", label: "Hidden" },
    { key: "unlisted", label: "Unlisted" },
    { key: "public", label: "Public" },
  ];
  contentForm: FormGroup;
  file: File | string = null;
  coverFile: File;
  coverChanged = false;
  mainTopic: string;
  subTopics: string[] = [];

  validation_messages = {
    title: [
      { type: "required", message: "Title is required." },
      { type: "minlength", message: "Please enter a valid title." },
    ],
    authors: [
      { type: "required", message: "Author is required." },
      { type: "minlength", message: "Please enter a valid author." },
    ],
  };

  step = 0;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private helper: HelperService,
    private storageServ: StorageService,
    private fireServ: FirebaseService,
    private chapterServ: ChapterService,
    private cdRef: ChangeDetectorRef,
    private remoteConfigServ: RemoteConfigService
  ) {}

  ngOnInit() {
    this.initForm();
    if (this.contentData && this.contentData.id) {
      this.getWaffleContent().then(() => {
        this.initForm();
      });
    }

    if (!this.profile) {
      this.fireServ
        .getUserProfileData()
        .then((profile) => {
          if (profile) {
            this.profile = profile;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // var element = document.getElementById("html-content-holder");
    // // Global variable
    // var getCanvas;
    // document.getElementById("btn-Preview-Image").addEventListener("click", () => {
    //   html2canvas(element).then((canvas) => {
    //     document.getElementById("previewImage").append(canvas);
    //     getCanvas = canvas;
    //     var link = document.createElement("a");
    //     document.body.appendChild(link);
    //     link.download = "html_image.png";
    //     link.href = canvas.toDataURL("image/png");
    //     link.target = "_blank";
    //     link.click();
    //   });
    // });
    // $("#btn-Convert-Html2Image").on("click", () => {
    //   var imgageData = getCanvas.toDataURL("image/png");
    //   // Now browser starts downloading
    //   // it instead of just showing it
    //   var newData = imgageData.replace(/^data:image\/png/, "data:application/octet-stream");
    //   $("#btn-Convert-Html2Image").attr("download", "GeeksForGeeks.png").attr("href", newData);
    // });
  }

  getWaffleContent() {
    let promisesCountdown = 3;
    return new Promise((resolve, reject) => {
      this.chapterServ
        .getChapterInfo([{ id: this.contentData?.id }], this.contentData?.libraryId, "resources")
        .then((resources) => {
          resources.forEach((resource) => {
            this.contentData.resources = resource.map((res) => {
              return res.data?.link;
            });
          });
        })
        .catch((err) => {
          console.error(err);
          this.contentData.resources = [];
        })
        .finally(() => {
          promisesCountdown -= 1;
          if (promisesCountdown === 0) resolve(true);
        });

      this.chapterServ
        .getChapterInfo([{ id: this.contentData.id }], this.contentData.libraryId, "links")
        .then((resources) => {
          resources.forEach((resource) => {
            this.contentData.links = resource.map((res) => {
              return res.data?.link;
            });
          });
        })
        .catch((err) => {
          console.error(err);
          this.contentData.links = [];
        })
        .finally(() => {
          promisesCountdown -= 1;
          if (promisesCountdown === 0) resolve(true);
        });

      this.chapterServ
        .getChapterInfo([{ id: this.contentData.id }], this.contentData.libraryId, "videos")
        .then((resources) => {
          resources.forEach((resource) => {
            this.contentData.media = resource.map((res) => {
              return res.data?.link;
            });
          });
        })
        .catch((err) => {
          console.error(err);
          this.contentData.media = [];
        })
        .finally(() => {
          promisesCountdown -= 1;
          if (promisesCountdown === 0) resolve(true);
        });
    });
  }

  initForm() {
    this.contentForm = this.formBuilder.group({
      newMediaUrl: new FormControl("", this.urlValidator),
      newResourceUrl: new FormControl("", this.urlValidator),
      newLinkUrl: new FormControl("", this.urlValidator),
      description: new FormControl(this.contentData ? this.contentData.description : ""),
      title: new FormControl(
        this.contentData ? this.contentData.title : "",
        Validators.compose([Validators.required, Validators.minLength(3)])
      ),
      status: new FormControl(this.contentData ? this.contentData.status : "hidden", [Validators.required]),
      topics: new FormControl(this.contentData && this.contentData.topics ? this.contentData.topics : [], [Validators.required]),
      type: new FormControl(this.contentData ? this.contentData.type : "", [Validators.required]),
      cover_url: new FormControl(this.contentData ? this.contentData.cover_url : ""),
      media: new FormArray(this.contentData?.media ? this.contentData.media.map((r) => new FormControl(r)) : []),
      resources: new FormArray(this.contentData?.resources ? this.contentData.resources.map((r) => new FormControl(r)) : []),
      links: new FormArray(this.contentData?.links ? this.contentData.links.map((r) => new FormControl(r)) : []),
      review_status: new FormControl(this.contentData ? this.contentData.review_status : "pending"),
    });

    if (this.contentData && this.contentData.topics) {
      if (this.contentData.topics.length > 0) {
        const topics = [...this.contentData.topics];
        this.mainTopic = topics.splice(0, 1)[0];
        this.subTopics = topics;
      }
    }

    if (this.contentData && this.contentData.path) {
      this.setFile();
    }

    if (this.contentData && this.contentData.cover_url) {
      this.coverFile = new File([], "");
    }
  }

  async setFile() {
    const file = await this.chapterServ.getChapterURL(this.contentData.id, this.contentData.libraryId);
    this.file = file;
    this.displayEpub(file + ".epub");
  }

  async displayEpub(epub) {
    if (rendition) {
      rendition.destroy();
    }
    currentEpub = Epub(epub);
    rendition = currentEpub.renderTo("book", { width: `95%`, height: "95%" });

    rendition.display().then(async () => {
      console.log("Displayed");
    });
  }

  async onEpubChanged(event) {
    console.log(event.target.files);
    this.file = event.target.files[0] as File;
    if (!this.contentForm.value.title) {
      this.contentForm.controls.title.setValue(this.file.name.replace(".epub", ""));
    }
    console.log("Event: ", event.target, this.file);
    this.displayEpub(this.file);
  }

  async onCoverChanged(event) {
    console.log("Event: ", event);
    this.coverFile = event.target.files[0] as File;
    var reader = new FileReader();
    reader.onload = (e) => {
      this.contentForm.controls.cover_url.setValue(e.target.result);
    };
    reader.readAsDataURL(this.coverFile);
    this.coverChanged = true;
  }

  async save() {
    if (!this.file) {
      await this.helper.presentToast("Please upload a file");
      return;
    }

    let cover_url: string;

    if (this.contentData && this.contentData.id) {
      await this.helper.presentLoader("Updating...");

      if (!this.coverFile) {
        const canvas = await html2canvas(document.getElementById("cover"));
        const dataURL = canvas.toDataURL("image/png");
        var base64 = dataURL.split("base64,")[1];
        this.coverFile = new File([base64], this.contentForm.value.title + "_cover.png");

        this.coverChanged = true;
      }

      if (this.coverChanged) {
        cover_url = await this.storageServ.saveCoverPhoto(this.coverFile);
      } else {
        cover_url = this.contentData.cover_url;
      }

      this.fireServ
        .saveUserContent({
          title: this.contentForm.value.title,
          userProfile: this.profile,
          status: this.contentForm.value.status,
          cover_url,
          bitType: this.contentForm.value.type,
          topics: this.contentForm.value.topics,
          description: this.contentForm.value.description,
          media: this.contentForm.value.media,
          resources: this.contentForm.value.resources,
          links: this.contentForm.value.links,
          id: this.contentData.id,
          review_status: this.contentForm.value.review_status,
        })
        .then(async (res) => {
          await this.helper.presentToast("Content has been successfully updated!");
          this.close(res);
        })
        .finally(async () => await this.helper.dismissLoader());
    } else {
      await this.helper.presentLoader("Uploading Content...");

      if (!this.coverFile) {
        const canvas = await html2canvas(document.getElementById("cover"));
        const dataURL = canvas.toDataURL("image/png");
        // var base64 = dataURL.split("base64,")[1];

        var byteString = atob(dataURL.split(",")[1]);

        // separate out the mime component
        var mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        // var blob = new Blob([ab], { type: mimeString });

        this.coverFile = new File([ab], this.contentForm.value.title + "_cover.png", { type: mimeString });
        this.coverChanged = true;
      }

      if (this.coverChanged) {
        cover_url = await this.storageServ.saveCoverPhoto(this.coverFile);
      }

      this.storageServ
        .uploadEpub(this.file, {
          title: this.contentForm.value.title,
          userProfile: this.profile,
          status: this.contentForm.value.status,
          cover_url,
          bitType: this.contentForm.value.type,
          topics: this.contentForm.value.topics,
          description: this.contentForm.value.description,
          media: this.contentForm.value.media,
          resources: this.contentForm.value.resources,
          links: this.contentForm.value.links,
          review_status: this.contentForm.value.review_status,
        })
        .then(async (res) => {
          await this.helper.presentToast(
            "Content has been successfully uploaded! Please allow up to 7 days for your content to be reviewed."
          );
          this.close(res);
        })
        .finally(async () => await this.helper.dismissLoader());
    }
  }

  selected(topic: string) {
    return this.subTopics.findIndex((sub) => sub === topic) != -1;
  }

  mainTopicChanged(event) {
    this.mainTopic = event.detail.value;
    let topics = [this.mainTopic];
    if (this.subTopics.length > 0) {
      topics = [this.mainTopic, ...this.subTopics];
    }
    this.contentForm.controls.topics.setValue(topics);
    this.cdRef.detectChanges();
  }

  async subTopicsChanged(event) {
    if (event.detail.value.length > 3) {
      await this.helper.presentToast("You can only select 3 sub topics");
      this.subTopicSelect.value = this.subTopics;
      if (this.subTopics.length == 0) {
        await this.subTopicSelect.open();
      }
      return;
    }
    this.subTopics = event.detail.value;
    let topics = [this.mainTopic, ...this.subTopics];
    this.contentForm.controls.topics.setValue(topics);
    this.cdRef.detectChanges();
  }

  async remove() {
    if (confirm("Are you sure you want this deleted? This cannot be undone")) {
      await this.helper.presentLoader("Deleting Content...");
      this.storageServ
        .deleteEpub(this.contentData.id)
        .then(async (res) => {
          await this.helper.presentToast("Content has been successfully deleted!");
          this.close("delete");
        })
        .catch(async (err) => {
          await this.helper.presentToast("Could not delete your content, please try again");
        })
        .finally(async () => await this.helper.dismissLoader());
    }
  }

  back() {
    this.step = 0;
  }

  close(chapter?: any) {
    this.modalCtrl.dismiss(chapter);
  }

  urlValidator(control: FormControl): { [s: string]: boolean } {
    if (control.value != "" && !control.value.match(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/)) {
      return { invalidUrl: true };
    }
  }

  addMedia() {
    const media = this.contentForm.controls.media as FormArray;
    media.push(new FormControl(this.contentForm.value.newMediaUrl));
    this.contentForm.controls.newMediaUrl.setValue("");
    console.log(this.contentForm);
  }

  removeMedia(index: number) {
    const media = this.contentForm.controls.media as FormArray;
    media.removeAt(index);
  }

  addResource() {
    const resource = this.contentForm.controls.resources as FormArray;
    resource.push(new FormControl(this.contentForm.value.newResourceUrl));
    this.contentForm.controls.newResourceUrl.setValue("");
  }

  removeResource(index: number) {
    const resource = this.contentForm.controls.resources as FormArray;
    resource.removeAt(index);
  }

  addLink() {
    const link = this.contentForm.controls.links as FormArray;
    link.push(new FormControl(this.contentForm.value.newLinkUrl));
    this.contentForm.controls.newLinkUrl.setValue("");
  }

  removeLink(index: number) {
    const link = this.contentForm.controls.links as FormArray;
    link.removeAt(index);
  }
}
