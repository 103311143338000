import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TocDetailPage } from './toc-detail';
import { TocDetailPageRoutingModule } from './toc-detail-routing.module';
import { ChapterComponent } from '../../components/chapter/chapter.component';
import { ComponentsModule } from '../../components/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TocDetailPageRoutingModule,
    ComponentsModule
  ],
  declarations: [TocDetailPage],

})
export class TocDetailModule {}
