import { Injectable } from '@angular/core';
import { analytics } from 'firebase'

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {
 
  platform;
  userAgent;

  constructor() {
    this.platform = window.navigator.platform
    this.userAgent = window.navigator.userAgent
  }

  event_share(params: any) {
    analytics().logEvent("share", {...params, platform: this.platform, userAgent: this.userAgent})
  }

  event_pageView(params: {
    [key: string]: any;
    page_title?: string;
    page_location?: string;
    page_path?: string;
  }) {
    //console.log("Page view event - ", params)
    analytics().logEvent("page_view", {...params, platform: this.platform, userAgent: this.userAgent})
  }

  event_signup(params: {
    [key: string]: any;
    method?: string;
  }) {
    analytics().logEvent("sign_up", {...params, platform: this.platform, userAgent: this.userAgent})
  }

  event_login(params: {
    [key: string]: any;
    method?: string;
  }) {
    //console.log("Login event - ", params)
    analytics().logEvent("login", {...params, platform: this.platform, userAgent: this.userAgent})
  }

  event_logout() {
      //console.log("Logout event")
      analytics().logEvent("logout")
  }
  
}