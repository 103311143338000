import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { BIT_TYPES } from "../../services/remote-config.service";

export interface Bit {
  id: string;
  title: string;
  libraryId: string;
  type: string | "chapter";
  link: string;
  bitType: BIT_TYPES;
  cover_url: string;
  authors: string[];
  views: number;
  avgRating: number;
  path: string;
}

export interface Viewable {
  libraryId: string;
  startingId: string;
  title: string;
  creators: string[];
  cover_url: string;
  type: BIT_TYPES; // null if collection
  views: number;
  avgRating: number;
  id: string;
}

@Component({
  selector: "app-bit-cover",
  templateUrl: "./bit-cover.component.html",
  styleUrls: ["./bit-cover.component.scss"],
})
export class BitCoverComponent {
  @Input() object: Viewable;
  constructor(private router: Router) {}

  open() {
    if (this.object.type == null) {
      this.router.navigate(["/libraries/" + this.object.libraryId + "/toc"]);
    } else {
      this.router.navigate(["r", this.object.libraryId, `chapter`, this.object.startingId]);
    }
  }
}
