import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConferenceData } from '../../providers/conference-data';

@Component({
  selector: 'page-speaker-detail',
  templateUrl: 'speaker-detail.html',
  styleUrls: ['./speaker-detail.scss'],
})
export class SpeakerDetailPage {
  speaker: any;
  realSpeaker: boolean;

  constructor(
    private dataProvider: ConferenceData,
    private route: ActivatedRoute
  ) {}

  ionViewWillEnter() {
    const speakerId = this.route.snapshot.paramMap.get('speakerId');
    if (Number(speakerId) >= 0) {
      this.realSpeaker = false
      this.dataProvider.load().subscribe((data: any) => {
        if (data && data.speakers) {
          for (const speaker of data.speakers) {
            if (speaker && speaker.id === speakerId) {
              this.speaker = speaker;
              break;
            }
          }
        }
      });
    } else {
      this.speaker = history.state.speaker
      this.realSpeaker = true
    }
  }
}
