import { Component, OnInit } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { Plugins, SharePluginWeb } from "@capacitor/core";
import { ActionSheetController, AlertController, LoadingController, ModalController, NavParams, PopoverController } from "@ionic/angular";
import { AngularFireAuth } from "angularfire2/auth";
import { UserCollection } from "../../interfaces/firebase-interfaces";
import { AnalyticsService } from "../../services/analytics.service";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";
import { MatrixComponent } from "../matrix/matrix.component";

@Component({
  selector: "my-collections-popover",
  templateUrl: "./my-collections-popover.component.html",
  styleUrls: ["./my-collections-popover.component.scss"],
})
export class MyCollectionsPopoverComponent implements OnInit {
  contentTitle: string;
  content: string[];
  myBooksList: any[];
  subSegment: string;
  libraryLink: string;
  copyLink: string;
  library: string;
  customContent: boolean = false;
  isSet: boolean = false;
  collection: UserCollection;

  guestMode: boolean = false;

  constructor(
    public navParams: NavParams,
    public popoverCtrl: PopoverController,
    public fireServ: FirebaseService,
    public alertController: AlertController,
    private router: Router,
    private actionSheetCtrl: ActionSheetController,
    private modalCtrl: ModalController,
    private analytics: AnalyticsService,
    private helperServ: HelperService,
    private fireAuth: AngularFireAuth,
    private loadCtrl: LoadingController
  ) {}

  ngOnInit() {
    if (!this.fireAuth.auth.currentUser) {
      this.guestMode = true;
      // return;
    }

    this.contentTitle = this.navParams.get("contentTitle");
    if (!this.contentTitle) {
      this.contentTitle = (this.navParams.get("libraryLink") as string).split("/")[-1];
    }
    this.libraryLink = this.navParams.get("libraryLink") as string;
    this.collection = this.navParams.get("collection");

    if (window.document.location.href.includes("toc")) {
      this.isSet = true;
    }
    if (window.document.location.href.includes("Build-A-Book")) {
      this.customContent = true;
    }

    this.content = this.navParams.get("list");
    this.subSegment = this.navParams.get("subSegment");

    this.copyLink = window.document.location.href.replace(this.router.routerState.snapshot.url, this.libraryLink).split(" ").join("%20");
    let x = this.libraryLink.split(`/`);

    if (this.libraryLink.includes("Volume")) {
      this.library = x[x.length - 5];
    } else {
      this.library = x[x.length - 3];
    }
  }

  async share() {
    if (this.collection && this.collection.id == "-1") {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    if (this.libraryLink) {
      const actionSheet = await this.actionSheetCtrl.create({
        header: "Share link to " + this.contentTitle + "?",
        mode: "ios",
        //subHeader: copyLink,
        buttons: [
          {
            text: "Copy Link",
            handler: () => {
              //// Different Browser capabilties
              if (window.navigator.userAgent.includes("Chrome")) {
                Plugins.Clipboard.write({ url: this.copyLink })
                  .then((value) => {
                    this.copiedMessage("Successfully copied the link to your clipboard!");
                    this.analytics.event_share({ share_method: "link", link: this.copyLink });
                  })
                  .catch((error) => {
                    console.log(error);
                    this.copiedMessage("Something went wrong. Please try again.");
                  });
              } else {
                // Create new element
                var el = document.createElement("textarea");
                // Set value (string to be copied)
                el.value = this.copyLink;
                // Set non-editable to avoid focus and move outside of view
                el.setAttribute("readonly", "");
                //el.style = {position: 'absolute', left: '-9999px'};
                document.body.appendChild(el);
                // Select text inside element

                el.select();
                // Copy text to clipboard
                if (document.execCommand("copy").valueOf()) {
                  this.copiedMessage("Successfully copied the link to your clipboard!");
                  this.analytics.event_share({ share_method: "link", link: this.copyLink });
                } else {
                  this.copiedMessage("Something went wrong. Please try again.");
                }
                // Remove temporary element
                document.body.removeChild(el);
              }
            },
          },
          {
            text: "Share via ... (for mobile & some browsers)",
            handler: () => {
              this.presentShareOptions();
              //this.socialSharing.
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ],
      });

      await actionSheet.present();
    } else {
      console.log("No link to share");
    }
  }

  async presentShareOptions() {
    if (this.guestMode) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    await SharePluginWeb.prototype
      .share({
        title: "See cool stuff",
        text: this.copyLink,
        url: this.copyLink,
        dialogTitle: "Share with buddies",
      })
      .then(() => {
        this.analytics.event_share({ share_method: "Native options", link: this.copyLink });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async removeCollection() {
    if (this.guestMode) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    let permanentlyDeleteMessage = "";
    if (this.subSegment == "all") {
      permanentlyDeleteMessage = " This collection will be removed permanently.";
    }
    let alert = await this.alertController.create({
      message: "Are you sure you want to remove this collection?" + permanentlyDeleteMessage,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Yes, I'm sure",
          role: "submit",
          handler: async () => {
            let loadingPopover = await this.loadCtrl.create({ message: "Loading... Please refresh after 30 seconds" });
            await loadingPopover.present();
            this.fireServ
              .removeCollection(this.collection)
              .then((value) => {
                this.popoverCtrl.dismiss({ removed: true });
              })
              .catch((err) => {
                console.error(err);
                this.popoverCtrl.dismiss({ removed: false });
              })
              .finally(async () => await loadingPopover.dismiss());
          },
        },
      ],
    });
    await alert.present();
  }

  async displayPrintOptions() {
    if (this.guestMode) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    let alert = await this.alertController.create({
      message: "Print on-demand is still under development and is not yet available. Coming Soon!", // Are you sure you want to send a print request for ' + printing + '?',
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Okay",
          role: "submit",
          handler: async () => {
            let printRequest = { [this.contentTitle]: this.content };

            await this.fireServ.sendPrintRequest(printRequest);
          },
        },
      ],
    });
    await alert.present();
  }

  async openSpreadsheet() {
    if (this.guestMode) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    const modal = await this.modalCtrl.create({
      component: MatrixComponent,
      cssClass: "full-page",
    });

    modal.present();
  }

  async navigateTo() {
    if (this.collection.id == "-1") {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    let navigationExtras: NavigationExtras = {
      state: {
        showPreview: this.router.routerState.snapshot.url.includes("store"),
      },
    };
    await this.router.navigate([`/r/${this.library}/set/${this.contentTitle}`], navigationExtras);

    await this.popoverCtrl.dismiss();
  }

  async copiedMessage(message: string) {
    this.helperServ.presentToast(message, false, true, 5000);
  }
}
