import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import firebase from "firebase";
import { Subscription } from "rxjs";
import { Library } from "../../interfaces/firebase-interfaces";
import { getLibraries } from "../../services/cloud-functions";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";

@Component({
  selector: "libraries",
  templateUrl: "./libraries.page.html",
  styleUrls: ["./libraries.page.scss"],
})
export class LibrariesPage implements OnInit, OnDestroy {
  Libraries: (Library & { subscribed: boolean })[] = [];
  searchTerm: string;
  searching: boolean = false;

  guestMode: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    public router: Router,
    private fireServ: FirebaseService,
    public helperServ: HelperService,
  ) {}

  async ngOnInit() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        this.guestMode = false;
      } else {
        this.guestMode = true;
        return;
      }
    });

    await this.helperServ.presentLoader();

    this.getContent();
  }

  ngOnDestroy(): void {
    this.destorySubs();
  }

  destorySubs() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  async getContent() {
    return new Promise<void>(async (resolve, reject) => {
      const allLibraries = (await getLibraries()).data;
      this.subscriptions.push(
        this.fireServ.getSubscribedContent().subscribe(
          async (libraryIds) => {
            this.Libraries = [];
            for (const id of libraryIds) {
              const newId = await id;
              if (newId && allLibraries.find((library) => library.id === newId)) {
                this.Libraries.push({ ...allLibraries.find((library) => library.id === newId), subscribed: true });
              }
            }
            this.Libraries = this.Libraries.sort((a, b) => {
              if (a.title.toLowerCase() > b.title.toLowerCase()) {
                return 1;
              } else return -1;
            });
            await this.helperServ.dismissLoader();
          },
          (err) => {
            console.error("Error: ", err);
          },
          async () => {
            resolve();
            await this.helperServ.dismissLoader();
          }
        )
      );
    });
  }

  search() {
    console.log(this.searchTerm);
    if (!this.searchTerm || this.searchTerm.length < 3) {
      this.helperServ.presentToast("Please enter 3 or more characters");
      return;
    }

    this.router.navigate(["search-results"], { queryParams: { search_query: this.searchTerm } });
  }

  async handleRefresh(event) {
    this.destorySubs();
    await this.getContent();
    event.target.complete();
  }
}
