import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { ChapterService } from "../../services/chapters.service";

@Component({
  styleUrls: ["./chapter-links-popover.scss"],
  template: `
    <ion-header>
      <ion-toolbar mode="md">
        <ion-title>Related Links</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="close()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list *ngFor="let link of chapLinks">
        <ion-button *ngIf="chapLinks.length > 0" (click)="open(link.link)">{{ link.title }}</ion-button>
      </ion-list>
      <ion-item *ngIf="chapLinks.length == 0">
        No links are currently available for this bit. Please suggest one on our support page. Click the "?" shortcut in the top right here
        or in the main menu.
      </ion-item>
    </ion-content>
  `,
})
export class LinkPopover {
  chapLinks: any[] = [];
  linkToAdd: any = [];
  downloadLink: any = [];
  library: string;
  constructor(public modalCtrl: ModalController, private chapService: ChapterService, private navParams: NavParams) {
    this.library = this.navParams.get("library");
    let chapters = this.navParams.get("chapters");
    /* this.chapService.getChapterLinks(chapters, this.library).subscribe((links: any[]) => {
        links.forEach((linkData: any) => {
          this.linkToAdd = {
            link: linkData.payload.doc.data()['link'],
            title: linkData.payload.doc.data()['title'],
            description: linkData.payload.doc.data()['description'],
            id: linkData.payload.doc.id
          }
          this.chapLinks.splice(this.chapLinks.length, 1, this.linkToAdd)
        })
      }); */

    this.chapService.getChapterInfo(chapters, this.library, "links").then((links) => {
      console.log("Links");
      links.forEach((link) => {
        link.forEach((linkData) => {
          this.linkToAdd = {
            link: linkData.data.link,
            title: linkData.data.title,
            description: linkData.data.description,
            id: linkData.id,
          };
          this.chapLinks.splice(this.chapLinks.length, 1, this.linkToAdd);
        });
      });
    });

    /*this.chapService.getURL(id + '.epub').then((epubUrl) => {
        this.downloadLink = {
          epubLink: epubUrl,
          title: "Download To Device",
          id: id
        }
      })*/
    //this.chapService.getURL(id + '.pdf').then((pdfUrl) => {
    //  this.downloadLink = this.downloadLink + {pdfLink: pdfUrl}
    //})
  }

  close() {
    this.modalCtrl.dismiss();
  }

  open(url: string) {
    window.open(url, "_blank");
    this.close();
  }
}
