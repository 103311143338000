import firebase, { firestore } from "firebase";

// export const app = firebase.initializeApp(environment.firebase);
// firebase.functions().useFunctionsEmulator("http://127.0.0.1:5001");

export const initCustomer = firebase.app().functions().httpsCallable("initCustomer");
export const createPaymentSource = firebase.app().functions().httpsCallable("createPaymentSource");
export const createSubscription = firebase.app().functions().httpsCallable("createSubscription");
export const getUserBook = firebase.app().functions().httpsCallable("getUserBook");
export const getUserChapter = firebase.app().functions().httpsCallable("getUserChapter");
export const getVolumeChaptersRef = firebase.app().functions().httpsCallable("getVolumeChaptersRef");
export const getChapterData = firebase.app().functions().httpsCallable("getChapterData");
export const getUserProfile = firebase.app().functions().httpsCallable("getUserProfile");
export const reactivateSubscription = firebase.app().functions().httpsCallable("reactivateSubscription");

export const subscribe = firebase.app().functions().httpsCallable("subscribe");
export const getLinkCount = firebase.app().functions().httpsCallable("getLinkCount");
// from firebase.service.ts
export const getLibraries = firebase.app().functions().httpsCallable("getLibraries");
export const getLatestUpdates = firebase.app().functions().httpsCallable("getLatestUpdates");
export const getUserBits = firebase.app().functions().httpsCallable("getUserBits");
export const saveUserContent = firebase.app().functions().httpsCallable("saveUserContent");
export const setPromoCode = firebase.app().functions().httpsCallable("setPromoCode");
export const addToCollectionList = firebase.app().functions().httpsCallable("addToCollectionList");
export const removeFromCollectionList = firebase.app().functions().httpsCallable("removeFromCollectionList");
export const getUserOptions = firebase.app().functions().httpsCallable("getUserOptions");
export const addNote = firebase.app().functions().httpsCallable("addNote");
export const addToBookList = firebase.app().functions().httpsCallable("addToBookList");
export const removeFromBookList = firebase.app().functions().httpsCallable("removeFromBookList");
export const addToChapterList = firebase.app().functions().httpsCallable("addToChapterList");
export const removeFromChapterList = firebase.app().functions().httpsCallable("removeFromChapterList");
export const addView = firebase.app().functions().httpsCallable("addView");
export const getViews = firebase.app().functions().httpsCallable("getViews");
export const addReview = firebase.app().functions().httpsCallable("addReview");
export const deleteReview = firebase.app().functions().httpsCallable("deleteReview");
export const getAllReviews = firebase.app().functions().httpsCallable("getAllReviews");
export const getPrevReview = firebase.app().functions().httpsCallable("getPrevReview");
export const uploadCollectionFromBits = firebase.app().functions().httpsCallable("uploadCollectionFromBits");
export const createConnectedAccountOnboardingLink = firebase.app().functions().httpsCallable("createConnectedAccountOnboardingLink");
export const createConnectedAccountDashboardLink = firebase.app().functions().httpsCallable("createConnectedAccountDashboardLink");

const setBackend = firebase.app().functions().httpsCallable("setBackendData");

export async function getBackendData(
  path: string,
  where: (any | any[])[][],
  values: string[]
): Promise<firestore.DocumentData | firestore.DocumentData[]> {
  return (await firebase.app().functions().httpsCallable("getBackendData")({ path: path, where: where, values: values })).data;
}
export async function setBackendData(path: string = "", operation: "update" | "set" | "delete", data: Object): Promise<boolean> {
  return setBackend({
    path: path,
    operation: operation,
    data: data,
  })
    .then((res) => {
      if (res.data) {
        return res.data;
      }

      return false;
    })
    .catch((err) => {
      console.error("Error in setdata", err);
      return false;
    });
}
