import { Component, Input, OnInit } from "@angular/core";
import { NavParams, PopoverController } from "@ionic/angular";

export interface ReaderOptions {
  paginated: boolean;
  font_size: number;
  font_style: string;
  background_color: string;
  font_color: string;
}
@Component({
  selector: "reader-options",
  templateUrl: "./reader-options.component.html",
  styleUrls: ["./reader-options.component.scss"],
})
export class ReaderOptionsComponent implements OnInit {
  @Input() highlight: boolean;
  @Input() font_size: number;
  @Input() font_style: string;
  @Input() font_color: string;
  @Input() paginated: boolean = true;
  @Input() background_color: string;

  @Input() changeFontSize: (size: number) => {};
  @Input() changeFontStyle: (style: string) => {};
  @Input() changeBackgroundColor: (color: string) => {};
  @Input() changeFontColor: (color: string) => {};
  @Input() toggleView: (paginated: boolean) => {};

  constructor(private navParams: NavParams, private popoverCtrl: PopoverController) {}

  ngOnInit() {}

  updateFontColor(color: string) {
    this.font_color = color;
    this.changeFontColor(this.font_color);
  }

  updateBackgroundColor(backgroundColor: string) {
    this.background_color = backgroundColor;
    this.changeBackgroundColor(this.background_color);
  }

  updateFontStyle(fontStyle: string) {
    this.font_style = fontStyle;
    console.log(this.font_style, fontStyle);
    this.changeFontStyle(this.font_style);
  }

  changeSize(size: number | string, custom = false) {
    if (typeof size == "string") {
      size = Number(size);
    }

    if (custom) {
      size = size - this.font_size;
    }

    console.log("Size: ", size);
    if ((this.font_size !== 0 || size > 0) && this.font_size + size <= 99 && this.font_size + size >= 1) {
      this.font_size += size;
      this.changeFontSize(size);
    }
  }

  togglePaginated(paginated: boolean) {
    this.paginated = paginated;
    this.toggleView(paginated);
    // this.popoverCtrl.dismiss({ paginated }, "togglePaginated");
  }
}
