import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { UserData } from '../../providers/user-data';
import { AuthService } from '../../services/auth.service';
import { PopoverController } from '@ionic/angular';
import { ConfirmDeletionComponent } from '../confirm-deletion/confirm-deletion.component';
import { Profile } from '../../pages/profile/profile.page';

@Component({
  selector: 'app-logout-popover',
  templateUrl: './logout-popover.component.html',
  styleUrls: ['./logout-popover.component.scss'],
})
export class LogoutPopoverComponent implements OnInit {
  @Input("profile") profile: Profile;

  constructor(
    public router: Router,
    public userData: UserData,
    private authServ: AuthService,
    private popoverCtl: PopoverController,
    ) {}

  ngOnInit() {}

  logout() {
    this.userData.logout();
    this.authServ.doLogout().then((value) => {
      this.router.navigateByUrl("/login");
    });
    this.popoverCtl.dismiss();
  }

  async deleteAccount() {
    const popover = await this.popoverCtl.create({
      component: ConfirmDeletionComponent,
      cssClass: "delete-acc-popover",
      componentProps: {"profile": this.profile}
    });
    this.popoverCtl.dismiss();
    await popover.present();
  }

}
