import { Component } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { ActionSheetController, NavController, PopoverController } from "@ionic/angular";
import { ChapterService } from "../../services/chapters.service";

import { OptionsPopoverComponent } from "../../components/options-popover/options-popover.component";
import { Chapter } from "../../interfaces/firebase-interfaces";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";

interface SelectChapter extends Chapter {
  selected: boolean;
}
@Component({
  selector: "page-toc-detail",
  templateUrl: "toc-detail.html",
  styleUrls: ["./toc-detail.scss"],
})
export class TocDetailPage {
  section: any = [];
  sections: {
    sectionNumber: string;
    sectionTitle: string;
    chapters: SelectChapter[];
  }[] = [];
  chapter: SelectChapter;
  chapters: SelectChapter[] = [];
  volNumber: any;
  volTitle: any;
  libraryId: string;
  parentPageLink: string;
  selectMode: boolean = false;

  searchTerm: string = "";
  searching: boolean = false;

  constructor(
    public actionSheetCtrl: ActionSheetController,
    public chapData: ChapterService,
    public router: Router,
    public route: ActivatedRoute,
    public navCtrl: NavController,
    public popoverCtrl: PopoverController,
    private fireServ: FirebaseService,
    private helperServ: HelperService
  ) {}

  async ngOnInit() {
    this.volNumber = this.route.snapshot.paramMap.get("volumeNumber");
    this.libraryId = this.route.snapshot.paramMap.get("libraryId").split(" ").join("_").split("%20").join("_");

    if (!(await this.fireServ.isSubscribedTo(this.libraryId)) && this.router.url.includes("libraries")) {
      this.router.navigate([`/store/${this.libraryId}/toc/toc-detail/Volume/${this.volNumber}`]);
    }

    let urlArray = this.router.routerState.snapshot.url.split("/");
    this.parentPageLink = urlArray.slice(0, urlArray.length - 5).join("/") + "/" + this.libraryId + "/toc";

    this.sections = [];
    this.section = [];
    var sectionNumber, sectionNumber2, chapNum;

    this.chapData.getChapters(this.libraryId, this.volNumber.toString()).subscribe((chaps) => {
      chaps.forEach((chap) => {
        sectionNumber = chap.data["sectionNumber"];
        if (this.sections.findIndex((sec) => sec.sectionNumber === sectionNumber) === -1) {
          this.chapters = [];
          chaps.forEach((chap2) => {
            sectionNumber2 = chap2.data["sectionNumber"];
            if (sectionNumber2 === sectionNumber) {
              chapNum = chap2.data["chapterNumber"];

              let authors = chap2.data["authors"] as Array<string>;

              this.chapter = {
                id: chap2.id,
                title: chap2.data["title"],
                number: chap2.data["chapterNumber"],
                modality: chap2.data["modality"],
                display: chap2.data["title"] + " | " + this.helperServ.buildAuthorDisplay(authors),
                libraryId: this.libraryId,
                type: "chapter",
                link: "/r/" + this.libraryId + "/chapter/" + chap2.id,
                selected: false,
              };

              if (!this.volTitle) {
                this.volTitle = chap2.data["volumeTitle"];
              }

              if (!this.chapters.find((chap3) => chap3.number === chapNum)) {
                this.chapters.splice(this.chapters.length, 1, this.chapter);
              }
            }
          });
          this.chapters.sort((a, b) => Number(a.number) - Number(b.number));
          this.section = {
            sectionNumber: sectionNumber,
            sectionTitle: chap.data["sectionTitle"],
            chapters: this.chapters,
          };
          this.sections.push(this.section);
        }
      });
      this.sections.sort((a, b) => Number(a.sectionNumber) - Number(b.sectionNumber));
    });
  }

  ionViewWillEnter() {
    //var volume = history.state.chapter;
    //this.volTitle = volume['title'];
  }

  search() {
    console.log(this.searchTerm);
    if (!this.searchTerm || this.searchTerm.length < 3) {
      this.helperServ.presentToast("Please enter 3 or more characters");
      return;
    }

    this.router.navigate(["search-results"], { queryParams: { search_query: this.searchTerm } });
  }

  navigateToOtherPage(chapter: any[]) {
    if (chapter == null) {
      console.log("chapterId not found");
    } else {
      let showPreview = false;
      if (this.router.routerState.snapshot.url.includes("store")) {
        showPreview = true;
      }
      let navigationExtras: NavigationExtras = {
        state: {
          chapter: chapter,
          showPreview: showPreview,
        },
      };
      this.router.navigate(["/r/" + this.libraryId + "/chapter/" + chapter["id"]], navigationExtras).catch((error) => {
        console.log(error);
      });
    }
  }

  async presentOptionsPopover(event: Event, chapters: Chapter[]) {
    const popover = await this.popoverCtrl.create({
      component: OptionsPopoverComponent,
      componentProps: { chapters: chapters, contentType: "chapter", library: this.libraryId },
      event,
      translucent: true,
      backdropDismiss: true,
    });
    await popover.present();
  }

  presentSelectOptions(event) {
    console.log("Event: ", event, this.sections);
    const selectedChapterSections = this.sections.filter((section) => section.chapters.filter((chap) => chap.selected).length > 0);
    if (selectedChapterSections.length === 0) {
      this.helperServ.presentToast("Please select one or more.");
      return;
    }

    const selectedChapters: SelectChapter[] = [];
    selectedChapterSections.forEach((section) => {
      const chapters = section.chapters.filter((chap) => chap.selected);
      console.log("Chapters", chapters);
      chapters.forEach((chap) => {
        selectedChapters.push(chap);
      });
    });
    console.log("Present select options for these chapters: ", selectedChapters, selectedChapterSections);
    this.presentOptionsPopover(null, selectedChapters);
  }

  disableSelectMode() {
    /* this.contentInView.map((content) => {
      return {...content, selected: false}
    }) */
    this.selectMode = false;
  }

  goBack() {
    this.navCtrl.navigateBack([this.parentPageLink]);
  }
}
