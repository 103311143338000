import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

@Component({
  selector: "image-popover",
  templateUrl: "./image-popover.component.html",
  styleUrls: ["./image-popover.component.scss"],
})
export class ImagePopoverComponent implements OnInit {
  src: HTMLImageElement;

  constructor(public navParams: NavParams, public modalCtrl: ModalController) {}

  ngOnInit() {
    this.src = document.getElementById("image") as HTMLImageElement;
    this.src.src = this.navParams.get("img").src;
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
