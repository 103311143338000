import { Component, Input, OnInit } from "@angular/core";
import { UpdateMessageService } from "../../services/update-message.service";

export class UpdateMessageData {
  title: string;
  scheduledAt: any; //Timestamp<number>;
  scheduledEnd: any; // Timestamp<number>
  pages: Array<string> = [];
  priority: number;
}

@Component({
  selector: "app-update-message",
  templateUrl: "./update-message.component.html",
  styleUrls: ["./update-message.component.scss"],
})
export class UpdateMessageComponent implements OnInit {
  @Input() location: string;
  updateData: UpdateMessageData;

  constructor(private updateMessageServ: UpdateMessageService) {}

  async ngOnInit() {
    this.updateData = await this.updateMessageServ.getMessage(this.location);
  }
}
