import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { AlertController } from "@ionic/angular";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";

@Component({
  selector: "page-support",
  templateUrl: "support.html",
  styleUrls: ["./support.scss"],
})
export class SupportPage {
  submitted = false;
  supportMessage: string;
  previousURL: string;

  constructor(
    public alertCtrl: AlertController,
    private fireServ: FirebaseService,
    private router: Router,
    private helperServ: HelperService
  ) {}

  ionViewDidEnter() {
    this.previousURL = history.state.previousURL;
  }

  async goBack() {
    await this.router.navigate([this.previousURL], { state: { fromSupport: true } });
  }

  async submit(form: NgForm) {
    this.submitted = true;

    if (form.valid) {
      this.supportMessage = "";
      this.submitted = false;

      let success = this.fireServ.sendSupportMessage(form.value, this.previousURL);
      if (success) {
        this.helperServ.presentToast("Your support request has been sent.");
      } else {
        this.helperServ.presentToast("Your support request could not be sent. Please try again");
      }
    }
  }
}
