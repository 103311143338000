import { Component, Input, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { PromoCode, UserSubscription } from "../../interfaces/firebase-interfaces";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";
import { PaymentService } from "../../services/payment.service";
import { StoreCollection } from "./../../pages/store/store.page";

@Component({
  selector: "payment-popover",
  templateUrl: "./payment-popover.component.html",
  styleUrls: ["./payment-popover.component.scss"],
})
export class PaymentPopoverComponent implements OnInit {
  @Input() product: StoreCollection;

  subscribed: boolean;
  promoCode: string;
  subscription: UserSubscription;
  promoCodeDoc: PromoCode;
  disableButton: boolean;

  constructor(
    private navParams: NavParams,
    public modalCtrl: ModalController,
    private fireServ: FirebaseService,
    private payServ: PaymentService,
    private helper: HelperService
  ) {}

  ngOnInit() {
    this.disableButton = false;
    this.subscribed = this.navParams.get("subscribed");

    if (this.subscribed) {
      this.fireServ
        .getActiveSubscriptionDoc(this.product, ["data"])
        .then((doc) => {
          this.subscription = doc.data as UserSubscription;
        })
        .catch((err) => {
          alert(`Error: ${JSON.stringify(err)}`);
        });
    }
  }

  async ionViewDidEnter() {}

  async unsubscribe() {
    const subscriptionDocData = await this.fireServ.getActiveSubscriptionDoc(this.product, ["data", "id"]);
    if (subscriptionDocData) {
      const subscriptionData = subscriptionDocData.data as UserSubscription;

      if (confirm("Are you sure you want to unsubscribe?" + (subscriptionData.type === "one-time" ? " This cannot be undone." : ""))) {
        let message = `You've successfully unsubscribed from ` + this.product.title + `!`;
        let success = true;

        if (subscriptionData.stripeLink && subscriptionData.type !== "one-time") {
          console.log("subscriptionData: ", subscriptionData);

          this.helper.presentLoader();
          success = await this.payServ.unsubscribe(subscriptionDocData.id);
          this.helper.dismissLoader();
          message =
            message +
            " This subscription will cancel at the end of your billing period. For more information, please visit your payment portal.";
        } else {
          success = await this.fireServ.unsubscribeTo(this.product);
        }

        if (success) {
          alert(message);
          this.modalCtrl.dismiss({ subscribed: subscriptionData.stripeLink && subscriptionData.type !== "one-time" ? true : false });
        } else {
          alert(`Something went wrong with unsubscribing. Please try again or contact myLibrary if the problem persists.`);
        }
      }
    }
  }

  async reactivateSubscription() {
    if (confirm("Are you sure you want to reactivate your subscription?")) {
      this.helper.presentLoader();
      const success = await this.payServ.changeCancelAtPeriodEnd(this.product);
      this.helper.dismissLoader();
      if (success) {
        alert("Your subscription has been reactivated and will not cancel at the end of the billing period.");
        this.modalCtrl.dismiss();
      } else {
        alert("Something went wrong, please try again");
      }
    }
  }

  async subscribe() {
    if (this.product.price > 0) {
      // make sure customer is created, user has a payment source, and subscribe them
      this.helper.presentLoader();
      this.payServ.initCustomer().then((customer) => {
        this.payServ.subscribe(this.product).then(async () => {
          if (this.promoCodeDoc) {
            if (this.promoCodeDoc.availableUses) {
              this.promoCodeDoc.availableUses -= 1;
              await this.fireServ.setPromoCode(this.promoCodeDoc, this.product.id);
            }
          }
        });
      });
    } else {
      const success = await this.fireServ.subscribeTo(this.product, { type: "monthly" });
      if (success) {
        alert("You are now subscribed to " + this.product.title + "!");
      }
      this.modalCtrl.dismiss({ subscribed: true });
    }
  }

  async applyCode() {
    this.promoCodeDoc = await this.fireServ.getPromoCode(this.promoCode, this.product.id);

    if (this.promoCodeDoc && this.promoCodeDoc.availableUses > 0) {
      if (this.promoCodeDoc.free) {
        if (this.promoCodeDoc.days === -1) {
          await this.fireServ.subscribeTo(this.product, { type: "promo", days: this.promoCodeDoc.days });
          alert(`You are now subscribed to ${this.product.title} for unlimited days!`);
          this.modalCtrl.dismiss({ subscribed: true });
        } else {
          let date = new Date();
          date.setDate(date.getDate() + this.promoCodeDoc.days);
          await this.fireServ.subscribeTo(this.product, { type: "promo", days: this.promoCodeDoc.days, endAt: date });
          alert(`You are now subscribed to ${this.product.title} for the next ${this.promoCodeDoc.days} days! `);
          this.modalCtrl.dismiss({ subscribed: true });
        }
        this.promoCodeDoc.availableUses -= 1;
        await this.fireServ.setPromoCode(this.promoCodeDoc, this.product.id);
      } else {
        this.product.trial_period_days = this.promoCodeDoc.trial_period_days;
        this.product.default_price_id = this.promoCodeDoc.price_id;
        this.product.price = this.promoCodeDoc.price;
        if (this.promoCodeDoc.days) {
          this.product.days = this.promoCodeDoc.days;
        }
        this.product.cycle = this.promoCodeDoc.cycle;
        let message = "Promo Code has been applied!";
        let freeTrial = this.product.trial_period_days ? ` Enjoy your ${this.product.trial_period_days} day free trial!` : "";
        alert(message + freeTrial);
      }
    } else if (this.promoCodeDoc && this.promoCodeDoc.availableUses === 0) {
      this.disableButton = false;
      alert("Expired Promo Code");
    } else {
      this.disableButton = false;
      alert("Invalid Promo Code");
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
