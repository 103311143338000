import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { ComponentsModule } from "../../components/components.module";
import { LibrariesPageModule } from "../libraries/libraries.module";
import { MyBooksModule } from "../my-books/my-books.module";
import { HomePage } from "./home";
import { HomePageRoutingModule } from "./home-routing.module";

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ComponentsModule, HomePageRoutingModule, MyBooksModule, LibrariesPageModule],
  declarations: [HomePage],
})
export class HomeModule {}
