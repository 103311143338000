import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { StoreCollection } from "../../pages/store/store.page";

@Component({
  selector: "app-collection-cover",
  templateUrl: "./collection-cover.component.html",
  styleUrls: ["./collection-cover.component.scss"],
})
export class CollectionCoverComponent implements OnInit {
  @Input() collection: StoreCollection;

  constructor(private router: Router) {}

  ngOnInit() {}

  open() {
    console.log("Navigate");
    this.router.navigate(["know-bitz", this.collection.id, "toc"]);
  }
}
