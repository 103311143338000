import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Plugins, SharePluginWeb } from "@capacitor/core";
import { ActionSheetController, ModalController, NavController, ToastController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { EditContentDataComponent } from "../../components/edit-content-data/edit-content-data.component";
import { UpdateDoc } from "../../interfaces/firebase-interfaces";
import { AnalyticsService } from "../../services/analytics.service";
import { getLibraries } from "../../services/cloud-functions";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";
import { StoreCollection } from "../store/store.page";

@Component({
  selector: "page-home",
  templateUrl: "./home.html",
  styleUrls: ["./home.scss"],
})
export class HomePage implements OnInit, OnDestroy {
  appShareLink: string = "https://www.mylibrary.world";
  contentUpdates: UpdateDoc[];

  subTabs: string[] = ["Build-A-Book", "myPurchases"];

  subTab: "Build-A-Book" | "myPurchases" = "Build-A-Book";

  subscriptions: Subscription[] = [];
  cardContent: StoreCollection[] = [];
  freeContent: StoreCollection[] = [];

  searchTerm: string = "";
  searching: boolean = false;
  constructor(
    private router: Router,
    private actionSheetCtrl: ActionSheetController,
    private toastCtrl: ToastController,
    private fireServ: FirebaseService,
    private analytics: AnalyticsService,
    private helperServ: HelperService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private cdRef: ChangeDetectorRef,
    private activeRoute: ActivatedRoute
  ) {
    this.activeRoute.queryParams.subscribe((params) => {
      if (params["subTab"]) {
        this.subTab = params["subTab"];
        this.cdRef.detectChanges();
      } else {
        this.selectSubTab("Build-A-Book");
      }
    });
  }

  ngOnDestroy(): void {
    console.log("On Destory");
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  async ngOnInit() {
    //this.contentUpdates = await this.fireServ.getLatestUpdates('home')

    getLibraries()
      .then((libraries) => {
        for (let library of libraries.data) {
          library.price === 0
            ? this.freeContent.push({ ...library, subscribed: false })
            : this.cardContent.push({ ...library, subscribed: false });
        }

        this.freeContent = this.freeContent.sort((a, b) => {
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          } else return -1;
        });

        this.cardContent = this.cardContent.sort((a, b) => {
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          } else return -1;
        });

        const sub = this.fireServ.getSubscribedContent().subscribe(async (libraryIds) => {
          for (let id of libraryIds) {
            const newId = await id;
            const libraryIndex = this.cardContent.findIndex((library) => library.id === newId);
            console.log(newId, libraryIndex);
            if (newId && libraryIndex >= 0) {
              console.log(this.cardContent[libraryIndex]);
              this.cardContent[libraryIndex].subscribed = true;
            } else {
              const libIndex = this.freeContent.findIndex((library) => library.id === newId);
              if (newId && libIndex >= 0) {
                console.log(this.freeContent[libIndex]);
                this.freeContent[libIndex].subscribed = true;
              }
            }
          }
        });

        this.subscriptions.push(sub);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  }

  selectSubTab(subTab: any) {
    if (subTab?.detail?.value) {
      return this.router.navigate([], { queryParams: { subTab: subTab.detail.value }, queryParamsHandling: "merge" });
    } else {
      return this.router.navigate([], { queryParams: { subTab }, queryParamsHandling: "merge" });
    }
  }

  search() {
    console.log(this.searchTerm);
    if (!this.searchTerm || this.searchTerm.length < 3) {
      this.helperServ.presentToast("Please enter 3 or more characters");
      return;
    }

    this.router.navigate(["search-results"], { queryParams: { search_query: this.searchTerm } });
  }

  async shareApp() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: "Share the app?",
      mode: "ios",
      //subHeader: copyLink,
      buttons: [
        {
          text: "Copy Link",
          handler: () => {
            //// Different Browser capabilties
            if (window.navigator.userAgent.includes("Chrome")) {
              Plugins.Clipboard.write({ url: this.appShareLink })
                .then((value) => {
                  this.copiedMessage("Successfully copied the link to your clipboard!");
                  this.analytics.event_share({ share_method: "link", link: this.appShareLink });
                })
                .catch((error) => {
                  console.log(error);
                  this.copiedMessage("Something went wrong. Please try again.");
                });
            } else {
              // Create new element
              var el = document.createElement("textarea");
              // Set value (string to be copied)
              el.value = this.appShareLink;
              // Set non-editable to avoid focus and move outside of view
              el.setAttribute("readonly", "");
              //el.style = {position: 'absolute', left: '-9999px'};
              document.body.appendChild(el);
              // Select text inside element

              el.select();
              // Copy text to clipboard
              if (document.execCommand("copy").valueOf()) {
                this.copiedMessage("Successfully copied the link to your clipboard!");
                this.analytics.event_share({ share_method: "link", link: this.appShareLink });
              } else {
                this.copiedMessage("Something went wrong. Please try again.");
              }
              // Remove temporary element
              document.body.removeChild(el);
            }
          },
        },
        {
          text: "Share via ... (for mobile & some browsers)",
          handler: () => {
            this.presentShareOptions();
          },
        },
        {
          text: "Cancel",
          role: "cancel",
        },
      ],
    });

    await actionSheet.present();
  }

  async presentShareOptions() {
    await SharePluginWeb.prototype
      .share({
        title: "See cool stuff",
        text: "myLibrary",
        url: this.appShareLink,
        dialogTitle: "Share with buddies",
      })
      .then(() => {
        this.analytics.event_share({ share_method: "Native options", link: this.appShareLink });
      })
      .catch((error) => {
        console.log(error, "Dang it");
      });
  }

  async copiedMessage(message: string) {
    this.helperServ.presentToast(message, false, true, 5000);
  }

  navigateTo(path: string) {
    if (path.includes("http")) {
      /* window.location.href = path */
      window.open(path, "_blank");
    } else {
      this.navCtrl.navigateForward(path);
      //this.router.navigate([path])
    }
  }

  async presentUploadModal() {
    const modal = await this.modalCtrl.create({
      component: EditContentDataComponent,
      backdropDismiss: true,
      cssClass: "chapter-popover",
    });

    modal.onWillDismiss().then((res) => {
      if (res.data) {
        console.log("Res: ", res);
        this.navigateTo("know-bitz");
      }
    });
    await modal.present();
  }
}
