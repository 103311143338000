import { CdkDragEnter, CdkDragExit } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { UserBook, UserChapter } from "../../interfaces/firebase-interfaces";

@Component({
  selector: "app-collapsible-part",
  templateUrl: "./collapsible-part.component.html",
  styleUrls: ["./collapsible-part.component.scss"],
})
export class CollapsiblePartComponent implements OnInit {
  @Input() value: string;
  @Input() index: number;
  @Input() part: UserBook;
  @Input() bitsList: string[];
  @Input() noBits: { [id: string]: boolean };
  @Input() condensed = false;

  @Output("dropBit") dropBit: EventEmitter<any> = new EventEmitter<any>();
  @Output("bitEntered") bitEntered: EventEmitter<CdkDragEnter<UserChapter[], UserChapter[]>> = new EventEmitter<
    CdkDragEnter<UserChapter[], UserChapter[]>
  >();
  @Output("bitExited") bitExited: EventEmitter<CdkDragExit<UserChapter[], UserChapter[]>> = new EventEmitter<
    CdkDragExit<UserChapter[], UserChapter[]>
  >();
  @Output("presentOptions") presentOptions: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router) {}

  ngOnInit() {}
}
