import { Injectable } from "@angular/core";
import { auth } from "firebase";
import { AnalyticsService } from "./analytics.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private analytics: AnalyticsService) {}

  async signInWithGoogle(): Promise<any> {
    var provider = new auth.GoogleAuthProvider();
    const user = await auth().signInWithPopup(provider);
    if (user.additionalUserInfo.isNewUser) {
      this.analytics.event_signup({ method: "Google" });
    } else {
      this.analytics.event_login({ method: "Google" });
    }
    return;
  }

  async signInWithMicrosoft() {
    const provider = new auth.OAuthProvider("microsoft.com");
    const user = await auth().signInWithPopup(provider);
    if (user.additionalUserInfo.isNewUser) {
      this.analytics.event_signup({ method: "Microsoft" });
    } else {
      this.analytics.event_login({ method: "Microsoft" });
    }
    return;
  }

  async signInWithApple() {
    const provider = new auth.OAuthProvider("apple.com");
    const user = await auth().signInWithPopup(provider);
    if (user.additionalUserInfo.isNewUser) {
      this.analytics.event_signup({ method: "Apple" });
    } else {
      this.analytics.event_login({ method: "Apple" });
    }
    return;
  }

  async sendSMSCode(number: string, captcha: firebase.auth.ApplicationVerifier): Promise<auth.ConfirmationResult> {
    return auth().signInWithPhoneNumber(number, captcha);
  }

  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      auth()
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          async (res) => {
            if (!(res.user.emailVerified || res.user.phoneNumber)) {
              auth().signOut();
              await auth().currentUser.sendEmailVerification({ url: window.location.origin + "/login", handleCodeInApp: true });
              window.localStorage.setItem("emailForSignIn", res.user.email);
            }
            this.analytics.event_signup({ method: "myLibrary" });
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }

  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => {
            if (res.user.emailVerified || res.user.phoneNumber) {
              this.analytics.event_login({ method: "myLibrary" });
              resolve(res);
            } else {
              window.localStorage.setItem("emailForSignIn", res.user.email);
              res.user
                .sendEmailVerification({ url: window.location.origin + "/login", handleCodeInApp: true })
                .then(() => {
                  reject(res.user.emailVerified || res.user.phoneNumber);
                })
                .catch((err) => {
                  reject(false);
                });
            }
          },
          (err) => reject(err)
        );
    });
  }

  resetPasswordWithEmail(email: string) {
    return new Promise<any>((resolve, reject) => {
      auth()
        .sendPasswordResetEmail(email)
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  // updateEmailVerifiedStatus() {
  //   return new Promise<any>((resolve, reject) => {

  //   })
  // }

  doLogout() {
    return new Promise<any>((resolve, reject) => {
      auth()
        .signOut()
        .then(() => {
          this.unsubscribeOnLogOut();
          this.analytics.event_logout();
          resolve(true);
        })
        .catch((error) => {
          console.log("Error here:", error);
          reject();
        });
    });
  }

  unsubscribeOnLogOut() {
    //remember to unsubscribe from the snapshotChanges
    //this.snapshotChangesSubscription.unsubscribe();
  }

  async updatePassword(pass: string) {
    await auth().currentUser.updatePassword(pass);
  }
}
