import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, PopoverController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { EditContentDataComponent } from "../../components/edit-content-data/edit-content-data.component";
import { Profile } from "../../pages/profile/profile.page";
import { UserData } from "../../providers/user-data";
import { AuthService } from "../../services/auth.service";
import { PaymentService } from "../../services/payment.service";
import { UserService } from "../../services/user.service";
import { FirebaseService } from "./../../services/firebase.service";

@Component({
  selector: "app-account-options-popover",
  templateUrl: "./account-options-popover.component.html",
  styleUrls: ["./account-options-popover.component.scss"],
})
export class AccountOptionsPopoverComponent implements OnInit {
  profile: Profile;
  guestMode: boolean;

  customer: Profile;

  subscriptions: Subscription[] = [];
  constructor(
    public router: Router,
    public userData: UserData,
    private changeDet: ChangeDetectorRef,
    private authServ: AuthService,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private userServ: UserService,
    private payServ: PaymentService,
    private fireServ: FirebaseService
  ) {}

  ngOnInit() {
    const sub = this.userServ.$userProfile.subscribe((profile) => {
      this.profile = profile;
      if (this.profile) {
        this.guestMode = false;
      } else {
        this.guestMode = true;
      }
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  async uploadContent() {
    await this.userServ.uploadEpub();
    // const modal = await this.modalCtrl.create({
    //   component: EditContentDataComponent,
    //   backdropDismiss: true,
    //   componentProps: {
    //     profile: this.profile,
    //   },
    //   cssClass: "chapter-popover",
    // });

    // modal.onWillDismiss().then((res) => {
    //   if (res.data) {
    //     const found = this.profile.bits.findIndex((bit) => bit.id == res.data.id);
    //     if (found != -1) {
    //       this.profile.bits[found] = res.data;
    //       this.changeDet.detectChanges();
    //     } else {
    //       this.profile.bits.push(res.data);
    //     }
    //   }
    // });
    // await modal.present();
    this.popoverCtrl.dismiss();
  }

  async viewTransactionHistory() {
    await this.payServ.openStripePortal(this.profile);
    await this.popoverCtrl.dismiss();
  }

  async logout() {
    await this.userData.logout();
    await this.authServ.doLogout();
    await this.navigateTo("/login");
  }

  async navigateTo(path: string) {
    await this.router.navigateByUrl(path);
    await this.popoverCtrl.dismiss();
  }
}
