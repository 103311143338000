import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, UrlSegment } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { AuthGuardServiceService } from './auth-guard-service.service';
import { ToastController } from '@ionic/angular';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuardServiceService implements CanActivate {
  
  //public user: {email: string, uid: string} = {email: '', uid: ''}

  constructor(
    private router: Router,
    public fireAuth: AngularFireAuth,
    public toastCtrl: ToastController,
    public authGuardServ: AuthGuardServiceService,
    //private fireServ: FirebaseService,
    //private authGaurd: AuthGuardServiceService
  ) {}
    
async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    // console.log('Can activate subscription service')

    // let firstGuard = new AuthGuardServiceService(this.router, this.fireAuth)
    // let status = firstGuard.canActivate()
    
    // console.log(status, 'First guard done')
    // let secondGuard = new FirebaseService(this.afs, this.fireAuth, this.toastCtrl, this.authGuardServ)
    // let secondStatus = secondGuard.canActivate()
    // console.log(secondStatus, 'Second guard done')
    // if (firstGuard && secondGuard) {
    //     let libraryId = route.paramMap.get('libraryId').split('%20').join(' ').toString()
    //     console.log('Checking subscirption guard 1')
    //     secondGuard.isSubscribedTo(libraryId).then((subscribed: boolean) => {
    //         if (subscribed) {
    //             console.log('Checking subscirption guard 2')
    //             return true
    //         }
    //         console.log('Checking subscirption guard 3')
        
    //         console.log(this.router.url)
    //         const newRoute = this.router.url.replace('libraries', 'store')
    //         console.log(newRoute)
        
    //         this.router.navigate([newRoute]);
    //         console.log('End of can activate subscription service')
        
    //         return false
    //     })
        
    // }

    return false
    
  }
}
