import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { Chapter } from "../../interfaces/firebase-interfaces";
import { ChapterService } from "../../services/chapters.service";
import { ResourcePopoverComponent } from "../resource-popover/resource-popover.component";

interface Resource {
  id: string;
  title: string;
  src: any;
}

@Component({
  selector: "chapter-resources-popover",
  templateUrl: "./chapter-resources-popover.component.html",
  styleUrls: ["./chapter-resources-popover.component.scss"],
})
export class ChapterResourcesComponent implements OnInit {
  chapters: Chapter[];
  chapResources: any[] = [];
  library: string;
  constructor(public modalCtrl: ModalController, public navParams: NavParams, public chapServ: ChapterService) {
    this.chapters = this.navParams.get("chapters");
    this.library = this.navParams.get("library");

    this.chapServ.getChapterInfo(this.chapters, this.library, "resources").then((resources) => {
      resources.forEach((resource) => {
        resource.forEach((res) => {
          let entry = {
            id: res.id,
            title: res.data.title,
            src: res.data?.link ? res.data.link : "",
          };
          this.chapResources.splice(this.chapResources.length, 1, entry);
        });
      });

      this.chapResources.forEach((resource: Resource) => {
        console.log("Get resource url: ", resource);
        if (resource.src) return;
        this.chapServ.getResourceURL(resource.id + ".pdf", this.library).then(async (url) => {
          resource.src = url + ".pdf";
          //resource.src = this.sanitizer.bypassSecurityTrustResourceUrl(resource.src)
        });
        //console.log(resource.src)
      });
    });
  }

  ngOnInit() {}

  async openResource(resource) {
    const modal = await this.modalCtrl.create({
      component: ResourcePopoverComponent,
      componentProps: { resource: resource },
      backdropDismiss: true,
      cssClass: "video-popover",
    });

    await modal.present();
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
