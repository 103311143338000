import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {

  transform(value: string, splitChar: string, splitIndex: number): any {
    return value.split(splitChar)[splitIndex];
  }

}
