import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { PopoverController } from "@ionic/angular";
import { OptionsPopoverComponent } from "../options-popover/options-popover.component";

@Component({
  selector: "chapter",
  templateUrl: "./chapter.component.html",
  styleUrls: ["./chapter.component.scss"],
})
export class ChapterComponent implements OnInit {
  @Input() piece;
  @Input() selectMode: boolean = false;
  @Input() owned: boolean = false;

  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router, private popoverCtrl: PopoverController) {}

  ngOnInit() {
    console.log("Piece: ", this.piece);
  }

  async navigateToOtherPage() {
    console.log(this.piece);
    if (this.piece == null) {
      console.log("chapterId not found");
    } else {
      let showPreview = false;
      if (this.router.routerState.snapshot.url.includes("store")) {
        showPreview = true;
      }
      let navigationExtras: NavigationExtras = {
        state: {
          chapter: this.piece,
          showPreview: showPreview,
        },
      };
      this.router.navigate(["/r/" + this.piece.libraryId + "/chapter/" + this.piece.id], navigationExtras);
    }
  }

  async presentOptionsPopover(showSelect: boolean, event?: Event) {
    const popover = await this.popoverCtrl.create({
      component: OptionsPopoverComponent,
      componentProps: { chapters: [this.piece], contentType: "chapter", library: this.piece.libraryId, showSelect },
      event,
      translucent: true,
      backdropDismiss: true,
    });

    popover.onWillDismiss().then((res) => {
      if (res.data && res.data.select && showSelect) {
        this.select.emit();
        //this.selectMode = true
        //const index = this.contentInView.findIndex((chap) => chap.id === chapter.id)
        //this.contentInView[index].selected = true
        this.piece.selected = true;
      }

      if (res.data && res.data.content) {
        if (res.data.content === "delete") {
          this.remove.emit();
        } else {
          this.piece = res.data.content;
        }
      }
    });
    await popover.present();
  }
}
