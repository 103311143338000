import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { Plugins } from "@capacitor/core";
import {
  ActionSheetController,
  AlertController,
  Gesture,
  GestureController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  PopoverController,
  ToastController,
} from "@ionic/angular";
import Epub, { Contents, Location, Rendition } from "epubjs";
import { Book } from "epubjs/types";
import { Annotation } from "epubjs/types/annotations";
import View from "epubjs/types/managers/view";
import firebase, { firestore } from "firebase";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { AddToPopoverComponent } from "../../components/add-to-popover/add-to-popover.component";
import { LinkPopover } from "../../components/chapter-links-popover/chapter-links-popover";
import { ChapterResourcesComponent } from "../../components/chapter-resources-popover/chapter-resources-popover.component";
import { ImagePopoverComponent } from "../../components/image-popover/image-popover.component";
import { NoteComponent } from "../../components/note/note.component";
import { PaymentPopoverComponent } from "../../components/payment-popover/payment-popover.component";
import { RatingPopoverComponent } from "../../components/rating-popover/rating-popover.component";
import { ReaderOptionsComponent } from "../../components/reader-options/reader-options.component";
import { TextOptionsComponent } from "../../components/text-options/text-options.component";
import { VideoPopoverPage } from "../../components/video-popover/video-popover";
import { FirebaseChapter, Library, UserBook } from "../../interfaces/firebase-interfaces";
import { AnalyticsService } from "../../services/analytics.service";
import { ChapterService } from "../../services/chapters.service";
import { getBackendData, getChapterData, getLibraries, getLinkCount } from "../../services/cloud-functions";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";
import { UserService } from "../../services/user.service";
import { Profile } from "../profile/profile.page";

export interface customChapter {
  title: string;
  url: string;
  id: string;
  libraryId: string;
  number?: number;
}

var currentEpub: Book;
var rendition: Rendition;
var font_style = "sans-serif";
var font_size = 16;
var background_color = "white";
var font_color = "black";
var highlight: boolean = true;

export enum KEY_CODE {
  RIGHT_ARROW = "ArrowRight",
  LEFT_ARROW = "ArrowLeft",
  UP_ARROW = "ArrowUp",
  DOWN_ARROW = "ArrowDown",
}

@Component({
  selector: "page-chapter",
  templateUrl: "./chapter.html",
  styleUrls: ["./chapter.scss"],
})
//below line was 'export class ChapterPage implements OnInit {' when creasted by ionic, changed 5/30/2019
export class ChapterPage implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("overlay") overlay: ElementRef;
  @ViewChild("book") bookElem: ElementRef;

  overlayTimeout: NodeJS.Timeout;

  chapterData: FirebaseChapter;
  searchTerm: "";
  toast: HTMLIonToastElement = null;
  previewMode: boolean = false;
  copyLink: string;
  libraryId: string;
  library: Library;
  titlePage: boolean;

  paginated = true;

  contentCreator: Profile;
  creatorId: string;
  customBook: UserBook;
  bookChapters: customChapter[];
  currentChapterIndex: number = 0;
  contentId: string;
  loadingPopover: HTMLIonLoadingElement;
  oneChapter: boolean = true;
  isSignedIn: boolean;
  contentType: string;
  defaultBackButton: boolean;

  subscriptions: Subscription[] = [];
  originalContents: Contents;

  rendition: Rendition;
  progress: number; // % through the epub
  currentPage: number;
  totalPages: number;

  overlayToggle: boolean = true;

  swipeGesture: Gesture;
  canSwipe = true;

  resourceCount: Array<number>;

  viewTimeout: ReturnType<typeof setTimeout> = undefined;

  constructor(
    public popoverCtrl: PopoverController,
    private router: Router,
    private route: ActivatedRoute,
    private chapServ: ChapterService,
    private toastCtrl: ToastController,
    private fireServ: FirebaseService,
    private alertController: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private loadCtrl: LoadingController,
    private navCtrl: NavController,
    private analytics: AnalyticsService,
    private helperServ: HelperService,
    private modalCtrl: ModalController,
    private userServ: UserService,
    private gestureCtrl: GestureController,
    private platform: Platform,
    private changeDet: ChangeDetectorRef
  ) {
    if (this.platform.is("mobile")) {
      this.paginated = false;
    } else {
      this.paginated = true;
    }
  }

  ngOnInit(): void {
    this.observeUserProfile();

    this.libraryId = this.route.snapshot.paramMap.get("libraryId").split(" ").join("_").split("%20").join("_");
    this.contentType = this.route.snapshot.paramMap.get("contentType");
    this.contentId = this.route.snapshot.paramMap.get("id").split(" ").join("_").split("%20").join("_");
    console.log(this.libraryId, this.contentType, this.contentId);
    if (!this.fireServ.Libraries) {
      getLibraries().then((vals) => {
        this.fireServ.Libraries = vals.data;
      });
    }

    this.resourceCount = [0, 0, 0];

    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        this.isSignedIn = true;
      } else {
        this.isSignedIn = false;
      }
      this.initContent();
    });
  }

  ngAfterViewInit(): void {
    // this.initSwipe();
  }

  initSwipe(ele: Node) {
    this.swipeGesture = this.gestureCtrl.create(
      {
        el: ele,
        threshold: 15,
        gestureName: "my-gesture",
        onMove: (ev) => {
          if (!this.canSwipe) {
            return;
          }

          this.canSwipe = false;

          if (ev.velocityX > 1) {
            this.prevPage();
          } else if (ev.velocityX < -1) {
            this.nextPage();
          }

          setTimeout(() => {
            this.canSwipe = true;
          }, 500);
        },
      },
      true
    );

    this.swipeGesture.enable();
  }

  async ionViewWillEnter() {
    await this.presentLoader();
    this.defaultBackButton = window.history.state.navigationId > 1 && !window.history.state.fromSupport ? true : false;
  }

  ionViewDidLeave() {
    if (this.viewTimeout) {
      clearTimeout(this.viewTimeout);
    }

    if (this.toast != null && !this.toast.hidden) {
      this.toast.dismiss();
    }

    if (currentEpub.isOpen) {
      rendition.destroy();
      currentEpub = null;
    }
    //this.router.ngOnDestroy()
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });

    if (this.swipeGesture) {
      this.swipeGesture.destroy();
    }
    //this.router.dispose()
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.key === KEY_CODE.RIGHT_ARROW) {
      this.nextPage();
    }

    if (event.key === KEY_CODE.LEFT_ARROW) {
      this.prevPage();
    }

    if (event.key === KEY_CODE.UP_ARROW) {
      if (this.currentChapterIndex > 0) {
        this.prevEpub();
      }
    }

    if (event.key === KEY_CODE.DOWN_ARROW) {
      if (this.currentChapterIndex < this.bookChapters.length - 1) {
        this.nextEpub();
      }
    }
  }

  async initContent() {
    if (history.state.showPreview != null) {
      this.previewMode = history.state.showPreview;
      //await this.storage.remove('showPreview')
      if (await Plugins.Storage.get({ key: "showPreview" })) {
        Plugins.Storage.remove({ key: "showPreview" });
      }
      await Plugins.Storage.set({ key: "showPreview", value: String(this.previewMode) });
      //await this.storage.set('showPreview', this.previewMode)
    } else {
      let storedPreviewMode = (await Plugins.Storage.get({ key: "showPreview" })).value;
      if (storedPreviewMode == "false") {
        this.previewMode = false;
      } else {
        this.previewMode = true;
      }
    }
    this.viewTimeout = setTimeout(async () => {
      await this.fireServ.addView(this.contentId, this.libraryId);
    }, 5000);
    if (this.contentType === "user-book") {
      this.creatorId = this.libraryId;
      const bookDoc = await this.fireServ.getUserBook(this.contentId, this.creatorId);
      this.customBook = bookDoc;

      this.copyLink = window.document.location.href
        .replace(this.router.routerState.snapshot.url, "/" + this.creatorId + "/user-book/" + this.customBook.id)
        .split(" ")
        .join("%20");

      return this.chapServ
        .getMultipleChapters(this.customBook)
        .then(async (chapters) => {
          this.bookChapters = chapters;
          this.chapterData = { title: this.bookChapters[0].title, authors: [], active: true };
          if (chapters.length > 1) {
            this.oneChapter = false;
          }
          this.titlePage = this.isTitlePage();
          await this.displayEpub(this.bookChapters[this.currentChapterIndex].url).catch((err) =>
            console.error("Error displaying epub: ", err)
          );
        })
        .catch(() => {
          this.noEpub();
        });
    } else if (this.contentType === "set") {
      this.contentId = this.contentId.split("_").join(" ");
      this.copyLink = window.document.location.href
        .replace(this.router.routerState.snapshot.url, "/r/" + this.libraryId + "/set/" + this.contentId)
        .split(" ")
        .join("%20");
      return this.chapServ
        .getChaptersInVolume(this.libraryId, this.contentId)
        .then(async (chapters) => {
          this.bookChapters = chapters;
          this.chapterData = { title: this.bookChapters[0].title, authors: [], active: true };
          if (chapters.length > 1) {
            this.oneChapter = false;
          }
          console.log(this.bookChapters);
          await this.displayEpub(this.bookChapters[this.currentChapterIndex].url);
        })
        .catch(async (err) => {
          console.error("Volume can't be displayed", err);
          this.noEpub();
        });
    } else {
      if (this.isSignedIn) {
        this.chapterData = await this.fireServ.getChapterData(this.contentId, this.libraryId);
      } else {
        this.chapterData = (await getChapterData({ contentId: this.contentId, libraryId: this.libraryId })).data;
      }

      this.copyLink = window.document.location.href
        .replace(this.router.routerState.snapshot.url, "/r/" + this.libraryId + "/chapter/" + this.contentId)
        .split(" ")
        .join("%20");

      return this.chapServ
        .getChapterURL(this.contentId, this.libraryId)
        .then(async (url) => {
          this.bookChapters = [
            { title: this.chapterData.title, number: this.chapterData.chapterNumber, url, id: this.contentId, libraryId: this.libraryId },
          ];
          await this.displayEpub(this.bookChapters[this.currentChapterIndex].url);
        })
        .catch(async () => {
          this.noEpub();
        });
    }
  }

  async displayEpub(epub: string) {
    document.getElementById("book").innerHTML = "";
    const count = await this.getNumLinks();
    this.resourceCount = [...Object.values(count)];
    console.log(this.resourceCount, count);
    this.changeDet.detectChanges();
    // 0 = links, 1 = videos, 2 = resources, 3 total
    if (rendition) {
      rendition.destroy();
    }

    if (this.toast) {
      this.toast.dismiss();
    }

    this.previewMode = false;
    const user = this.userServ.getFireUser();

    if (user) {
      this.library = (
        (await getBackendData(
          `Collections/${
            this.bookChapters[this.currentChapterIndex].libraryId == "N/A"
              ? this.libraryId
              : this.bookChapters[this.currentChapterIndex].libraryId
          }`,
          [],
          ["data"]
        )) as firestore.DocumentData
      ).data as Library;
    } else if (this.fireServ.Libraries) {
      this.library = this.fireServ.Libraries[this.fireServ.Libraries.findIndex((lib) => lib.id == this.libraryId)];
    }

    if (
      (!this.library && this.bookChapters[this.currentChapterIndex].libraryId !== "N/A") ||
      (this.bookChapters[this.currentChapterIndex].libraryId !== "N/A" &&
        ((!(await this.fireServ.isSubscribedTo(this.bookChapters[this.currentChapterIndex].libraryId)) &&
          (!user || this.bookChapters[this.currentChapterIndex].libraryId != user.uid) &&
          this.library.price != 0) ||
          (!user && this.library.price == 0))
      ) ||
      (this.chapterData?.includedInUserCollection?.length > 0 && 
        !(await Promise.all(this.chapterData?.includedInUserCollection.map(async (val) => {
          return await this.fireServ.isSubscribedTo(val);
        }))).some((val) => val)
      )
    ) {
      this.previewMode = true;
    }

    // I believe there needs to be a variable that stores the value of
    // rendition.display() for the scrolling to work properly. Seems like a weird bug  ~Brandon
    currentEpub = Epub(epub + ".epub");
    if (this.previewMode) {
      if (this.paginated) {
        rendition = currentEpub.renderTo("book", { width: `95%`, height: "95%" });
      } else {
        rendition = currentEpub.renderTo("book", { flow: "scrolled-doc" });
      }

      rendition.display().then(async () => {
        await this.dismissLoader();
        await this.presentPreviewPopover();
      });
    } else {
      if (this.paginated) {
        rendition = currentEpub.renderTo("book", { width: `95%`, height: "95%" });
      } else {
        rendition = currentEpub.renderTo("book", { flow: "scrolled-doc" });
      }
      rendition.themes.default(this.getEpubTheme());
      rendition.settings.allowScriptedContent = true;

      await this.configEpub();
    }
    return this.dismissLoader();
  }

  async noEpub() {
    document.getElementById("book").innerHTML = " This page cannot be displayed. Please let us know! ";
    await this.dismissLoader();
  }

  async configEpub() {
    rendition.on("relocated", (locations: Location) => {
      // this.progress = currentEpub.locations.percentageFromCfi(locations.start.cfi);
      // this.currentPage = locations.start.displayed.page; //Number(currentEpub.locations.locationFromCfi(locations.start.cfi).toString());
      // this.totalPages = locations.start.displayed.total;
      this.changeDet.detectChanges();
    });

    rendition.hooks.content.register(async (contents: Contents, view: View) => {
      this.initSwipe(contents.content);
      // contents
      if (this.chapterData.title === "Unique Cover") {
        this.contentCreator = await this.fireServ.getUserProfileData(null, this.creatorId);
        if (this.contentCreator && (this.contentCreator.firstName || this.contentCreator.lastName)) {
          contents.document.body.innerHTML = contents.document.body.innerHTML.replace(
            /{{user}}/g,
            `${this.contentCreator.firstName} ${this.contentCreator.lastName}`
          );
        } else {
          contents.document.body.innerHTML = contents.document.body.innerHTML.replace(/{{user}}/g, `A myLibrary User`);
        }
        contents.document.body.innerHTML = contents.document.body.innerHTML.replace(/{{bookTitle}}/g, this.customBook.title);
      }

      // Apply stored annotations
      if (!this.previewMode) {
        this.fireServ
          .getUserOptions(
            this.bookChapters[
              this.bookChapters[this.currentChapterIndex].title == "Unique Cover" ? this.currentChapterIndex + 1 : this.currentChapterIndex
            ]
          )
          .then((contentOptions) => {
            if (contentOptions) {
              if (contentOptions.notes) {
                contentOptions.notes.forEach((note) => {
                  rendition.annotations.add("underline", note.cfiRange, note.data, async (e: MouseEvent) => {
                    const remove = await this.openNote(
                      e,
                      (rendition.annotations as any)._annotations[note.cfiRange.replace(/\[/g, "%5B").replace(/\]/g, "%5D") + "underline"]
                    );
                    if (remove) {
                      rendition.annotations.remove(note.cfiRange, "underline");
                    }
                  });
                });
              }

              if (contentOptions.highlights) {
                contentOptions.highlights.forEach((highlight) => {
                  rendition.annotations.highlight(highlight.cfiRange, {}, async (e) => {
                    if (confirm("Do you want to remove this highlight?")) {
                      rendition.annotations.remove(highlight.cfiRange, "highlight");
                      await this.fireServ.updateAnnotations(this.bookChapters[this.currentChapterIndex], highlight, false);
                    }
                  });
                });
              }
            }
          })
          .catch((err) => {
            console.log("No content options");
          });
      }

      // Text Options

      contents.on("selected", (cfiRange: string) => {
        this.openTextOptions().then(async (action) => {
          if (action === "highlight") {
            const annotation = rendition.annotations.add(
              "highlight",
              cfiRange,
              {},
              async (e) => {
                if (confirm("Do you want to remove this highlight?")) {
                  rendition.annotations.remove(cfiRange, "highlight");
                  await this.fireServ.updateAnnotations(this.bookChapters[this.currentChapterIndex], annotation, false);
                }
              },
              ""
            );

            await this.fireServ.updateAnnotations(this.bookChapters[this.currentChapterIndex], annotation, true);
          } else if (action === "note") {
            const annotation: Annotation = rendition.annotations.add("underline", cfiRange, { note: "" }, async (e: MouseEvent) => {
              const remove = await this.openNote(
                e,
                (rendition.annotations as any)._annotations[cfiRange.replace(/\[/g, "%5B").replace(/\]/g, "%5D") + "underline"]
              );
              if (remove) {
                rendition.annotations.remove(cfiRange, "underline");
              }
            });

            //sessionStorage.setItem('note', JSON.stringify(annotation))
            const remove = await this.openNote(new Event("mouse"), annotation);
            if (remove) {
              rendition.annotations.remove(cfiRange, "underline");
            }
          }
        });
      });

      // rendition.hooks.display.register((display) => {
      //   display.on("mousemove", () => {
      //     clearTimeout(this.overlayTimeout)
      //     // console.log("Ev: ", ev)
      //     this.toggleOverlay(true)
      //     this.overlayTimeout = setTimeout(() => {
      //       console.log("Turning overlay off")
      //       this.toggleOverlay(false)
      //     }, 2000)
      //   })
      // })

      // Click on images
      let contents2 = contents.document.images;

      for (var i = 0; i < contents2.length; i++) {
        contents2.item(i).title = "click to enlarge";

        const x = contents2.item(i);
        contents2.item(i).addEventListener(
          "click",
          async (ev) => {
            const modal = await this.modalCtrl.create({
              component: ImagePopoverComponent,
              componentProps: { img: x },
              backdropDismiss: true,
              cssClass: "image-popover",
            });
            await modal.present();
          },
          false
        );
        contents2.item(i).addEventListener(
          "mouseover",
          (ev: any) => {
            ev.target.style.cursor = "pointer";
          },
          false
        );
      }
    });

    await rendition.display();

    this.rendition = rendition;
    this.originalContents = rendition.getContents();
  }

  toggleOverlay(show: boolean) {
    const overlay = document.getElementById("overlay");

    if (show) {
      this.toggleOn();
      overlay.onmouseover = () => {};
      overlay.onmouseleave = () => {};
      overlay.removeEventListener("mouseover", this.toggleOn);
      overlay.removeEventListener("mouseleave", this.toggleOff);
    } else {
      this.toggleOff();

      overlay.addEventListener("mouseover", this.toggleOn);
      overlay.addEventListener("mouseleave", this.toggleOff);
    }
  }

  toggleOn() {
    const overlay = document.getElementById("overlay");

    if (overlay.classList.contains("fadeOut")) {
      overlay.classList.remove("fadeOut");
    }

    overlay.classList.add("fadeIn");
  }

  toggleOff() {
    const overlay = document.getElementById("overlay");

    if (overlay.classList.contains("fadeIn")) {
      overlay.classList.remove("fadeIn");
    }
    overlay.classList.add("fadeOut");
  }

  observeUserProfile() {
    const sub = this.userServ.$userProfile.pipe(distinctUntilChanged()).subscribe((profile) => {
      console.log("User profile: ", profile);
      if (profile && profile.readerPreferences) {
        background_color = profile.readerPreferences.background_color;
        font_color = profile.readerPreferences.font_color;
        font_size = profile.readerPreferences.font_size;
        font_style = profile.readerPreferences.font_style;
        this.paginated = profile.readerPreferences.paginated;
      }
    });

    this.subscriptions.push(sub);
  }

  async saveReaderOptions() {
    if (this.isSignedIn) {
      return this.userServ.saveReaderOptions({
        background_color,
        font_color,
        font_size,
        font_style,
        paginated: this.paginated,
      });
    }
    return Promise.resolve();
  }

  async openTextOptions() {
    const top = await this.popoverCtrl.getTop();
    if (top) {
      top.dismiss();
    }

    const popover = await this.popoverCtrl.create({
      component: TextOptionsComponent,
      componentProps: {},
      event,
      mode: "ios",
    });
    await popover.present();
    return popover.onWillDismiss().then((res) => {
      if (res.data) {
        return res.data.action;
      }
    });
  }

  async openNote(event: Event, annotation: Annotation): Promise<boolean> {
    const popover = await this.popoverCtrl.create({
      component: NoteComponent,
      componentProps: { annotation },
      event,
      mode: "ios",
    });
    await popover.present();

    return popover
      .onWillDismiss()
      .then(async (res) => {
        if (res.data) {
          await this.fireServ.updateAnnotations(this.bookChapters[this.currentChapterIndex], annotation, !res.data.remove);
          return res.data.remove;
        } else {
          await this.fireServ.updateAnnotations(this.bookChapters[this.currentChapterIndex], annotation, true);
        }
      })
      .catch((err) => {
        console.error("openNote error:", err);
      });
  }

  getEpubTheme() {
    rendition.themes.fontSize(`${font_size}px`);
    return {
      html: {
        "background-color": `${background_color} !important`,
        "font-family": `${font_style} !important`,
        hyphens: "none",
      },
      body: {
        "background-color": `${background_color} !important`,
        "font-family": `${font_style} !important`,
        hyphens: "none",
      },
      ".title": {
        "font-family": `${font_style} !important`,
        hyphens: "none",
      },
      p: {
        "background-color": `${background_color} !important`,
        "font-family": `${font_style} !important`,
        hyphens: "none",
      },
      span: {
        "font-family": `${font_style} !important`,
        color: `${font_color} !important`,
        "background-color": `${background_color} !important`,
        hyphens: "none",
      },
      h1: {
        "font-family": `${font_style} !important`,
        hyphens: "none",
      },
      "span.sans-serif": {
        "font-family": `${font_style} !important`,
        hyphens: "none",
      },
      b: {
        "font-family": `${font_style} !important`,
        hyphens: "none",
      },
      ".element": {
        "font-family": `${font_style} !important`,
        hyphens: "none",
      },
      ".section-title": {
        "font-family": `${font_style} !important`,
        hyphens: "none",
      },
      "*": {
        color: `${font_color} !important`,
      },
      ".orange": {
        fill: `orange`,
      },
    };
  }

  async presentPaymentOptions() {
    for (let library of (await getLibraries()).data) {
      if (library.id == this.libraryId) {
        const modal = await this.modalCtrl.create({
          component: PaymentPopoverComponent,
          componentProps: { product: library, subscribed: false },
          // translucent: true,
          backdropDismiss: true,
          // cssClass: "add-to-popover",
        });

        modal.onWillDismiss().then((res) => {
          if (res.data && res.data.subscribed) {
            this.previewMode = false;
          }
          this.initContent();
        });

        await modal.present();
      }
    }
  }

  async toggleView(paginated: boolean) {
    this.paginated = paginated;

    rendition.destroy();

    if (this.paginated) {
      rendition = currentEpub.renderTo("book", { width: `95%`, height: "100%" });

      // Styling
      rendition.themes.default(this.getEpubTheme());
    } else {
      rendition = currentEpub.renderTo("book", { manager: "continuous", flow: "scrolled" });
      // Styling
      rendition.themes.default(this.getEpubTheme());
    }

    await this.configEpub();
  }

  async presentLoader() {
    this.loadingPopover = await this.loadCtrl.create({
      message: "Loading... Please refresh after 30 seconds",
      backdropDismiss: true,
    });
    await this.loadingPopover.present();
  }

  async dismissLoader() {
    if (this.loadingPopover && !this.loadingPopover.hidden) {
      await this.loadingPopover.dismiss().catch((error) => console.error("Loader is not present", error));
    }
  }

  async nextPage() {
    if (!this.previewMode) {
      if (this.currentChapterIndex < this.bookChapters.length - 1) {
        if (!this.paginated) {
          this.nextEpub();
        } else if (this.paginated && (rendition.location.atEnd || this.previewMode)) {
          this.nextEpub();
        }
      }
      rendition.next();
    }
  }

  nextEpub() {
    rendition.destroy();
    this.presentLoader().then((value) => {
      this.currentChapterIndex += 1;
      this.chapterData.title = this.bookChapters[this.currentChapterIndex].title;
      this.displayEpub(this.bookChapters[this.currentChapterIndex].url);
      this.titlePage = this.isTitlePage();
    });
  }

  async prevPage() {
    if (this.paginated) {
      if ((rendition.location.atStart || this.previewMode) && this.currentChapterIndex > 0) {
        // If at end of file, load next file in queue
        this.prevEpub();
      } else if (!this.previewMode) {
        await rendition.prev();
      }
    } else {
      if (this.currentChapterIndex > 0) {
        this.prevEpub();
      } else {
        console.log("First epub of the book");
      }
    }
  }

  prevEpub() {
    rendition.destroy();
    this.presentLoader().then((value) => {
      this.currentChapterIndex -= 1;
      this.chapterData.title = this.bookChapters[this.currentChapterIndex].title;
      this.displayEpub(this.bookChapters[this.currentChapterIndex].url);
      this.titlePage = this.isTitlePage();
    });
  }

  async presentPreviewPopover() {
    if (this.chapterData?.includedInUserCollection?.length > 0) {
      this.toast = await this.toastCtrl.create({
        message: `This Bit is part of a paid collection/s. You must buy a collection from the store that contains this before you may view it.`,
        position: `middle`
      });
    } else {
      this.toast = await this.toastCtrl.create({
        message: `This is a preview - Please ${this.library && this.library.price != 0 ? "subscribe/purchase" : "login"} to see full content`,
        position: "middle",
        buttons: [
          {
            text: this.isSignedIn ? "Get Access" : "Login",
            side: "end",
            handler: () => {
              if (this.isSignedIn) {
                this.presentPaymentOptions();
              } else {
                //alert(`You must logged in to subscribe! You are being redirected.`)
                var pageUrlArray = window.location.href.split("/");
                pageUrlArray = pageUrlArray.slice(pageUrlArray.length - 3); //.join('/').replace('%20', ' ')

                var pageUrl: string = "";
                for (const section of pageUrlArray) {
                  pageUrl += "/" + section.replace(/%20/gi, " ");
                }

                this.router.navigate(["login"], { state: { redirectTo: pageUrl } });
              }
            },
          },
        ],
      });
    }
    await this.toast.present();
    // }
  }

  async share() {
    let sharingTitle: string;
    if (this.contentType === "user-book") {
      sharingTitle = `your custom ` + this.customBook.title + ` part`;
    } else if (this.contentType === "set") {
      sharingTitle = this.contentId;
    } else {
      sharingTitle = this.chapterData.title;
    }

    const actionSheet = await this.actionSheetCtrl.create({
      header: "Share link to " + sharingTitle + "?",
      mode: "ios",
      //subHeader: copyLink,
      buttons: [
        {
          text: "Copy Link",
          handler: () => {
            if (window.navigator.userAgent.includes("Chrome")) {
              Plugins.Clipboard.write({ url: this.copyLink })
                .then((value) => {
                  this.copiedMessage("Successfully copied the link to your clipboard!");
                  this.analytics.event_share({ share_method: "link", link: this.copyLink });
                })
                .catch((error) => {
                  console.error(error);
                  this.copiedMessage("Something went wrong. Please try again.");
                });
            } else {
              // Create new element
              var el = document.createElement("textarea");
              // Set value (string to be copied)
              el.value = this.copyLink;
              // Set non-editable to avoid focus and move outside of view
              el.setAttribute("readonly", "");
              //el.style = {position: 'absolute', left: '-9999px'};
              document.body.appendChild(el);
              // Select text inside element
              el.select();
              // Copy text to clipboard
              if (document.execCommand("copy").valueOf()) {
                this.copiedMessage("Successfully copied the link to your clipboard!");
                this.analytics.event_share({ share_method: "link", link: this.copyLink });
              } else {
                this.copiedMessage("Something went wrong. Please try again.");
              }
              // Remove temporary element
              document.body.removeChild(el);
            }
          },
        },
        {
          text: "Share via ... (for mobile & some browsers)",
          handler: () => {
            this.presentShareOptions(sharingTitle);
          },
        },
        {
          text: "Cancel",
          role: "cancel",
        },
      ],
    });

    await actionSheet.present();
  }

  async presentShareOptions(title: string) {
    await Plugins.Share.share({
      title: title,
      url: this.copyLink,
    })
      .then(() => {
        this.analytics.event_share({ share_method: "Native options", link: this.copyLink });
      })
      .catch((error) => {
        console.error(error, "Couldn't share, try again");
      });
  }

  async copiedMessage(message: string) {
    this.helperServ.presentToast(message, false, true, 5000);
  }

  async addToOptions() {
    if (!this.isSignedIn) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    const modal = await this.modalCtrl.create({
      component: AddToPopoverComponent,
      componentProps: { chapters: [this.bookChapters[this.currentChapterIndex]] },
      backdropDismiss: true,
      cssClass: "add-to-popover",
    });
    await modal.present();
  }

  async presentOptionsPopover(event: Event) {
    const popover = await this.popoverCtrl.create({
      component: ReaderOptionsComponent,
      componentProps: {
        paginated: this.paginated,
        font_style: font_style,
        changeFontStyle: this.changeFontStyle,
        font_size: font_size,
        changeFontSize: this.changeFontSize,
        background_color: background_color,
        changeBackgroundColor: this.changeBackground,
        changeFontColor: this.changeFontColor,
        font_color: font_color,
        toggleView: (paginated) => {
          this.toggleView(paginated);
        },
      },
      event,
      translucent: true,
      backdropDismiss: true,
    });

    popover.onWillDismiss().then((result) => {
      return this.saveReaderOptions();
    });
    await popover.present();
  }

  async videoPopover() {
    const chapter = this.bookChapters[this.currentChapterIndex];

    const modal = await this.modalCtrl.create({
      component: VideoPopoverPage,
      componentProps: { chapters: [this.bookChapters[this.currentChapterIndex]], library: chapter.libraryId },
      // translucent: true,
      backdropDismiss: true,
      cssClass: "video-popover",
    });

    await modal.present();
  }

  async displayPrintOptions() {
    if (!this.isSignedIn) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    let printContent: string;
    if (this.contentType === "user-book") {
      printContent = JSON.stringify(this.customBook);
    } else if (this.contentType === "set") {
      printContent = this.contentId;
    } else {
      printContent = this.chapterData.title;
    }

    let alert = await this.alertController.create({
      message: "Print on-demand is still under development and is not yet available. Coming Soon!",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Okay",
          role: "submit",
          handler: async () => {
            await this.fireServ.sendPrintRequest(printContent); // Might change
          },
        },
      ],
    });
    await alert.present();
  }

  async supportDocumentsPopover() {
    const chapter = this.bookChapters[this.currentChapterIndex];

    const modal = await this.modalCtrl.create({
      component: ChapterResourcesComponent,
      componentProps: { chapters: [this.bookChapters[this.currentChapterIndex]], library: chapter.libraryId },
      backdropDismiss: true,
      cssClass: "new-custom-chapter",
    });
    await modal.present();
  }

  async presentLinkPopover(event: Event) {
    const chapter = this.bookChapters[this.currentChapterIndex];

    const modal = await this.modalCtrl.create({
      component: LinkPopover,
      componentProps: { chapters: [this.bookChapters[this.currentChapterIndex]], library: chapter.libraryId },
      backdropDismiss: true,
      cssClass: "chapter-popover",
    });
    await modal.present();
  }

  changeFontColor(color: string) {
    //TODO: FIX BUG HERE
    //may have to use CSS instead of epub.js
    //https://github.com/futurepress/epub.js/issues/778
    font_color = color;

    rendition.themes.register("fontColor", {
      "*": {
        color: `${font_color} !important`,
      },
    });

    rendition.themes.select("fontColor");
  }

  changeBackground(color: string) {
    background_color = color;

    rendition.themes.register("background", {
      html: {
        "background-color": `${background_color} !important`,
      },
      body: {
        "background-color": `${background_color} !important`,
      },
      p: {
        "background-color": `${background_color} !important`,
      },
      span: {
        "background-color": `${background_color} !important`,
      },
    });

    rendition.themes.select("background");
  }

  changeFontStyle(style: string) {
    font_style = style;

    rendition.themes.register("new", {
      span: {
        "font-family": `${font_style} !important`,
      },
      html: {
        "font-family": `${font_style} !important`,
      },
      body: {
        "font-family": `${font_style} !important`,
      },
      h1: {
        "font-family": `${font_style} !important`,
      },
      ".title": {
        "font-family": `${font_style} !important`,
      },
      "span.sans-serif": {
        "font-family": `${font_style} !important`,
      },
      b: {
        "font-family": `${font_style} !important`,
      },
      ".element": {
        "font-family": `${font_style} !important`,
      },
      ".section-title": {
        "font-family": `${font_style} !important`,
      },
      p: {
        "font-family": `${font_style} !important`,
      },
    });
    rendition.themes.select("new");
  }

  changeFontSize(size: number) {
    //TODO: FIX BUG HERE
    //current theme may not be being applied thats why color+fontsize dont change anything???
    //https://github.com/futurepress/epub.js/issues/1208
    //downgrading to 0.3.83 may also fix the issue
    font_size += size;

    rendition.themes.fontSize(String(font_size) + "px");
  }

  support() {
    let navigationExtras: NavigationExtras = {
      state: {
        goBack: true,
        previousURL: this.router.url,
      },
    };
    this.router.navigate(["support"], navigationExtras);
  }

  goBack() {
    let path = `/${this.previewMode ? `store` : `libraries`}/${this.libraryId}/toc`;

    if (this.contentType == "user-book") {
      path = `home`;
    }

    if (this.contentType == "chapter" && this.library && this.library.price == 0) {
      path = `know-bitz`;
    }

    this.navCtrl.navigateBack([path]);
  }

  async getNumLinks() {
    let count = { links: 0, videos: 0, resources: 0 };
    if (!this.isTitlePage()) {
      console.log("Not title page");
      count = (
        await getLinkCount({
          id: this.bookChapters[this.currentChapterIndex].id,
          libId: this.bookChapters[this.currentChapterIndex].libraryId,
        })
      ).data as { links: number; videos: number; resources: number };
      console.log(count);
      count["total"] = Object.values(count).reduce((a, b) => a + b);
      console.log(count);
    }
    console.log(count);

    return count;
  }

  async handleRefresh(event: any) {
    this.initContent().finally(() => {
      event.target.complete();
    });
  }

  isTitlePage() {
    return this.bookChapters[this.currentChapterIndex].title == "Unique Cover";
  }

  async rate() {
    await this.helperServ.presentLoader();
    const rating = await this.fireServ.getPrevReview(this.contentId).finally(() => this.helperServ.dismissLoader());
    console.log("Rating: ", rating);
    const modal = await this.popoverCtrl.create({
      component: RatingPopoverComponent,
      cssClass: ["borderRad10"],
      componentProps: { rating },
    });
    await modal.present();
    modal.onWillDismiss().then((data) => {
      if (data.data) {
        return this.fireServ.addReview(this.contentId, this.libraryId, data.data.rating);
      }
    });
  }
}
