import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { PopoverPage } from "../../components/about-popover/about-popover";
import { AboutPage } from "./about";
import { AboutPageRoutingModule } from "./about-routing.module";

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, AboutPageRoutingModule],
  declarations: [AboutPage, PopoverPage],
  // entryComponents: [PopoverPage],
  bootstrap: [AboutPage],
})
export class AboutModule {}
