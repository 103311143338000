import { Component } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { ActionSheetController, ModalController, NavController, PopoverController } from "@ionic/angular";
import firebase, { firestore } from "firebase";
import { MyCollectionsPopoverComponent } from "../../components/my-collections-popover/my-collections-popover.component";
import { OptionsPopoverComponent } from "../../components/options-popover/options-popover.component";
import { PaymentPopoverComponent } from "../../components/payment-popover/payment-popover.component";
import { TocFilterComponent } from "../../components/toc-filter/toc-filter.component";
import { Author, Chapter, Library, Volume } from "../../interfaces/firebase-interfaces";
import { AnalyticsService } from "../../services/analytics.service";
import { ChapterService } from "../../services/chapters.service";
import { getLibraries } from "../../services/cloud-functions";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";

@Component({
  selector: "page-toc",
  templateUrl: "toc.html",
  styleUrls: ["./toc.scss"],
})
export class TocPage {
  volume: Volume = null;
  volumes: Volume[] = [];
  volumeSections: any[] = [];
  viewOptions: any = [];
  viewBy: "Author" | "Chapter" | "Volume" | "Modality";
  chapters: Chapter[] = [];
  chapter: Chapter;
  contentInView: any[] = [];
  authors: Author[] = [];
  author: Author;
  searchTerm: string = "";
  searching: boolean = false;
  includedTracks: any[] = [];
  modalities: string[] = [];
  leadModalities: string[] = [];
  libraryObject: Library;
  parentPageLink: string;
  selectMode: boolean = false;
  subscribed: boolean = false;
  //libraryId: string;

  constructor(
    public actionSheetCtrl: ActionSheetController,
    public chapData: ChapterService,
    public router: Router,
    public popoverCtrl: PopoverController,
    public navCtrl: NavController,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private fireServ: FirebaseService,
    private analytics: AnalyticsService,
    private helperServ: HelperService
  ) {}

  async ngOnInit() {
    const libraryId = this.route.snapshot.paramMap.get("libraryId").split(" ").join("_").split("%20").join("_");
    const libraries = (await getLibraries()).data;
    this.libraryObject = libraries[libraries.findIndex((library) => library.id === libraryId)];

    firebase.auth().onAuthStateChanged((user) => {
      console.log("Init Login page - SignedIn: ", user);
      this.checkSubscribed();
    });

    this.analytics.event_pageView({
      page_title: this.libraryObject.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    const urlArray = this.router.routerState.snapshot.url.split("/");
    this.parentPageLink = urlArray.slice(0, urlArray.length - 2).join("/");

    console.log(this.libraryObject);

    if (this.libraryObject?.chapterIdPaths) {
      this.chapData.getChaptersFromPaths(this.libraryObject.chapterIdPaths).subscribe((chapters: firestore.DocumentData[]) => {
        this.handleChapterSub(chapters, true);
      });
    } else {
      this.chapData.getChapters(this.libraryObject.id).subscribe((chapters: firestore.DocumentData[]) => {
        this.handleChapterSub(chapters, false);
      });
    }

    this.checkSubscribed();
  
      this.route.queryParams.subscribe((queryParams) => {
        if (queryParams?.view) {
          this.updateView(queryParams.view);
        } else {
          this.updateView(this.libraryObject.volumes ? "Volume" : "Chapter");
        }
      });
  }

  async handleChapterSub(chapters: firestore.DocumentData[], isUserContent: boolean) {
    chapters.forEach((chap) => {
      if (this.libraryObject && this.libraryObject.volumes) {
        this.volume = {
          //id: chap.payload.doc.id,
          title: chap.data["volumeTitle"],
          number: chap.data["volumeNumber"],
          type: "volume",
          link: window.location.pathname + "/toc-detail/Volume/" + chap.data["volumeNumber"],
          display: chap.data["volumeTitle"],
        };
      }
      console.log("Chaps: ", chap.data);

      const authors = chap.data["authors"] as Array<string>;
      let authorDisplay: string = this.helperServ.buildAuthorDisplay(authors);

      let modalities: string[];
      if (this.libraryObject.modalities) {
        modalities = chap.data["modality"];
      }
      if (!isUserContent) {
        if (this.libraryObject.specialChapters) {
          this.chapter = {
            id: chap.id,
            number: chap.id,
            title: chap.data["title"],
            libraryId: this.libraryObject.id,
            volumeTitle: this.volume.title,
            modality: modalities,
            type: "chapter",
            link: "/r/" + this.libraryObject.id + "/chapter/" + chap.id,
            display: chap.data["title"] + " | " + authorDisplay,
          };
        } else {
          this.chapter = {
            id: chap.id,
            number: chap.id,
            title: chap.data["title"],
            libraryId: this.libraryObject.id,
            type: "chapter",
            link: "/r/" + this.libraryObject.id + "/chapter/" + chap.id,
            display: chap.data["title"] + " | " + authorDisplay,
          };
        }
      } else {
        this.chapter = {
          id: chap.id,
          number: chap.id,
          title: chap.data["title"],
          libraryId: chap.data["path"].split("/")[0],//null, //this.libraryObject.id,
          volumeTitle: "I dont know what this does",//this.volume.title,
          modality: modalities,
          type: "chapter",
          link: "/r/" + chap.id,
          display: chap.data["title"] + " | " + authorDisplay,
        }
      }

      this.author = {
        //full array of authors from Firebase
        names: chap.data["authors"],
        title: this.chapter.title,
        id: this.chapter.id,
        type: "author",
        link: this.chapter.link,
        display: chap.data["authors"] + " | " + this.chapter.title,
        libraryId: this.libraryObject.id,
      };
      if (!this.chapters.find((chapter) => chapter.id === this.chapter.id)) {
        this.chapters.splice(this.chapters.length, 1, this.chapter);
      }
      if (!this.authors.find((author) => author.names === this.author.names) && this.author.names) {
        this.authors.splice(this.authors.length, 1, this.author);
      }

      if (this.libraryObject.volumes) {
        if (!this.volumes.find((vol) => vol.number === this.volume.number)) {
          this.volumes.splice(this.volumes.length, 1, this.volume);
        }
      }
    });

    if (this.libraryObject.volumes) {
      this.volumes.sort((a, b) => a.number - b.number);
    }

    if (this.libraryObject.modalities) {
      this.chapters.forEach((chapter) => {
        chapter.modality.forEach((chapModality) => {
          if (!this.modalities.includes(chapModality)) {
            this.modalities.splice(this.modalities.length, 1, chapModality);
          }
        });
      });

      this.chapters.forEach((chap) => {
        if (!this.leadModalities.includes(chap.modality[0])) {
          this.leadModalities.splice(this.leadModalities.length, 1, chap.modality[0]);
        }
      });

      this.includedTracks = this.modalities;
    }
    if (!isUserContent) {
      console.log("soring")
      this.chapters.sort((a, b) => {
        return a.title > b.title ? 1 : -1;
      });
    }
    
    this.buildAndSortAuthors();

    if (
      this.contentInView.length === 0 &&
      this.route.snapshot.queryParams?.view !== "Author" &&
      this.route.snapshot.queryParams?.view !== "Chapter" &&
      this.route.snapshot.queryParams?.view !== "Volume" &&
      this.route.snapshot.queryParams?.view !== "Modality"
    ) {
      if (this.libraryObject.volumes) {
        console.log("Volumes: ", this.volumes);
        this.updateViewParam("");
      } else {
        this.updateViewParam("Chapter");
        this.updateView(this.libraryObject.volumes ? "Volume" : "Chapter");
      }
    } else if (this.contentInView.length === 0 && this.route.snapshot.queryParams?.view) {
      this.updateView(this.viewBy);
    }
    //this.authors.sort((a,b) => (a.name[0] > b.name[0] ? 1 : -1));
  }

  checkSubscribed() {
    this.fireServ
      .isSubscribedTo(this.libraryObject.id)
      .then((res) => {
        this.subscribed = res;
      })
      .catch((err) => {
        this.helperServ.presentToast("Error checking if you're subscribed. Please refresh and try again");
        this.subscribed = false;
      });
  }

  buildAndSortAuthors() {
    //console.log(this.authors)
    this.authors.forEach((author) => {
      //First author
      //const leadAuthor = author.names[0].split(' ')
      author.display = "";
      author.names.forEach((name) => {
        const splitName = name.split(" ");
        //console.log(name, splitName, splitName[1], splitName[0])
        if (name === author.names[author.names.length - 1]) {
          author.display += `${splitName[1]}, ${splitName[0]}`;
        } else {
          author.display += `${splitName[1]}, ${splitName[0]} & `;
        }
      });

      author.display += ` | ${author.title}`;
    });
    // 1 for the last name, 0 for first
    this.authors.sort((a, b) => {
      if (a.names[0].split(" ")[1] > b.names[0].split(" ")[1]) {
        return 1;
      } else if (a.names[0].split(" ")[1] < b.names[0].split(" ")[1]) {
        return -1;
      } else {
        if (a.title > b.title) {
          return 1;
        } else {
          return -1;
        }
      }
    });
  }

  async presentFilter() {
    let modalities = this.modalities;
    if (this.viewBy == "Modality") {
      modalities = this.leadModalities;
    }
    const modal = await this.modalCtrl.create({
      component: TocFilterComponent,
      componentProps: { includedTracks: this.includedTracks, modalities: modalities },
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();
    if (data) {
      this.includedTracks = data;
      if (this.viewBy == "Volume") {
        let filtered = this.chapters.filter((chapter) => chapter.modality.find((modality) => this.includedTracks.includes(modality)));

        this.contentInView = this.volumes.filter((volume) => filtered.find((chapter) => chapter.volumeTitle == volume.title));
      } else if (this.viewBy == "Chapter") {
        //this.contentInView = this.chapters.filter(chapter => chapter.modality.find(modality => this.includedTracks.includes(modality)))
        this.contentInView = this.contentInView.map((chapter) => () => {
          return { ...chapter, hidden: !chapter.modality.find((modality) => this.includedTracks.includes(modality)) };
        });
      } else if (this.viewBy == "Modality") {
        this.contentInView = [];
        this.includedTracks.forEach((modality) => {
          let chapters = this.chapters.filter((chapter) => chapter.modality[0] == modality);
          this.contentInView.push({ modality: modality, chapters: chapters });
        });
        this.contentInView.sort((a, b) => a.modality - b.modality);
      } else {
        //
      }
    }
  }

  async presentOptionsPopover(
    chapters: { title: string; id: string; hidden: boolean; selected: boolean }[],
    showSelect: boolean,
    event?: Event
  ) {
    const popover = await this.popoverCtrl.create({
      component: OptionsPopoverComponent,
      componentProps: { chapters: chapters, contentType: "chapter", library: this.libraryObject.id, showSelect },
      event,
      translucent: true,
      backdropDismiss: true,
    });

    popover.onWillDismiss().then((res) => {
      if (res.data && res.data.select && showSelect) {
        this.selectMode = true;
        chapters[0].selected = true;
      }
    });
    await popover.present();
  }

  async subscribeToPopover() {
    if (!this.fireServ.fireAuth.auth.currentUser) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true, false, 5000);
      return;
    }

    if (this.libraryObject.id) {
      const popover = await this.modalCtrl.create({
        component: PaymentPopoverComponent,
        componentProps: { product: this.libraryObject, subscribed: this.subscribed },
        //translucent: true,
        backdropDismiss: true,
        //cssClass: "add-to-popover"
      });

      await popover.present();

      popover.onWillDismiss().then((response) => {
        if (response.data) {
          this.subscribed = response.data.subscribed;
        }
      });
    }
  }

  presentSelectOptions(event) {
    console.log("Event: ", event);
    if (this.contentInView.filter((content) => content.selected).length === 0) {
      this.helperServ.presentToast("Please select one or more.");
      return;
    }
    if (this.viewBy == "Author") {
      console.log(
        "Present select options for these author chapters: ",
        this.contentInView.filter((content) => content.selected)
      );
    } else if (this.viewBy == "Volume") {
    } else if (this.viewBy == "Chapter") {
      console.log(
        "Present select options for these chapters: ",
        this.contentInView.filter((content) => content.selected)
      );
      this.presentOptionsPopover(
        this.contentInView.filter((content) => content.selected),
        false
      );
    } else if (this.viewBy == "Modality") {
    }
  }

  disableSelectMode() {
    this.contentInView.map((content) => {
      return { ...content, selected: false };
    });
    this.selectMode = false;
  }

  goBack() {
    if (this.parentPageLink == "/libraries") {
      if (this.subscribed) {
        this.navCtrl.navigateBack(["/home"], {
          queryParams: {
            subTab: "myPurchases",
          },
        });
      } else {
        this.navCtrl.navigateBack(["/store"]);
      }
    } else {
      this.navCtrl.navigateBack([this.parentPageLink]);
    }
    //window.history.back()
  }

  async presentVolumeOptionsPopover(event: Event, volume: Volume) {
    const popover = await this.popoverCtrl.create({
      component: MyCollectionsPopoverComponent,
      componentProps: { libraryLink: volume.link, contentTitle: volume.title },
      event,
      translucent: true,
      backdropDismiss: true,
    });
    await popover.present();
  }

  async navigateToOtherPage(page: any[]) {
    console.log(page);
    if (page == null) {
      console.log("chapterId not found");
    } else {
      let showPreview = false;
      if (this.router.routerState.snapshot.url.includes("store")) {
        showPreview = true;
      }
      let navigationExtras: NavigationExtras = {
        state: {
          chapter: page,
          showPreview: showPreview,
        },
      };
      this.router.navigate([page["link"]], navigationExtras);
    }
  }

  updateView(view: "Author" | "Chapter" | "Volume" | "Modality") {
    this.viewBy = view;
    console.log("View: ", view, this.viewBy);

    if (this.viewBy == "Chapter") {
      this.contentInView = this.chapters.map((chap) => {
        return { ...chap, hidden: false, selected: false };
      });
    } else if (this.viewBy == "Volume") {
      this.contentInView = this.volumes;
    } else if (this.viewBy == "Author") {
      this.contentInView = this.authors.map((author) => {
        return { ...author, hidden: false, selected: false };
      });
    } else if (this.viewBy == "Modality") {
      this.contentInView = [];
      this.leadModalities.forEach((modality) => {
        let chapters = this.chapters.filter((chapter) => chapter.modality[0] == modality);
        this.contentInView.push({ modality: modality, chapters: chapters });
      });
      this.contentInView.sort((a, b) => a.modality + b.modality);
    }

    console.log("Content In View: ", this.contentInView);
  }

  updateViewParam(view: string) {
    this.router.navigate([], {
      queryParams:
        view && ((view != "Volume" && this.libraryObject.volumes) || (view != "Chapter" && !this.libraryObject.volumes)) ? { view } : {},
      relativeTo: this.route,
    });
  }

  search() {
    console.log(this.searchTerm);
    if (!this.searchTerm || this.searchTerm.length < 3) {
      this.helperServ.presentToast("Please enter 3 or more characters");
      return;
    }

    this.router.navigate(["/search-results"], { queryParams: { search_query: this.searchTerm } });
  }
}
