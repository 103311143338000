import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TocPage } from './toc';
import { TocPageRoutingModule } from './toc-routing.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TocFilterComponent } from '../../components/toc-filter/toc-filter.component';
import { AddToPopoverComponent } from '../../components/add-to-popover/add-to-popover.component';
import { VolumeComponent } from '../../components/volume/volume.component';
import { ChapterComponent } from '../../components/chapter/chapter.component';
import { ComponentsModule } from '../../components/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TocPageRoutingModule,
    PdfViewerModule,
    ComponentsModule
  ],
  declarations: [
    TocPage,
    TocFilterComponent,
    VolumeComponent,
  ],
})
export class TocModule {}
