import { Component } from "@angular/core";

import { Router } from "@angular/router";
import { PopoverController } from "@ionic/angular";

@Component({
  template: `
    <ion-list>
      <ion-item [button]="true" (click)="close('https://nexus4change.com/')">
        <ion-label>NEXUS4change</ion-label>
      </ion-item>
      <ion-item [button]="true" (click)="close('https://nexus4change.com/team')">
        <ion-label>NEXUS4change Team</ion-label>
      </ion-item>
      <ion-item [button]="true" (click)="close('https://www.facebook.com/wearenexus4change/')">
        <ion-label>Facebook</ion-label>
      </ion-item>
      <ion-item [button]="true" (click)="close('https://twitter.com/NEXUS4change')">
        <ion-label>Twitter</ion-label>
      </ion-item>
      <ion-item [button]="true" (click)="support()">
        <ion-label>Support</ion-label>
      </ion-item>
    </ion-list>
  `,
})
export class PopoverPage {
  constructor(public router: Router, public popoverCtrl: PopoverController) {}

  support() {
    this.router.navigateByUrl("/support");
    this.popoverCtrl.dismiss();
  }

  close(url: string) {
    window.open(url, "_blank");
    this.popoverCtrl.dismiss();
  }
}
