import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PopoverController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { Profile } from "../../pages/profile/profile.page";
import { UserData } from "../../providers/user-data";
import { UserService } from "../../services/user.service";
import { AccountOptionsPopoverComponent } from "../account-options-popover/account-options-popover.component";

@Component({
  selector: "app-account-options",
  templateUrl: "./account-options.component.html",
  styleUrls: ["./account-options.component.scss"],
})
export class AccountOptionsComponent implements OnInit {
  profile: Profile;
  guestMode: boolean;

  subscriptions: Subscription[] = [];
  constructor(
    public router: Router,
    public userData: UserData,
    private popoverCtrl: PopoverController,
    private userServ: UserService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const sub = this.userServ.$userProfile.subscribe((profile) => {
      this.profile = profile;
      if (this.profile) {
        this.guestMode = false;
      } else {
        this.guestMode = true;
      }

      this.cdRef.detectChanges();
    });
    this.subscriptions.push(sub);
  }

  async presentOptionsPopover(event: Event) {
    const popover = await this.popoverCtrl.create({
      component: AccountOptionsPopoverComponent,
      componentProps: {},
      event,
      translucent: true,
      backdropDismiss: true,
    });
    await popover.present();
  }

  async login() {
    await this.router.navigateByUrl("/login");
  }
}
