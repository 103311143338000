import { Component, Input, OnInit } from "@angular/core";
import { LoadingController, ModalController, NavParams } from "@ionic/angular";
import { UserBook, UserCollection } from "../../interfaces/firebase-interfaces";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";

@Component({
  selector: "my-library-add-popover",
  templateUrl: "./my-library-add-popover.component.html",
  styleUrls: ["./my-library-add-popover.component.scss"],
})
export class MyLibraryAddPopoverComponent implements OnInit {
  @Input() content: UserBook;

  collections: UserCollection[] = [{ id: "", title: "My 1st Whole", books: [], owner: null, createdAt: null, updatedAt: null }];
  newCollectionTitle: string;

  constructor(
    public modalCtrl: ModalController,
    public fireServ: FirebaseService,
    public navParams: NavParams,
    private helperServ: HelperService,
    private loadCtrl: LoadingController
  ) {}

  ngOnInit() {
    this.setCollectionTitleOptions();
  }

  setCollectionTitleOptions() {
    this.collections = this.fireServ.userCollections;
    if (this.collections.findIndex((userBook) => userBook.title == "My 1st Whole") == -1) {
      this.collections.unshift({ id: "-1", title: "My 1st Whole", owner: "", books: [], updatedAt: null, createdAt: null });
    }
  }

  async addToCollectionList(collection?: UserCollection) {
    if (!collection) {
      if (this.newCollectionTitle == null) {
        this.newCollectionTitle = "myWhole #" + (this.collections.length + 1);
      }

      collection = { id: "-1", title: this.newCollectionTitle, books: [], owner: null, createdAt: null, updatedAt: null };

      const foundIndex = this.collections.findIndex(
        (storedCollection) =>
          collection?.title?.toUpperCase().split(" ").join("") == storedCollection?.title?.toUpperCase().split(" ").join("")
      );

      if (foundIndex > -1) {
        if (!confirm("This whole already exists. Would you like to add it to that?")) {
          return;
        }

        collection = this.collections[foundIndex];
      }
    }
    let loadingPopover = await this.loadCtrl.create({message: "Loading... Please refresh after 30 seconds"});
    await loadingPopover.present();
    await this.fireServ.updateCollectionList(true, [this.content], collection).then(async () => {
      await loadingPopover.dismiss();
    });
    this.close();
  }

  async close() {
    await this.modalCtrl.dismiss();
  }
}
