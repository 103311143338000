import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Plugins, SharePluginWeb } from "@capacitor/core";
import { ActionSheetController, IonicSlides } from "@ionic/angular";
import { firestore } from "firebase";
import { Subscription } from "rxjs";
import SwiperCore from "swiper";
import { Viewable } from "../../components/bit-cover/bit-cover.component";
import { Library } from "../../interfaces/firebase-interfaces";
import { AnalyticsService } from "../../services/analytics.service";
import { ChapterService } from "../../services/chapters.service";
import { getLibraries } from "../../services/cloud-functions";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";
import { BIT_TOPICS } from "../../services/remote-config.service";
import { UserService } from "../../services/user.service";
import { Profile } from "../profile/profile.page";
import { StoreCollection } from "../store/store.page";
import { RemoteConfigService } from "./../../services/remote-config.service";

export enum BIT_TYPES {
  "article" = "article",
  "book" = "book",
  "chapter" = "chapter",
  "essay" = "essay",
  "handout" = "handout",
  "instructions" = "instructions",
  "jokes" = "jokes",
  "letter" = "letter",
  "lyrics" = "lyrics",
  "manual" = "manual",
  "memoir" = "memoir",
  "poem" = "poem",
  "recipe" = "recipe",
  "story" = "story",
  "worksheet" = "worksheet",
  "other" = "other",
}

export enum BIT_TYPES_MAP {
  "article" = "Article",
  "book" = "Book",
  "chapter" = "Chapter",
  "essay" = "Essay",
  "handout" = "Handout",
  "instructions" = "Instructions",
  "jokes" = "Jokes",
  "letter" = "Letter",
  "lyrics" = "Lyrics",
  "manual" = "Manual",
  "memoir" = "Memoir",
  "poem" = "Poem",
  "recipe" = "Recipe",
  "story" = "Story",
  "worksheet" = "Worksheet",
  "other" = "Other",
}

// export enum BIT_TOPICS {
//   "arts_architecture" = "Arts & Architecture",
//   "business_economics" = "Business & Economics",
//   "collaboration" = "Collaboration",
//   "computers_technology" = "Computers & Technology",
//   "education_teaching" = "Education & Teaching",
//   "fiction" = "Fiction",
//   "history_archeology" = "History & Archeology",
//   "music_performing_arts" = "Music & Performing Arts",
//   "non_fiction" = "Non-Fiction",
//   "organizational_development" = "Organizational Development, Change and Behavior",
//   "parenting_family_relationships" = "Parenting, Family & Relationships",
//   "political_social_science" = "Political & Social Science",
//   "psychology" = "Psychology",
//   "other" = "Other",
// }

SwiperCore.use([IonicSlides]);
@Component({
  selector: "app-know-bits",
  templateUrl: "./know-bits.page.html",
  styleUrls: ["./know-bits.page.scss"],
})
export class KnowBitsPage implements OnInit, OnDestroy {
  shareLink: string = "https://app.mylibrary.world/know-bitz";

  BIT_TYPES = this.remoteConfigServ.BIT_TYPES;
  BIT_TYPES_MAP = this.remoteConfigServ.BIT_TYPES_MAP;
  BIT_TOPICS = this.remoteConfigServ.BIT_TOPICS;
  LENGTH_OF_POPULAR: number = 20;

  freeContent: StoreCollection[] = [];

  subscriptions: Subscription[] = [];
  chaptersSub: Subscription;

  swipers: (SwiperCore & { topic: string })[] = [];

  searchTerm: string = "";
  searching: boolean = false;

  knowBitTopics: { topic: string; bits: Viewable[] }[] = []; // all BIT_TOPICS with Popular and Collections

  signedIn: boolean = false;

  profile: Profile;

  constructor(
    private fireServ: FirebaseService,
    private router: Router,
    private helperServ: HelperService,
    private chapServ: ChapterService,
    private actionSheetCtrl: ActionSheetController,
    private analytics: AnalyticsService,
    private userServ: UserService,
    public remoteConfigServ: RemoteConfigService
  ) {}

  ngOnDestroy(): void {
    this.destorySubs();
  }

  async ngOnInit() {
    await this.helperServ.presentLoader();

    await this.getContent();

    this.watchUser();
  }

  initBitTypes() {
    this.knowBitTopics = [];
    Object.values(this.remoteConfigServ.BIT_TOPICS).forEach((topic: BIT_TOPICS) => {
      this.knowBitTopics.push({
        topic,
        bits: [],
      });
    });

    this.knowBitTopics.push({ topic: "Popular", bits: [] }, { topic: "Collections", bits: [] });
  }

  destorySubs() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  watchUser() {
    const sub = this.userServ.$userProfile.subscribe((profile) => {
      this.signedIn = profile ? true : false;
      this.profile = profile;
    });

    this.subscriptions.push(sub);
  }

  async getContent() {
    await this.fireServ.getAllContent();
    this.initBitTypes();
    return this.fireServ.getAllContent()
      .then(async (libraries) => {
        this.freeContent = libraries
          .filter((library) => library.price == 0)
          .map((library) => {
            return { ...library, subscribed: false };
          });

        await Promise.all(
          this.freeContent.map(async (collection) => {
            const chaps = await this.getChapters(collection.id);
            console.log("collection", collection, chaps);
            return chaps;
          })
        );
        let Collections: Library[] = [];
        this.fireServ.Libraries.forEach((val) => {
          if (val.volumes) {
            Collections.push(val);
          }
        });
        this.knowBitTopics.find((val) => val.topic == "Collections").bits = Collections;
        this.knowBitTopics.sort((a, b) => {
          if (a.topic == "Popular") {
            return -1;
          } else if (b.topic == "Popular") {
            return 1;
          } else {
            return this.getSumViews(b.bits) - this.getSumViews(a.bits);
          }
        });
        this.knowBitTopics.forEach((data) => {
          if (data.topic == "Collections") {
            data.bits.sort((a, b) => b.avgRating - a.avgRating);
          } else {
            data.bits.sort((a, b) => b.views - a.views);
          }
        });
        let popular: Viewable[] = [];
        this.knowBitTopics.forEach((value) => {
          value.bits.forEach((bit, i) => {
            if (i < this.LENGTH_OF_POPULAR) {
              popular.push(bit);
            } else {
              return;
            }
          });
        });
        popular.sort((a, b) => b.views - a.views);
        popular.length = this.LENGTH_OF_POPULAR;
        this.knowBitTopics.find((val) => val.topic == "Popular").bits = popular;
      })

      .finally(async () => await this.helperServ.dismissLoader());
  }

  getSumViews(bits: Viewable[]) {
    let sum = 0;
    bits.forEach((bit: Viewable) => {
      sum += bit.views;
    });
    return sum;
  }

  getChapters(libraryId: string) {
    return new Promise<void>((resolve, reject) => {
      if (this.chaptersSub) {
        this.chaptersSub.unsubscribe();
      }

      this.subscriptions.push(
        this.chapServ.getChapters(libraryId).subscribe(
          (chapters: firestore.DocumentData[]) => {
            chapters.forEach((chap: any) => {
              const chapter: Viewable = {
                id: chap.id,
                number: chap.id,
                title: chap.data["title"],
                libraryId: libraryId,
                // @ts-ignore
                type: "chapter",
                link: "/r/" + libraryId + "/chapter/" + chap.id,
                bitType: chap.data["bitType"] || "other",
                topics: chap.data["topics"] || [],
                cover_url: chap.data["cover_url"],
                authors: chap.data["authors"],
                creators: chap.data["authors"],
                views: chap.data["views"]?.length ? chap.data["views"]?.length : 0,
                avgRating: chap.data["avgReview"],
                startingId: chap.id,
              };
              // There is a much more elegent way to do this, probably by creating a type
              // that the above is which inherits from Viewable so it can be passed to
              // the cover without issue while also retaining the needed data.
              // The below function is the patchwork solution for fixing the swiper on the Popular row.
              // The issue was is that the page was being loaded while Popular was empty,
              // so, by arbitrarily filling it early Swiper loads expecting LENGTH_OF_POPULAR
              // slides and will work as expected
              if (this.knowBitTopics.find((val) => val.topic == "Popular").bits.length < this.LENGTH_OF_POPULAR) {
                this.knowBitTopics.find((val) => val.topic == "Popular").bits.push(chapter);
              }
              const foundIndex = this.knowBitTopics.findIndex((topic) => {
                const found = topic.bits.findIndex((bit) => {
                  return bit.id && bit.id == chapter.id;
                });
                return found != -1;
              });
              if (foundIndex == -1) {
                // @ts-ignore
                const chapTopic = chapter.topics && chapter.topics.length > 0 ? chapter.topics[0] : "other";
                const foundIndex = this.knowBitTopics.findIndex((topic) => topic.topic == BIT_TOPICS[chapTopic]);
                if (foundIndex != -1) {
                  this.knowBitTopics[foundIndex].bits.push(chapter);
                } else {
                  this.knowBitTopics[this.knowBitTopics.length - 1].bits.push(chapter);
                }
              }
            });
          },
          (err) => {
            console.error("Error: ", err);
          },
          () => {
            resolve();
          }
        )
      );
    });
  }

  async uploadEpub() {
    await this.userServ.uploadEpub();
  }

  onSwiper(swiper: SwiperCore, topic: string) {
    swiper["topic"] = topic;
    this.swipers.push(swiper as SwiperCore & { topic: string });
  }

  search() {
    console.log(this.searchTerm);
    if (!this.searchTerm || this.searchTerm.length < 3) {
      this.helperServ.presentToast("Please enter 3 or more characters");
      return;
    }

    this.router.navigate(["search-results"], { queryParams: { search_query: this.searchTerm } });
  }

  nextSlide(topic: string) {
    const foundIndex = this.swipers.findIndex((swiper) => swiper["topic"] == topic);
    if (foundIndex != -1) {
      this.swipers[foundIndex].slideNext();
    }
  }

  prevSlide(topic: string) {
    const foundIndex = this.swipers.findIndex((swiper) => swiper["topic"] == topic);
    if (foundIndex != -1) {
      this.swipers[foundIndex].slidePrev();
    }
  }

  async share() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: "Share the app?",
      mode: "ios",
      //subHeader: copyLink,
      buttons: [
        {
          text: "Copy Link",
          handler: () => {
            //// Different Browser capabilties
            if (window.navigator.userAgent.includes("Chrome")) {
              Plugins.Clipboard.write({ url: this.shareLink })
                .then((value) => {
                  this.copiedMessage("Successfully copied the link to your clipboard!");
                  this.analytics.event_share({ share_method: "link", link: this.shareLink });
                })
                .catch((error) => {
                  console.log(error);
                  this.copiedMessage("Something went wrong. Please try again.");
                });
            } else {
              // Create new element
              var el = document.createElement("textarea");
              // Set value (string to be copied)
              el.value = this.shareLink;
              // Set non-editable to avoid focus and move outside of view
              el.setAttribute("readonly", "");
              //el.style = {position: 'absolute', left: '-9999px'};
              document.body.appendChild(el);
              // Select text inside element

              el.select();
              // Copy text to clipboard
              if (document.execCommand("copy").valueOf()) {
                this.copiedMessage("Successfully copied the link to your clipboard!");
                this.analytics.event_share({ share_method: "link", link: this.shareLink });
              } else {
                this.copiedMessage("Something went wrong. Please try again.");
              }
              // Remove temporary element
              document.body.removeChild(el);
            }
          },
        },
        {
          text: "Share via ... (for mobile & some browsers)",
          handler: () => {
            this.presentShareOptions();
          },
        },
        {
          text: "Cancel",
          role: "cancel",
        },
      ],
    });

    await actionSheet.present();
  }

  async presentShareOptions() {
    await SharePluginWeb.prototype
      .share({
        title: "See cool stuff",
        text: "myLibrary",
        url: this.shareLink,
        dialogTitle: "Share with buddies",
      })
      .then(() => {
        this.analytics.event_share({ share_method: "Native options", link: this.shareLink });
      })
      .catch((error) => {
        console.log(error, "Dang it");
      });
  }

  async copiedMessage(message: string) {
    this.helperServ.presentToast(message, false, true, 5000);
  }

  async handleRefresh(event) {
    this.destorySubs();
    await this.getContent();
    this.watchUser();
    event.target.complete();
  }

  filterTopics(knowBitTopics: any[]) {
    return knowBitTopics.filter((topic) => topic.bits.length > 0);
  }
}
