import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage-angular";
import { AngularFireModule } from "angularfire2";
import { AngularFireAuth, AngularFireAuthModule } from "angularfire2/auth";
import { AngularFirestoreModule, FirestoreSettingsToken } from "angularfire2/firestore";
import { AngularFireFunctionsModule } from "angularfire2/functions";
import { AngularFireStorageModule } from "angularfire2/storage";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AddToPopoverComponent } from "./components/add-to-popover/add-to-popover.component";
import { LinkPopover } from "./components/chapter-links-popover/chapter-links-popover";
import { ChapterResourcesComponent } from "./components/chapter-resources-popover/chapter-resources-popover.component";
import { MatrixComponent } from "./components/matrix/matrix.component";
import { OptionsPopoverComponent } from "./components/options-popover/options-popover.component";
import { PaymentPopoverComponent } from "./components/payment-popover/payment-popover.component";
import { ResourcePopoverComponent } from "./components/resource-popover/resource-popover.component";
import { RatingPopoverComponent } from "./components/rating-popover/rating-popover.component";
import { VideoPopoverPage } from "./components/video-popover/video-popover";
import { AboutModule } from "./pages/about/about.module";
import { AccountModule } from "./pages/account/account.module";
import { ChapterModule } from "./pages/chapter/chapter.module";
import { CommunityPageModule } from "./pages/community/community.module";
import { HomeModule } from "./pages/home/home.module";
import { KnowBitsPageModule } from "./pages/know-bits/know-bits.module";
import { LibrariesPageModule } from "./pages/libraries/libraries.module";
import { MyBooksModule } from "./pages/my-books/my-books.module";
import { ScheduleModule } from "./pages/schedule/schedule.module";
import { SearchResultsPageModule } from "./pages/search-results/search-results.module";
import { SessionDetailModule } from "./pages/session-detail/session-detail.module";
import { SpeakerDetailModule } from "./pages/speaker-detail/speaker-detail.module";
import { SpeakerListModule } from "./pages/speaker-list/speaker-list.module";
import { StorePageModule } from "./pages/store/store.module";
import { SupportModule } from "./pages/support/support.module";
// import { TabsPageRoutingModule } from "./pages/tabs-page/tabs-page-routing.module";
import { TocDetailModule } from "./pages/toc-detail/toc-detail.module";
import { TocModule } from "./pages/toc/toc.module";
import { AuthGuardServiceService } from "./services/auth-guard-service.service";
import { SubscriptionGuardServiceService } from "./services/subscription-guard-service";

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    TocModule,
    ChapterModule,
    PdfViewerModule,
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
    HomeModule,
    SearchResultsPageModule,
    TocModule,
    ChapterModule,
    TocDetailModule,
    AboutModule,
    AccountModule,
    SupportModule,
    CommonModule,
    // MapModule,
    ScheduleModule,
    SessionDetailModule,
    SpeakerDetailModule,
    SpeakerListModule,
    MyBooksModule,
    // TabsPageRoutingModule,
    LibrariesPageModule,
    StorePageModule,
    CommunityPageModule,
    KnowBitsPageModule,
  ],
  declarations: [
    AppComponent,
    AddToPopoverComponent,
    VideoPopoverPage,
    ChapterResourcesComponent,
    LinkPopover,
    OptionsPopoverComponent,
    ResourcePopoverComponent,
    PaymentPopoverComponent,
    MatrixComponent,
    RatingPopoverComponent,
  ],
  providers: [
    AngularFireAuth,
    { provide: FirestoreSettingsToken, useValue: {} },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthGuardServiceService,
    SubscriptionGuardServiceService,
    AppComponent,
  ],
  // entryComponents: [
  //   AddToPopoverComponent,
  //   VideoPopoverPage,
  //   ChapterResourcesComponent,
  //   LinkPopover,
  //   OptionsPopoverComponent,
  //   ResourcePopoverComponent,
  //   PaymentPopoverComponent,
  //   MatrixComponent,
  // ],
  bootstrap: [AppComponent],
})
export class AppModule {}
