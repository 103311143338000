import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnInit {

  @Input() annotation: any;

  note: string;

  constructor(
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit() {
    console.log("annotation", this.annotation)
    this.note = this.annotation.data.note
  }

  ionViewWillLeave() {
    this.annotation.update({note: this.note})
    //sessionStorage.setItem('note', JSON.stringify(this.annotation))
  }

  remove() {
    this.popoverCtrl.dismiss({remove: true})
  }

}
