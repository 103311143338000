import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertController, PopoverController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { LogoutPopoverComponent } from "../../components/logout-popover/logout-popover.component";
import { Chapter } from "../../interfaces/firebase-interfaces";
import { UserData } from "../../providers/user-data";
import { HelperService } from "../../services/helper.service";
import { PaymentService } from "../../services/payment.service";
import { UserService } from "../../services/user.service";
import { Profile } from "../profile/profile.page";

@Component({
  selector: "page-account",
  templateUrl: "account.html",
  styleUrls: ["./account.scss"],
})
export class AccountPage implements OnDestroy, OnInit {
  username: string;
  profile: Profile = {
    firstName: "",
    lastName: "",
    website: "https://",
    linkedIn: "https://",
    expertise: "",
    professionalRoles: "",
    public: false,
    about: "",
    profilePic: "https://www.gravatar.com/avatar?d=mm&s=140",
    uid: "",
    email: "",
    bits: [],
  };

  guestMode = false;

  subscriptions: Subscription[] = [];

  constructor(
    public alertCtrl: AlertController,
    public router: Router,
    public userData: UserData,
    private cdRef: ChangeDetectorRef,
    private userServ: UserService,
    private payServ: PaymentService,
    private popoverCtrl: PopoverController,
    public helperServ: HelperService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.refresh) {
        this.connectWithStripe();
      } else if (params.return) {
        // TODO: Get status of connected account and Show success or error message
      }
    });
  }

  ngOnInit() {
    const sub = this.userServ.$userProfile.subscribe((profile) => {
      this.profile = profile;
      if (this.profile) {
        this.guestMode = false;
        if (this.profile.bits) {
          this.buildBits();
        }
      } else {
        this.guestMode = true;
      }
      this.cdRef.detectChanges();
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  buildBits() {
    this.profile.bits = this.profile.bits.map((bit) => {
      return {
        ...bit,
        libraryId: this.profile.uid,
        type: "chapter",
        link: `/r/${this.profile.uid}/chapter/${bit.id}`,
        number: bit.id,
        display: `${bit.title} | ${bit.authors[0]}`,
      };
    });
  }

  async uploadContent() {
    await this.userServ.uploadEpub();
  }

  remove(bit: Chapter) {
    this.profile.bits.splice(
      this.profile.bits.findIndex((b) => b.id == bit.id),
      1
    );
  }

  async presentLogout(event: Event) {
    const popover = await this.popoverCtrl.create({
      component: LogoutPopoverComponent,
      componentProps: { profile: this.profile },
      event,
      translucent: true,
      backdropDismiss: true,
    });
    await popover.present();
  }

  support() {
    this.router.navigateByUrl("/support");
  }

  editProfile() {
    this.router.navigateByUrl("/profile");
  }

  viewTransactionHistory() {
    this.payServ.openStripePortal(this.profile);
  }

  connectWithStripe() {
    if (this.profile.stripeConnectedAccountId) {
      this.payServ.openConnectedAccountDashboard(this.profile);
    } else {
      this.payServ.connectWithStripe(this.profile);
    }
  }
}
