import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardServiceService } from "./services/auth-guard-service.service";

const routes: Routes = [
  {
    path: "app/tabs/:tab",
    redirectTo: ":tab",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () => import("./pages/auth/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "register",
    loadChildren: () => import("./pages/auth/register/register.module").then((m) => m.RegisterPageModule),
  },
  {
    path: "profile",
    loadChildren: () => import("./pages/profile/profile.module").then((m) => m.ProfilePageModule),
    canActivate: [AuthGuardServiceService],
  },
  {
    path: "r/:libraryId/:contentType/:id",
    loadChildren: () => import("./pages/chapter/chapter.module").then((m) => m.ChapterModule),
    canActivate: [],
  },
  {
    path: "",
    redirectTo: "/know-bitz",
    pathMatch: "full",
  },
  {
    path: "",
    loadChildren: () => import("./pages/tabs-page/tabs-page.module").then((m) => m.TabsModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
