import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";

import { IonicModule } from "@ionic/angular";

import { ComponentsModule } from "../../components/components.module";
import { LibrariesPage } from "./libraries.page";

const routes: Routes = [
  {
    path: "",
    component: LibrariesPage,
  },
];

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ComponentsModule, RouterModule.forChild(routes)],
  declarations: [LibrariesPage],
  exports: [LibrariesPage],
})
export class LibrariesPageModule {}
