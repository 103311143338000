import firebase from "firebase/app";
import "firebase/remote-config";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  appVersion: require("../../package.json").version,
  firebase: {
    apiKey: "AIzaSyC2yB0eeygAShjUURt0CMhKW-u8uhJBkTo",
    authDomain: "mylibrary-dev-d2f41.firebaseapp.com",
    databaseURL: "https://mylibrary-dev-d2f41.firebaseio.com",
    projectId: "mylibrary-dev-d2f41",
    storageBucket: "mylibrary-dev-d2f41.appspot.com",
    messagingSenderId: "564129866107",
    appId: "1:564129866107:web:3ecc8a49e5820701359dde",
    locationID: "us-central",
    locationId: "us-central",
    measurementId: "G-47FHKF7QCS",
  },
};

// Initialize Firebase
export const app = firebase.initializeApp(environment.firebase);

export const functions = firebase.functions();
// functions.useFunctionsEmulator("http://127.0.0.1:5001");

// Initialize Remote Config and get a reference to the service
export const remoteConfig = firebase.remoteConfig();

remoteConfig.settings.minimumFetchIntervalMillis = 300000;

remoteConfig.defaultConfig = {
  bit_topics: `{"arts_architecture":"Arts & Architecture","business_economics":"Business & Economics","collaboration":"Collaboration","computers_technology":"Computers & Technology","culture_sociology":"Culture & Sociology","current":"Current Events","design":"Design","education_teaching":"Education & Teaching","fiction":"Fiction","healthcare":"Healthcare","history_archeology":"History & Archeology","music_performing_arts":"Music & Performing Arts","non_fiction":"Non-Fiction","organizational_development":"Organization Development & Change","parenting_family_relationships":"Parenting, Family & Relationships","philosophy":"Philosophy","poetry":"Poetry","political_science":"Political Science","political_social_science":"Political & Social Science","psychology":"Psychology","social_science":"Social and Behavioral Science","other":"Other"}`,
  bit_types: `{"article":"article","book":"book","chapter":"chapter","essay":"essay","handout":"handout","instructions":"instructions","jokes":"jokes","letter":"letter","lyrics":"lyrics","manual":"manual","memoir":"memoir","poem":"poem","recipe":"recipe","story":"story","worksheet":"worksheet","other":"other"}`,
  bit_types_map: `{"article":"Article","book":"Book","chapter":"Chapter","essay":"Essay","handout":"Handout","instructions":"Instructions","jokes":"Jokes","letter":"Letter","lyrics":"Lyrics","manual":"Manual","memoir":"Memoir","poem":"Poem","recipe":"Recipe","story":"Story","worksheet":"Worksheet","other":"Other"}`,
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
