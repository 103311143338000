import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { ComponentsModule } from "../../components/components.module";
import { ImagePopoverComponent } from "../../components/image-popover/image-popover.component";
import { NoteComponent } from "../../components/note/note.component";
import { TextOptionsComponent } from "../../components/text-options/text-options.component";
import { ChapterPage } from "./chapter";
import { ChapterPageRoutingModule } from "./chapter-routing.module";

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ChapterPageRoutingModule, PdfViewerModule, ComponentsModule],
  declarations: [ChapterPage, ImagePopoverComponent, NoteComponent, TextOptionsComponent],
})
export class ChapterModule {}
