import { CdkDragDrop, CdkDragExit, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { AlertController, LoadingController, PopoverController } from "@ionic/angular";
import firebase from "firebase";
import { Subscription } from "rxjs";
import { MyCollectionsPopoverComponent } from "../../components/my-collections-popover/my-collections-popover.component";
import { MyLibraryPopoverComponent } from "../../components/my-library-popover/my-library-popover.component";
import { OptionsPopoverComponent } from "../../components/options-popover/options-popover.component";
import { UserBook, UserChapter, UserCollection } from "../../interfaces/firebase-interfaces";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "page-my-books",
  templateUrl: "my-books.html",
  styleUrls: ["./my-books.scss"],
})
export class MyBooksPage implements OnDestroy {
  // @ViewChild("accordionGroup", { static: true }) accordionGroup: IonAccordionGroup;
  // myDisplayedList:  UserCollection[] | UserBook[] | UserChapter[] = []
  imageView = false;
  segment: "bit" | "part" | "whole" = "whole";
  // subSegment: "all" | "selected" = "all";

  userCollections: UserCollection[] = [];
  userBooks: UserBook[] = [];
  userChapters: UserChapter[] = [];

  selectedCollection: UserCollection;
  selectedBook: UserBook;

  loadingPopover: HTMLIonLoadingElement;
  guestMode: boolean = false;

  noParts: boolean[] = [];
  noBits: { [id: string]: boolean } = {};

  subscriptions: Subscription[] = [];

  isDropping = false;

  constructor(
    public router: Router,
    private fireServ: FirebaseService,
    public popoverCtrl: PopoverController,
    public alertController: AlertController,
    private loadCtrl: LoadingController,
    private userServ: UserService,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    public helperServ: HelperService
  ) {
    activatedRoute.queryParams.subscribe(async (queryParams) => {
      if (queryParams.s) {
        this.segment = queryParams.s;
      } else {
        await router.navigate([], { queryParams: { s: "whole" }, queryParamsHandling: "merge" });
      }
      this.checkForBits();
      this.cdRef.markForCheck();
    });

    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        this.guestMode = false;
      } else {
        this.guestMode = true;
        return;
      }
      this.cdRef.detectChanges();
    });

    if (!this.guestMode) {
      this.unsubscribeAll();
      this.setUserContent();
    }
  }

  ionViewWillEnter() {}

  async ionViewWillLeave() {
    if (!this.loadingPopover.hidden) {
      await this.dismissLoader();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  handleRefresh($event) {
    this.unsubscribeAll();
    this.setUserContent();
    $event.target.complete();
  }

  setDefaultContent() {
    this.userChapters = [
      {
        title: "Guest Bit",
        libraryId: "",
        custom: false,
        originalChapterId: "",
        id: "-1",
        createdAt: null,
        updatedAt: null,
      },
    ];

    this.userBooks = [
      {
        title: "Guest Part",
        chapters: this.userChapters,
        owner: "Guest",
        id: "-1",
        createdAt: null,
        updatedAt: null,
      },
    ];

    this.userCollections = [
      {
        title: "Guest Whole",
        books: this.userBooks,
        owner: "Guest",
        id: "-1",
        createdAt: null,
        updatedAt: null,
      },
    ];
  }

  async presentLoader() {
    this.loadingPopover = await this.loadCtrl.create({
      message: "Loading...",
      backdropDismiss: true,
    });
    await this.loadingPopover.present();
  }

  async dismissLoader() {
    if (this.loadingPopover && !this.loadingPopover.hidden) {
      await this.loadingPopover
        .dismiss()
        .then(async (value) => {
          await this.updateSegment(this.segment);
        })
        .catch((error) => console.log("Loader is not present", error));
    }
  }

  async setUserContent() {
    await this.presentLoader();
    if (!this.guestMode) {
      this.subscriptions.push(
        this.fireServ.getUserChapters().subscribe(async (chapters) => {
          const loadedChapters = await Promise.all(chapters);
          loadedChapters.sort((a, b) => a?.order - b?.order);
          loadedChapters.forEach((chapter, i) => {
            if (this.userChapters.findIndex((userChap) => userChap.id == chapter?.id) == -1) {
              this.userChapters.push(chapter);
            }
          });
        })
      );

      this.subscriptions.push(
        this.fireServ.getUserBooks().subscribe(async (books) => {
          if (!this.isDropping) {
            const loadedBooks = await Promise.all(books);
            loadedBooks.sort((a, b) => a?.order - b?.order);
            loadedBooks.forEach((book, i) => {
              const foundIndex = this.userBooks.findIndex((userBook) => userBook.id == book?.id);
              if (foundIndex == -1) {
                this.userBooks.push(book);
              } else {
                this.userBooks[foundIndex].chapters = book.chapters;
              }
            });
          }
        })
      );
      this.subscriptions.push(
        this.fireServ.getUserCollections().subscribe(async (collections) => {
          if (!this.isDropping) {
            const loadedCollections = await Promise.all(collections);
            loadedCollections.sort((a, b) => a?.order - b?.order);
            loadedCollections.forEach((collection, i) => {
              const foundIndex = this.userCollections.findIndex((col) => col.id == collection?.id);

              if (foundIndex == -1) {
                this.userCollections.push(collection);
              } else {
                this.userCollections[foundIndex].books = collection.books;
              }
            });
            this.noParts = this.userCollections.map((col) => col.books.length == 0);

            this.checkForBits();
          }

          await this.dismissLoader();
        })
      );
    }
  }

  checkForBits() {
    this.noBits = {};
    if (this.segment == "whole") {
      this.userCollections.forEach((col, i) => {
        col.books.forEach((book) => {
          if (book?.id && !this.noBits[book?.id]) {
            this.noBits = { ...this.noBits, [book.id]: book.chapters.length == 0 };
          }
        });
      });
    } else if (this.segment == "part") {
      this.userBooks.forEach((book, i) => {
        if (!this.noBits[book.id]) {
          this.noBits = { ...this.noBits, [book.id]: book.chapters.length == 0 };
        }
      });
    }
  }

  async updateSegment(segment: "bit" | "part" | "whole") {
    await this.router.navigate([], { queryParams: { s: segment } });
  }

  async viewChapter(bit: UserChapter) {
    if (bit.id) {
      let navigationExtras: NavigationExtras = {
        state: {
          showPreview: false,
        },
      };

      this.router.navigate([`/r/${bit.libraryId}/chapter/${bit.originalChapterId}`], navigationExtras);
    } else {
      console.error("content id not found", bit);
    }
  }

  async viewCollection(collection: UserCollection) {
    if (collection.id) {
      this.selectedCollection = collection;
      // this.subSegment = "selected";
    } else {
      console.error("content id not found", collection);
    }
  }

  openOptions(event) {}

  async presentChapterOptionsPopover(event: Event, bit: UserChapter, book?: UserBook) {
    var booksWithChapter: UserBook[];
    if (this.segment === "bit") {
      booksWithChapter = this.userBooks.filter((book) => book.chapters.some((item) => item.id === bit.id));
    } else {
      this.selectedBook = book;
      booksWithChapter = [this.selectedBook];
    }

    const popover = await this.popoverCtrl.create({
      component: OptionsPopoverComponent,
      componentProps: {
        chapters: [bit],
        contentType: "chapter",
        library: bit.libraryId,
        permanentlyRemove: this.segment === "bit" ? true : false,
        booksWithChapter,
      },
      event,
      translucent: true,
      backdropDismiss: true,
    });
    await popover.present();

    popover.onDidDismiss().then((data) => {
      if (data.data) {
        this.unsubscribeAll();
        this.setUserContent();
        if (data.data.removed) {
          if (this.segment === "bit") {
            this.userChapters = (this.userChapters as UserChapter[]).filter((chapter2) => chapter2.id != bit.id);
          } else {
            this.selectedBook.chapters = this.selectedBook.chapters.filter((chapter2) => chapter2.id != bit.id);
          }
        } else {
          console.log(`Bit didn't get removed`);
        }
      }
    });
  }

  async presentBookOptionsPopover(event: Event, book: UserBook | UserCollection | UserChapter, collection?: UserCollection) {
    var collectionsWithBook: UserCollection[];
    if (this.segment === "part") {
      collectionsWithBook = this.userCollections.filter((collection) => collection.books.some((book2) => book2.id === book.id));
    } else {
      this.selectedCollection = collection;
      collectionsWithBook = [this.selectedCollection];
    }

    console.log("presentBookOptionsPopovers: ", collectionsWithBook);
    const popover = await this.popoverCtrl.create({
      component: MyLibraryPopoverComponent,
      componentProps: {
        collectionsWithBook: collectionsWithBook,
        book: book,
        segment: "book",
        userCreator: this.userServ.getFireUser().uid,
      },
      event,
      translucent: true,
      backdropDismiss: true,
    });
    await popover.present();

    popover.onDidDismiss().then((data) => {
      if (data.data) {
        this.unsubscribeAll();
        this.setUserContent();
        if (data.data.removed) {
          if (this.segment === "part") {
            this.userBooks = this.userBooks.filter((book2) => book2.id != book.id);
          } else {
            this.selectedCollection.books = this.selectedCollection.books.filter((book2) => book2.id != book.id);
          }
        } else {
          console.log(`Didn't get removed`);
        }
      }
    });
  }

  async presentCollectionOptionsPopover(event: Event, collection: UserCollection) {
    const popover = await this.popoverCtrl.create({
      component: MyCollectionsPopoverComponent,
      componentProps: { collection, list: this.userCollections, libraryLink: `/${collection.owner}/set/${collection.id}` },
      event,
      translucent: true,
      backdropDismiss: true,
    });
    await popover.present();

    popover.onDidDismiss().then((data) => {
      if (data.data) {
        this.unsubscribeAll();
        this.setUserContent();
        if (data.data.removed) {
          this.userCollections = this.userCollections.filter((collection2) => collection2.id != collection.id);
        } else {
          console.log(`Didn't get removed`);
        }
      }
    });
  }

  validBookView(): boolean {
    var validView = false;
    this.userBooks.forEach((book) => {
      if (this.selectedBook && this.selectedBook.id === book.id) {
        validView = true;
      }
    });
    return validView;
  }

  itemsReordered(event) {
    event.detail.complete();
  }

  getPartsLists(whole: UserCollection) {
    let partIds = [];
    this.userCollections.forEach((collection, i) => {
      if (whole.id != collection.id) {
        partIds.push(collection.id);
      }
    });
    return partIds;
  }

  getBitsLists(id: string) {
    let bitIds = [];
    this.userBooks.forEach((book, j) => {
      if (book.id != id) {
        bitIds.push(book.id);
      }
    });
    return bitIds;
  }

  async dropWhole(event: CdkDragDrop<UserCollection[]>) {
    moveItemInArray(this.userCollections, event.previousIndex, event.currentIndex);
    await this.fireServ.changeWholeOrder(this.userCollections);
  }

  async dropPart(event: CdkDragDrop<UserBook[]>) {
    const foundTargetBookIndex = this.userCollections.findIndex((col) => col.id == event.container.id);
    const foundSourceBookIndex = this.userCollections.findIndex((col) => col.id == event.previousContainer.id);
    if (event.container.id != event.previousContainer.id) {
      transferArrayItem(
        event.previousContainer._dropListRef.data.data,
        event.container._dropListRef.data.data,
        event.previousIndex,
        event.currentIndex
      );
      let oneDrop = true;
      let twoDrop = true;
      this.fireServ
        .updateCollectionList(true, event.container.data, this.userCollections[foundTargetBookIndex], false)
        .catch((err) => {
          console.error("Could not add bit to part", event.container.id);
        })
        .finally(() => {
          oneDrop = false;
          setTimeout(() => {
            this.isDropping = oneDrop || twoDrop;
          }, 1000);
        });
      // remove bit from old part
      this.fireServ
        .updateCollectionList(false, [event.item.data], this.userCollections[foundSourceBookIndex], false)
        .catch((err) => {
          console.error("Could not remove bit from part", event.previousContainer.id);
        })
        .finally(() => {
          twoDrop = false;
          setTimeout(() => {
            this.isDropping = oneDrop || twoDrop;
          }, 1000);
        });
    } else {
      moveItemInArray(event.previousContainer._dropListRef.data.data, event.previousIndex, event.currentIndex);

      if (this.segment == "whole") {
        this.fireServ
          .updateCollectionList(true, event.previousContainer.data, this.userCollections[foundSourceBookIndex], false)
          .catch((err) => {
            console.error("Could not add bit to part", event.container.id);
          })
          .finally(() => {
            setTimeout(() => {
              this.isDropping = false;
            }, 1000);
          });
      } else if (this.segment == "part") {
        await this.fireServ.changePartOrder(event.previousContainer._dropListRef.data.data);
      }
    }
  }

  async dropBit(event: CdkDragDrop<UserChapter[]>) {
    const foundTargetBookIndex = this.userBooks.findIndex((book) => book.id == event.container.id);
    const foundSourceBookIndex = this.userBooks.findIndex((book) => book.id == event.previousContainer.id);
    if (event.container.id != event.previousContainer.id) {
      this.isDropping = true;

      if (foundTargetBookIndex == -1 || foundSourceBookIndex == -1) {
        console.error("Error: Could not find id for source or target", event.container.id, event.previousContainer.id, this.userBooks);
      }
      transferArrayItem(
        event.previousContainer._dropListRef.data.data,
        event.container._dropListRef.data.data,
        event.previousIndex,
        event.currentIndex
      );
      let oneDrop = true;
      let twoDrop = true;
      this.fireServ
        .updateBookList(true, event.container.data, this.userBooks[foundTargetBookIndex], false)
        .catch((err) => {
          console.error("Could not add bit to part", event.container.id);
        })
        .finally(() => {
          oneDrop = false;
          setTimeout(() => {
            this.isDropping = oneDrop || twoDrop;
          }, 500);
        });
      // remove bit from old part
      this.fireServ
        .updateBookList(false, [event.item.data], this.userBooks[foundSourceBookIndex], false)
        .catch((err) => {
          console.error("Could not remove bit from part", event.previousContainer.id);
        })
        .finally(() => {
          twoDrop = false;
          setTimeout(() => {
            this.isDropping = oneDrop || twoDrop;
          }, 500);
        });
    } else {
      moveItemInArray(event.previousContainer._dropListRef.data.data, event.previousIndex, event.currentIndex);

      if (this.segment == "part" || this.segment == "whole") {
        this.fireServ
          .updateBookList(true, event.previousContainer.data, this.userBooks[foundSourceBookIndex], false)
          .catch((err) => {
            console.error("Could not add bit to part", event.container.id);
          })
          .finally(() => {
            setTimeout(() => {
              this.isDropping = false;
            }, 500);
          });
      } else if (this.segment == "bit") {
        this.fireServ.changeBitOrder(event.previousContainer.data);
      }
    }
  }

  itemEntered(event, i: number) {
    this.noParts[i] = false;
  }

  itemExited(event: CdkDragExit<UserBook[], UserBook[]>, i: number) {
    if (event.item.dropContainer.id == event.container.id) {
      this.noParts[i] = this.userCollections[i].books.length == 1;
    } else {
      this.noParts[i] = this.userCollections[i].books.length == 0;
    }
  }

  bitEntered($event, id: string) {
    this.noBits[id] = false;
  }

  bitExited(event: CdkDragExit<UserChapter[], UserChapter[]>, id: string) {
    if (event.item.dropContainer.id == event.container.id) {
      this.noBits[id] = event.container.data.length == 1;
    } else {
      this.noBits[id] = event.container.data.length == 0;
    }
  }
}
