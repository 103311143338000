import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LoadingController, ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  loadingPopover: HTMLIonLoadingElement;

  constructor(
    public loadCtrl: LoadingController, 
    public toastCtrl: ToastController, 
    private router: Router
    ) {}

  async presentLoader(message?: string) {
    this.loadingPopover = await this.loadCtrl.create({
      message: message || "Loading... Please refresh after 30 seconds and try again.",
      backdropDismiss: true,
    });
    console.log("Loading popover present");
    return this.loadingPopover.present();
  }

  async dismissLoader() {
    if (this.loadingPopover && !this.loadingPopover.hidden) {
      await this.loadingPopover
        .dismiss()
        .then((data) => {
          console.log(`loading done`, data);
        })
        .catch((error) => console.log("Loader is not present", error));
    }
  }

  returnAfterLogin() {
    this.router.navigate(["login"], { state: { redirectTo: window.location.pathname}});
  }

  async presentToast(
    message: string,
    loginButton = false,
    closeButton = false,
    duration = 4000,
    navigateButton?: { path: string[]; title: string }
  ) {
    const buttons = [];
    if (closeButton) {
      buttons.push({
        role: "cancel",
        text: "Dismiss",
        side: "end",
      });
    }

    if (loginButton) {
      buttons.push({
        text: "Login",
        side: "end",
        handler: () => {
          this.router.navigate(["login"], { state: { redirectTo: window.location.pathname } });
        },
      });
    }

    if (navigateButton) {
      buttons.push({
        text: navigateButton.title,
        side: "end",
        handler: () => {
          this.router.navigate(navigateButton.path, { state: { redirectTo: window.location.pathname } });
        },
      });
    }

    const toast = await this.toastCtrl.create({
      message,
      duration,
      position: "bottom",
      buttons,
      cssClass: "toast-popup"
    });

    await toast.present();
  }

  buildAuthorDisplay(authors: string[], maxAuthors = 2) {
    let display = "";

    for (let i = 0; i < Math.min(authors.length, maxAuthors); i++) {
      const author = authors[i].split(" ");

      display += i > 0 ? ", " : "";

      display += author.length > 1 ? author[1] : author[0];
    }

    if (authors.length > maxAuthors) {
      display += `, et al.`;
    }

    return display;
  }
}
