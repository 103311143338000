import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { Platform, ToastController } from "@ionic/angular";
import firebase from "firebase";
import { Subscription } from "rxjs";
import { environment } from "../environments/environment";
import { UserData } from "./providers/user-data";
import { AnalyticsService } from "./services/analytics.service";
import { AuthService } from "./services/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  environment = environment;

  appPages = [
    {
      title: "myLibrary",
      url: "/home",
      icon: "assets/img/bookshelf.svg",
      customIcon: true,
    },
    {
      title: "myPurchases",
      url: "/home",
      queryParams: { subTab: "myPurchases" },
      indent: true,
    },
    {
      title: "Build-A-Book",
      url: "/home",
      queryParams: {
        s: "whole",
        subTab: "Build-A-Book",
      },
      indent: true,
    },
    {
      title: "myUploads",
      url: "/uploads",
      icon: "assets/img/add-document.svg",
      customIcon: true,
    },
    {
      title: "Home",
      url: "/know-bitz",
      icon: "assets/img/cookie-lightbulb.svg",
      customIcon: true,
    },
    {
      title: "myCommunity",
      url: "/community",
      icon: "people",
    },
    {
      title: "Store",
      url: "/store",
      icon: "cart",
    },
    // {
    //   title: "Build-A-Book",
    //   url: "/my-books",
    //   icon: "business",
    // },
  ];
  dark = false;

  subscriptions: Subscription[] = [];
  guestMode: boolean = false;

  path = "";

  constructor(
    private platform: Platform,
    private router: Router,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private authServ: AuthService,
    private analytics: AnalyticsService
  ) {
    this.initializeApp();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  ngOnInit() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        this.guestMode = false;
      } else {
        this.guestMode = true;
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.path = event.url;

        this.checkSignInLink();
        console.log(this.path);
      }
    });
  }

  checkSignInLink() {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem("emailForSignIn");
          if (window.location.pathname.includes("register") || window.location.pathname.includes("login")) {
            this.router.navigate([""]);
          }
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          console.error("Error signing in with email link", error);
          alert("Error signing in with email link");
        });
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      console.log("Platform Ready!!!");

      this.analytics.event_pageView({ page_title: "App", page_location: ".", page_path: "." });
      this.checkForUpdates();
    });
  }

  checkForUpdates() {
    this.swUpdate.versionUpdates.subscribe((updateEvent) => {
      if (updateEvent.type == "VERSION_READY") {
        this.toastCtrl
          .create({
            message: "Update available!",
            position: "bottom",
            buttons: [
              {
                text: "Update",
                role: "cancel",
              },
            ],
          })
          .then(async (toast) => {
            toast.onDidDismiss().then(() => {
              window.location.reload();
            });
            await toast.present();
          });
      }
    });
  }

  logout() {
    this.userData.logout().then((value) => {
      //console.log("userData.logout(): " + value)
    });
    this.authServ.doLogout().then((value) => {
      //console.log("authServ.doLogout(): " + value)
      this.analytics.event_logout();
      this.toLogin();
    });
  }

  toLogin() {
    this.router.navigateByUrl("/login");
  }
}
