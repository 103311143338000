import { Component } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ModalController, NavParams } from "@ionic/angular";
import { Chapter } from "../../interfaces/firebase-interfaces";
import { ChapterService } from "../../services/chapters.service";

@Component({
  template: `
    <ion-header>
      <ion-toolbar mode="md">
        <ion-title>Related Audio & Video</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="close()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="outer-content">
      <div *ngIf="chapVideos.length > 0">
        <ion-list [inset]="true" *ngFor="let i of chapVideos">
          <ion-item>{{ i.title }}</ion-item>
          <iframe width="100%" height="315" [src]="i.link" frameborder="0" allowfullscreen></iframe>
        </ion-list>
      </div>
      <ion-item *ngIf="chapVideos.length == 0">
        No video content is currently available for this bit. Please suggest one on our support page. Click the "?" shortcut in the top
        right here or in the main menu.
      </ion-item>
    </ion-content>
  `,
})
export class VideoPopoverPage {
  videoToAdd: any = [];
  chapVideos: any[] = [];
  chapters: Chapter[];
  library: string;
  constructor(
    public modalCtrl: ModalController,
    private sanitizer: DomSanitizer,
    public navParams: NavParams,
    private chapService: ChapterService
  ) {
    this.library = this.navParams.get("library");
    this.chapters = this.navParams.get("chapters");

    this.chapService.getChapterInfo(this.chapters, this.library, "videos").then((chapVideos) => {
      chapVideos.forEach((videoLinks) => {
        videoLinks.forEach((videoData) => {
          this.videoToAdd = {
            link: this.sanitizer.bypassSecurityTrustResourceUrl(videoData.data.link),
            title: videoData.data.title,
            description: videoData.data.description,
            id: videoData.id,
          };
          this.chapVideos.splice(this.chapVideos.length, 1, this.videoToAdd);
        });
      });
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
