import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators, ValidatorFn } from "@angular/forms";
import { IonSelect, ModalController } from "@ionic/angular";
import Epub, { Book, Rendition } from "epubjs";
import html2canvas from "html2canvas";
import { Profile } from "../../pages/profile/profile.page";
import { ChapterService } from "../../services/chapters.service";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";
import { RemoteConfigService } from "../../services/remote-config.service";
import { StorageService } from "../../services/storage.service";
import { Bit } from "../bit-cover/bit-cover.component";

var currentEpub: Book;
var rendition: Rendition;

@Component({
  selector: "app-monetize-popover",
  templateUrl: "./monetize-popover.component.html",
  styleUrls: ["./monetize-popover.component.scss"],
})
export class MonetizePopoverComponent implements OnInit {
  @Input() bits: Bit[];
  @ViewChild("subTopicSelect") subTopicSelect: IonSelect;
  step: number = 1;
  description: string;
  contentForm: FormGroup;
  file: File | string = null;
  coverFile: File;
  cover_url: string;
  coverChanged = false;
  mainTopic: string;
  subTopics: string[] = [];

  validation_messages = {
    title: [
      { type: "required", message: "Title is required." },
      { type: "minlength", message: "Please enter a valid title." },
    ],
    authors: [
      { type: "required", message: "Author is required." },
      { type: "minlength", message: "Please enter a valid author." },
    ],
    price: [
      { type: "required", message: "Price is required." },
      { type: "min", message: "Price must be at least $1." },
      { type: "max", message: "Price is over our set max." },
      { type: "pattern", message: "Must be a valid price." },
    ],
  };

  BIT_TYPES_MAP_KEYS = Object.keys(this.remoteConfigServ.BIT_TYPES_MAP);
  BIT_TYPES_MAP = this.remoteConfigServ.BIT_TYPES_MAP;

  BIT_TOPICS_MAP_KEYS = Object.keys(this.remoteConfigServ.BIT_TOPICS);
  BIT_TOPICS_MAP = this.remoteConfigServ.BIT_TOPICS;

  cssPopoverData = {
    cssClass: "editContentSelectItem",
  };

  statuses = [
    { key: "hidden", label: "Hidden" },
    { key: "unlisted", label: "Unlisted" },
    { key: "public", label: "Public" },
  ];

  profile: Profile;

  constructor(
    private modalCtrl: ModalController,
    private fireServ: FirebaseService,
    private formBuilder: FormBuilder,
    private helper: HelperService,
    private storageServ: StorageService,
    private chapterServ: ChapterService,
    private cdRef: ChangeDetectorRef,
    private remoteConfigServ: RemoteConfigService
  ) {}

  ngOnInit() {
    this.initForm();

    if (!this.profile) {
      this.fireServ
        .getUserProfileData()
        .then((profile) => {
          if (profile) {
            this.profile = profile;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  close(chapter?: any) {
    this.modalCtrl.dismiss(chapter);
  }

  back() {
    this.step--;
  }

  async continue() {
    if (this.step == 3) {
      await this.helper.presentLoader("Uploading Content...");

      this.fireServ
        .createCollectionFromBits(
          this.bits.map((val, index) => {
            return val.path;
          }),
          this.description,
          this.cover_url,
          Number(this.unFormat(this.contentForm.value.price)),
          this.contentForm.value.title,
          this.contentForm.value.isSubscription,
          this.profile.stripeConnectedAccountId
        )
        .then(async (res) => {
          await this.helper.presentToast("Content has been successfully uploaded!");
          this.close(res);
        })
        .finally(async () => await this.helper.dismissLoader());
    } else if (this.step == 2) {
      if (this.contentForm.valid) {
        let cover_url: string;

        if (!this.coverFile) {
          await this.helper.presentLoader("Saving cover...");

          const canvas = await html2canvas(document.getElementById("cover"));
          const dataURL = canvas.toDataURL("image/png");
          var byteString = atob(dataURL.split(",")[1]);
          var mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }

          this.coverFile = new File([ab], this.contentForm.value.title + "_cover.png", { type: mimeString });
          this.coverChanged = true;
        }

        if (this.coverChanged) {
          cover_url = await this.storageServ.saveCoverPhoto(this.coverFile);
        }
        this.cover_url = cover_url;
        await this.helper.dismissLoader();
        this.step++;
      } else {
        await this.helper.presentToast("Please complete the form before continuing.");
      }
    } else {
      this.step++;
    }
  }

  initForm() {
    this.contentForm = this.formBuilder.group({
      description: new FormControl(""),
      title: new FormControl("", Validators.compose([Validators.required, Validators.minLength(3)])),
      topics: new FormControl([], [Validators.required]),
      type: new FormControl("", [Validators.required]),
      price: new FormControl("", [
        Validators.required,
        Validators.pattern("^\\d+(,\\d+)*(\\.\\d{1,2})?$"),
        (control: AbstractControl): ValidationErrors | null => {
            try {
              let val = Number(this.unFormat(control.value));
              if (val < 1) {
                return {"min": true};
              } else if (val > 999999.99) {
                return {"max": true};
              } else {
                return null;
              }
            } catch {
              return {"pattern": true};
            }
        }
      ]),
      isSubscription: new FormControl(false, [Validators.required]),
      cover_url: new FormControl(""),
    });
  }

  async setFile() {
    const file = await this.chapterServ.getChapterURL(this.bits[0].id, this.bits[0].libraryId);
    this.file = file;
    this.displayEpub(file + ".epub");
  }

  async displayEpub(epub) {
    if (rendition) {
      rendition.destroy();
    }
    currentEpub = Epub(epub);
    rendition = currentEpub.renderTo("book", { width: `95%`, height: "95%" });

    rendition.display().then(async () => {
      console.log("Displayed");
    });
  }

  async onEpubChanged(event) {
    console.log(event.target.files);
    this.file = event.target.files[0] as File;
    if (!this.contentForm.value.title) {
      this.contentForm.controls.title.setValue(this.file.name.replace(".epub", ""));
    }
    console.log("Event: ", event.target, this.file);
    this.displayEpub(this.file);
  }

  async onCoverChanged(event) {
    console.log("Event: ", event);
    this.coverFile = event.target.files[0] as File;
    var reader = new FileReader();
    reader.onload = (e) => {
      this.contentForm.controls.cover_url.setValue(e.target.result);
    };
    reader.readAsDataURL(this.coverFile);
    this.coverChanged = true;
  }

  urlValidator(control: FormControl): { [s: string]: boolean } {
    if (control.value != "" && !control.value.match(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/)) {
      return { invalidUrl: true };
    }
  }

  mainTopicChanged(event) {
    this.mainTopic = event.detail.value;
    let topics = [this.mainTopic];
    if (this.subTopics.length > 0) {
      topics = [this.mainTopic, ...this.subTopics];
    }
    this.contentForm.controls.topics.setValue(topics);
    this.cdRef.detectChanges();
  }

  async subTopicsChanged(event) {
    if (event.detail.value.length > 3) {
      await this.helper.presentToast("You can only select 3 sub topics");
      this.subTopicSelect.value = this.subTopics;
      if (this.subTopics.length == 0) {
        await this.subTopicSelect.open();
      }
      return;
    }
    this.subTopics = event.detail.value;
    let topics = [this.mainTopic, ...this.subTopics];
    this.contentForm.controls.topics.setValue(topics);
    this.cdRef.detectChanges();
  }

  selected(topic: string) {
    return this.subTopics.findIndex((sub) => sub === topic) != -1;
  }

  getChunked() {
    const results = [];
    for (let i = 0; i < this.bits.length; i += 2) {
      results.push(this.bits.slice(i, i + 2));
    }
    return results;
  }

  format(event) {
    let valString: string = event.target.value;
    if (!valString) {
        return '';
    }
    let hadCents = false;
    if (valString.includes(".")) {
      hadCents = true;
    }
    let val = valString.toString();
    const parts: string[] = this.unFormat(val).split(".");
    parts[0] = parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
    if (parts.length == 1 && hadCents) {
      parts[1] = "";
    }
    this.contentForm.controls.price.setValue(parts.join("."));
  };

  unFormat(val) {
    if (!val) {
        return '';
    }
    return val.replace(/,/g, '');
  };
}
