import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { Plugins, SharePluginWeb } from "@capacitor/core";
import { ActionSheetController, AlertController, LoadingController, ModalController, Platform, PopoverController } from "@ionic/angular";
import { AngularFireAuth } from "angularfire2/auth";
import firebase from "firebase";
import { Chapter, UserBook } from "../../interfaces/firebase-interfaces";
import { AnalyticsService } from "../../services/analytics.service";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";
import { UserService } from "../../services/user.service";
import { AddToPopoverComponent } from "../add-to-popover/add-to-popover.component";
import { LinkPopover } from "../chapter-links-popover/chapter-links-popover";
import { ChapterResourcesComponent } from "../chapter-resources-popover/chapter-resources-popover.component";
import { EditContentDataComponent } from "../edit-content-data/edit-content-data.component";
import { VideoPopoverPage } from "../video-popover/video-popover";

@Component({
  selector: "options-popover",
  templateUrl: "./options-popover.component.html",
  styleUrls: ["./options-popover.component.scss"],
})
export class OptionsPopoverComponent implements OnInit, OnChanges {
  @Input() chapters: Chapter[];
  @Input() booksWithChapter: UserBook[];
  @Input() library: string;
  @Input() customBook: UserBook;
  @Input() userCreator: string;
  @Input() permanentlyRemove: boolean = false;
  @Input() showSelect = false;

  bookTitle: string;
  copyLink: string;
  platform: Platform;
  customList: boolean;

  guestMode: boolean = false;

  user: firebase.User;

  constructor(
    public popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private fireServ: FirebaseService,
    private alertController: AlertController,
    private router: Router,
    private actionSheetCtrl: ActionSheetController,
    private analytics: AnalyticsService,
    private helperServ: HelperService,
    private fireAuth: AngularFireAuth,
    private userServ: UserService,
    private loadCtrl: LoadingController
  ) {
    this.customList = this.router.routerState.snapshot.url.includes("Build-A-Book");
    this.user = this.userServ.getFireUser();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit() {
    if (!this.fireAuth.auth.currentUser) {
      console.log("Not signed in");
      this.guestMode = true;
      return;
    }

    console.log("Options for chapters: ", this.chapters);
    if (this.chapters.length === 1) {
      this.copyLink = window.document.location.href.replace(
        this.router.routerState.snapshot.url,
        "/r/" + this.library + "/chapter/" + this.chapters[0].id
      );
      this.copyLink = this.copyLink.split(" ").join("%20");
    }

    if (this.router.routerState.snapshot.url.includes("search-results")) {
      this.showSelect = false;
    }
  }

  async selectChapter() {
    if (this.guestMode) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    this.popoverCtrl.dismiss({ select: true });
  }

  async share() {
    if (this.chapters[0].id === "-1") {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    let sharingTitle: string = this.chapters[0].title;

    const actionSheet = await this.actionSheetCtrl.create({
      header: "Share link to " + sharingTitle + "?",
      mode: "ios",
      //subHeader: this.copyLink,
      buttons: [
        {
          text: "Copy Link",
          handler: () => {
            //// Different Browser capabilties
            if (window.navigator.userAgent.includes("Chrome")) {
              Plugins.Clipboard.write({ url: this.copyLink })
                .then((value) => {
                  this.copiedMessage("Successfully copied the link to your clipboard!");
                  this.analytics.event_share({ share_method: "link", link: this.copyLink });
                })
                .catch((error) => {
                  console.log(error);
                  this.copiedMessage("Something went wrong. Please try again.");
                });
            } else {
              // Create new element
              var el = document.createElement("textarea");
              // Set value (string to be copied)
              el.value = this.copyLink;
              // Set non-editable to avoid focus and move outside of view
              el.setAttribute("readonly", "");
              //el.style = {position: 'absolute', left: '-9999px'};
              document.body.appendChild(el);
              // Select text inside element

              el.select();
              // Copy text to clipboard
              if (document.execCommand("copy").valueOf()) {
                this.copiedMessage("Successfully copied the link to your clipboard!");
                this.analytics.event_share({ share_method: "link", link: this.copyLink });
              } else {
                this.copiedMessage("Something went wrong. Please try again.");
              }
              // Remove temporary element
              document.body.removeChild(el);
            }
          },
        },
        {
          text: "Share via ... (for mobile & some browsers)",
          handler: () => {
            this.presentShareOptions(sharingTitle);
          },
        },
        {
          text: "Cancel",
          role: "cancel",
        },
      ],
    });

    await actionSheet.present();
  }

  async presentShareOptions(title: string) {
    await SharePluginWeb.prototype
      .share({
        title: title,
        text: "Share text",
        url: this.copyLink,
        dialogTitle: "Share with friends",
      })
      .then((res) => {
        this.analytics.event_share({ share_method: "Native options", link: this.copyLink });
      })
      .catch((error) => {
        console.error(error, "Dang it");
      });
  }

  async copiedMessage(message: string) {
    this.helperServ.presentToast(message, false, true, 5000);
  }

  async removeContent() {
    if (this.guestMode) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    let alert = await this.alertController.create({
      message: "Are you sure you want to remove this?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Yes, I'm sure",
          role: "submit",
          handler: async () => {
            let loadingPopover = await this.loadCtrl.create({ message: "Loading... Please refresh after 30 seconds" });
            await loadingPopover.present();
            this.fireServ
              .updateChaptersList(false, this.chapters, this.permanentlyRemove, this.booksWithChapter)
              .then((value) => {
                this.popoverCtrl.dismiss({ removed: true });
              })
              .catch((err) => {
                console.error(err);
                this.popoverCtrl.dismiss({ removed: false });
              })
              .finally(async () => await loadingPopover.dismiss());
          },
        },
      ],
    });
    await alert.present();
  }

  async addToOptions() {
    if (this.guestMode) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    const modal = await this.modalCtrl.create({
      component: AddToPopoverComponent,
      componentProps: { chapters: this.chapters },
      backdropDismiss: true,
      cssClass: "add-to-popover",
    });
    await modal.present();
    modal.onDidDismiss().then(() => {
      this.popoverCtrl.dismiss({ added: true });
    });
  }

  async videoPopover() {
    const modal = await this.modalCtrl.create({
      component: VideoPopoverPage,
      componentProps: { chapters: this.chapters, library: this.library },
      backdropDismiss: true,
      cssClass: "video-popover",
    });
    await modal.present();
  }

  async displayPrintOptions() {
    if (this.guestMode) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    let printContent = this.chapters.map((chap) => chap.id);
    //if (this.contentType === 'user-book') {
    //  printContent = JSON.stringify(this.customBook)
    //} else {
    //printContent = this.contentId
    //}

    let alert = await this.alertController.create({
      message: "Print on-demand is still under development and is not yet available. Coming Soon!", // Are you sure you want to send a print request for this chapter?',
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Okay",
          role: "submit",
          handler: async () => {
            let success = await this.fireServ.sendPrintRequest(printContent);
            if (success) {
              this.helperServ.presentToast(
                "We successfully received your print request! We should get back to you in 24 hours with more info.",
                false,
                true,
                5000
              );

              /* const toast = await this.toastCtrl.create({
              message: 'We successfully received your print request! We should get back to you in 24 hours with more info.',
              showCloseButton: true,
              position: 'bottom',
              closeButtonText: `Dismiss`,
              duration: 5000
            });
            await toast.present() */
            } else {
              this.helperServ.presentToast("Something went wrong, please try again.", false, true, 5000);

              /*  const toast = await this.toastCtrl.create({
              message: ,
              showCloseButton: true,
              position: 'bottom',
              closeButtonText: `Dismiss`,
              duration: 3000
            });
            await toast.present() */
            }
          },
        },
      ],
    });
    await alert.present();
  }

  async chapterResourcesPopover() {
    console.log(this.chapters, this.library);
    const modal = await this.modalCtrl.create({
      component: ChapterResourcesComponent,
      componentProps: { chapters: this.chapters, library: this.library },
      backdropDismiss: true,
      cssClass: "new-custom-chapter",
    });
    await modal.present();
  }

  async presentLinkPopover(event: Event) {
    const modal = await this.modalCtrl.create({
      component: LinkPopover,
      backdropDismiss: true,
      componentProps: {
        chapters: this.chapters,
        library: this.library,
      },
      cssClass: "chapter-popover",
    });
    await modal.present();
  }

  async edit() {
    const modal = await this.modalCtrl.create({
      component: EditContentDataComponent,
      backdropDismiss: true,
      componentProps: {
        contentData: this.chapters[0],
      },
      cssClass: "chapter-popover",
    });
    await modal.present();

    modal.onWillDismiss().then(async (res) => {
      if (res.data) {
        await this.popoverCtrl.dismiss({ content: res.data });
      }
    });
  }
}
