import { Injectable } from "@angular/core";
import { remoteConfig } from "../../environments/environment";

export enum BIT_TYPES {
  "article" = "article",
  "book" = "book",
  "chapter" = "chapter",
  "essay" = "essay",
  "handout" = "handout",
  "instructions" = "instructions",
  "jokes" = "jokes",
  "letter" = "letter",
  "lyrics" = "lyrics",
  "manual" = "manual",
  "memoir" = "memoir",
  "poem" = "poem",
  "recipe" = "recipe",
  "story" = "story",
  "worksheet" = "worksheet",
  "other" = "other",
}

export enum BIT_TYPES_MAP {
  "article" = "Article",
  "book" = "Book",
  "chapter" = "Chapter",
  "essay" = "Essay",
  "handout" = "Handout",
  "instructions" = "Instructions",
  "jokes" = "Jokes",
  "letter" = "Letter",
  "lyrics" = "Lyrics",
  "manual" = "Manual",
  "memoir" = "Memoir",
  "poem" = "Poem",
  "recipe" = "Recipe",
  "story" = "Story",
  "worksheet" = "Worksheet",
  "other" = "Other",
}

export enum BIT_TOPICS {
  "arts_architecture" = "Arts & Architecture",
  "business_economics" = "Business & Economics",
  "collaboration" = "Collaboration",
  "computers_technology" = "Computers & Technology",
  "education_teaching" = "Education & Teaching",
  "fiction" = "Fiction",
  "history_archeology" = "History & Archeology",
  "music_performing_arts" = "Music & Performing Arts",
  "non_fiction" = "Non-Fiction",
  "organizational_development" = "Organization Development & Change",
  "parenting_family_relationships" = "Parenting, Family & Relationships",
  "political_social_science" = "Political & Social Science",
  "psychology" = "Psychology",
  "other" = "Other",

  "culture_sociology" = "Culture & Sociology",
  "current" = "Current Events",
  "design" = "Design",
  "healthcare" = "Healthcare",
  "poetry" = "Poetry",
  "political_science" = "Political Science",
  "philosophy" = "Philosophy",
  "social_science" = "Social and Behavioral Science",
}
@Injectable({
  providedIn: "root",
})
export class RemoteConfigService {
  BIT_TYPES: BIT_TYPES = JSON.parse(remoteConfig.getValue("bit_types").asString()) as BIT_TYPES;
  BIT_TYPES_MAP: BIT_TYPES_MAP = JSON.parse(remoteConfig.getValue("bit_types_map").asString()) as BIT_TYPES_MAP;
  BIT_TOPICS: BIT_TOPICS = JSON.parse(remoteConfig.getValue("bit_topics").asString()) as BIT_TOPICS;

  constructor() {
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        this.BIT_TYPES = JSON.parse(remoteConfig.getValue("bit_types").asString()) as BIT_TYPES;
        this.BIT_TYPES_MAP = JSON.parse(remoteConfig.getValue("bit_types_map").asString()) as BIT_TYPES_MAP;
        this.BIT_TOPICS = JSON.parse(remoteConfig.getValue("bit_topics").asString()) as BIT_TOPICS;
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
  }
}
