import { Component, Input, OnInit } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { PopoverController } from "@ionic/angular";
import { Library, Volume } from "../../interfaces/firebase-interfaces";
import { MyCollectionsPopoverComponent } from "../my-collections-popover/my-collections-popover.component";

@Component({
  selector: "volume",
  templateUrl: "./volume.component.html",
  styleUrls: ["./volume.component.scss"],
})
export class VolumeComponent implements OnInit {
  @Input() volume: Volume;
  @Input() collection: Library;

  constructor(private router: Router, private popoverCtrl: PopoverController) {}

  ngOnInit() {}

  async presentVolumeOptionsPopover(event: Event, volume: Volume) {
    const popover = await this.popoverCtrl.create({
      component: MyCollectionsPopoverComponent,
      componentProps: { libraryLink: volume.link, contentTitle: volume.title, collection: this.collection },
      event,
      translucent: true,
      backdropDismiss: true,
    });
    await popover.present();
  }

  async navigateToOtherPage(page: any) {
    console.log(page);
    if (page == null) {
      console.log("chapterId not found");
    } else {
      let showPreview = false;
      if (this.router.routerState.snapshot.url.includes("store")) {
        showPreview = true;
      }
      let navigationExtras: NavigationExtras = {
        state: {
          chapter: page,
          showPreview: showPreview,
        },
      };
      this.router.navigate([page["link"]], navigationExtras);
    }
  }
}
