import { Component, Input } from "@angular/core";
import { Chapter } from "../../interfaces/firebase-interfaces";
import { OrderManager } from "../../pages/uploads/uploads.page";

@Component({
  selector: 'app-selected-bit',
  templateUrl: './selected-bit.component.html',
  styleUrls: ['./selected-bit.component.scss'],
})
export class SelectedBitComponent {
  @Input() bit: Chapter;
  @Input() index: number;
  @Input() mngr: OrderManager;
  selected: boolean = false;

  constructor() {}

  select() {
    this.selected = !this.selected;
    if (this.selected) {
      this.mngr.Order.push(this.index);
      this.mngr.selectedBitsOrder[this.index] = this.mngr.Order.length;
    } else {
      for (let i=this.mngr.Order.findIndex((value) => value == this.index); i<this.mngr.Order.length; i++) {
        this.mngr.Order[i] = this.mngr.Order[i+1]
        this.mngr.selectedBitsOrder[this.mngr.Order[i]]--;
      }
      this.mngr.Order.pop();
      this.mngr.selectedBitsOrder[this.index] = -1;
    }
  }
}
