import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AngularFireAuth } from "angularfire2/auth";
import firebase from "firebase";
import { AuthService } from "./auth.service";
import { FirebaseService } from "./firebase.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardServiceService implements CanActivate {
  public user: { email: string; uid: string } = { email: "", uid: "" };

  constructor(
    private router: Router,
    public fireAuth: AngularFireAuth,
    private fireServ: FirebaseService,
    private authServ: AuthService
  ) {}

  canActivate(/*route: ActivatedRouteSnapshot*/): Promise<boolean> {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user && user.emailVerified) {
          this.fireServ.initializeUser();
          // this.fireServ.getAllContent()
          this.user = { email: user.email, uid: user.uid };
          resolve(true);
        } else {
          this.goLogin();
          reject(false);
        }
      });
    });
  }

  goLogin() {
    this.router.navigate(["/login"]);
  }
}
