import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { KnowBitsPage } from "./know-bits.page";

const routes: Routes = [
  {
    path: "",
    component: KnowBitsPage,
    // children: [
    //   {
    //     path: ":libraryId/toc",
    //     // component: TocPage,
    //     children: [
    //       {
    //         path: "",
    //         component: TocPage,
    //       },
    //       {
    //         path: "toc-detail/Volume/:volumeNumber",
    //         loadChildren: "../toc-detail/toc-detail.module#TocDetailModule",
    //       },
    //     ],
    //   },
    // ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class KnowBitsPageRoutingModule {}
