import { Component, Input } from '@angular/core';
import { Profile } from '../../pages/profile/profile.page';
import { FirebaseService } from '../../services/firebase.service';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-confirm-deletion',
  templateUrl: './confirm-deletion.component.html',
  styleUrls: ['./confirm-deletion.component.scss'],
})
export class ConfirmDeletionComponent {

  confirmed: boolean = false;

  @Input("profile") profile: Profile
  constructor(
    private fireServ: FirebaseService, 
    private router: Router,
    private popoverCtl: PopoverController,
    private helperServ: HelperService,
  ) {}

  onInput(event) {
    this.confirmed = event.target.value == this.profile.email;
  }

  async deleteAccount() {
    this.popoverCtl.dismiss();
    try {
      await this.fireServ.deleteUserAccount();
      this.router.navigateByUrl("/login");
      this.helperServ.presentToast("Your account has been deleted, please come again", false, false, 5000);
    } catch (e) {
      this.helperServ.presentToast("Please login to verify your account deletion", false, false, 5000);
      this.router.navigate(["/login"], {"queryParams": {"reAuth": true}});
    }
  }
}
