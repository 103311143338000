import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { UserChapter } from "../../interfaces/firebase-interfaces";

@Component({
  selector: "app-bit",
  templateUrl: "./bit.component.html",
  styleUrls: ["./bit.component.scss"],
})
export class BitComponent implements OnInit {
  @Input() bit: UserChapter;

  @Output("presentOptions") presentOptions: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router) {}

  ngOnInit() {}

  async viewChapter(bit: UserChapter) {
    if (bit.id) {
      let navigationExtras: NavigationExtras = {
        state: {
          showPreview: false,
        },
      };

      this.router.navigate([`/r/${bit.libraryId}/chapter/${bit.originalChapterId}`], navigationExtras);
    } else {
      console.error("content id not found", bit);
    }
  }
}
