import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { SwiperModule } from "swiper/angular";
import { ComponentsModule } from "../../components/components.module";
import { KnowBitsPageRoutingModule } from "./know-bits-routing.module";
import { KnowBitsPage } from "./know-bits.page";

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, KnowBitsPageRoutingModule, ComponentsModule, SwiperModule],
  declarations: [KnowBitsPage],
})
export class KnowBitsPageModule {}
