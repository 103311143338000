import { Component, Input, OnInit } from '@angular/core';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'toc-filter',
  templateUrl: './toc-filter.component.html',
  styleUrls: ['./toc-filter.component.scss'],
})
export class TocFilterComponent implements OnInit {

  @Input() includedTracks: string[];
  @Input() modalities: string[];

  tracks: {name: string, isChecked: boolean}[] = [];

  constructor(
    //public confData: ConferenceData,
    public modalCtrl: ModalController,
    public navParams: NavParams
  ) { }

  ngOnInit() {
    console.log("Included trakcs", this.includedTracks)
    console.log("Modalities:", this.modalities)
  }

  // TODO use the ionViewDidEnter event
  ngAfterViewInit() {
    // passed in array of track names that should be excluded (unchecked)
    const includedTrackNames = this.navParams.get('includedTracks');
    const modalities = this.navParams.get('modalities')

    modalities.forEach(modality => this.tracks.push({name: modality, isChecked: includedTrackNames.includes(modality)})) 

  }

  selectAllFilters() {
    // reset all of the toggles to be checked
    this.tracks.forEach(track => {
      track.isChecked = true;
    });
  }

  deselectAllFilters() {
    this.tracks.forEach(track => {
      track.isChecked = false;
    });
  }


  applyFilters() {
    // Pass back a new array of track names to exclude
    const includedTrackNames = this.tracks.filter(c => c.isChecked).map(c => c.name);
    this.dismiss(includedTrackNames);
  }

  dismiss(data?: any) {
    // using the injected ModalController this page
    // can "dismiss" itself and pass back data
    this.modalCtrl.dismiss(data);
  }
}
