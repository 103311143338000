import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { ComponentsModule } from "../../components/components.module";
import { MyBooksPage } from "./my-books";
// import { MyBooksPageRoutingModule } from "./my-books-routing.module";

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ComponentsModule, DragDropModule],
  declarations: [MyBooksPage],
  exports: [MyBooksPage],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // entryComponents: [MyLibraryPopoverComponent, MyLibraryAddPopoverComponent, MyCollectionsPopoverComponent]
})
export class MyBooksModule {}
