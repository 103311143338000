import { Injectable } from "@angular/core";
// import { Events } from '@ionic/angular';
import { Storage } from "@ionic/storage-angular";

@Injectable({
  providedIn: "root",
})
export class UserData {
  _favorites: string[] = [];
  private _storage: Storage | null = null;

  HAS_LOGGED_IN = "hasLoggedIn";
  HAS_SEEN_TUTORIAL = "hasSeenTutorial";

  constructor(public storage: Storage) {
    this.storage.create().then((storage) => {
      this._storage = storage;
    });
  }

  hasFavorite(sessionName: string): boolean {
    return this._favorites.indexOf(sessionName) > -1;
  }

  addFavorite(sessionName: string): void {
    this._favorites.push(sessionName);
  }

  removeFavorite(sessionName: string): void {
    const index = this._favorites.indexOf(sessionName);
    if (index > -1) {
      this._favorites.splice(index, 1);
    }
  }

  login(username: string): Promise<any> {
    return this._storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
      // return this.events.publish('user:login');
    });
  }

  signup(username: string): Promise<any> {
    return this._storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
      // return this.events.publish('user:signup');
    });
  }

  logout(): Promise<any> {
    return this._storage
      .remove(this.HAS_LOGGED_IN)
      .then(() => {
        return this._storage.remove("username");
      })
      .catch((err) => {
        console.error("Error here: ", err);
        // this.events.publish('user:logout');
      });
  }

  setUsername(username: string): Promise<any> {
    return this._storage.set("username", username);
  }

  getUsername(): Promise<string> {
    return this._storage.get("username").then((value) => {
      return value;
    });
  }

  isLoggedIn(): Promise<boolean> {
    return this._storage.get(this.HAS_LOGGED_IN).then((value) => {
      return value === true;
    });
  }

  checkHasSeenTutorial(): Promise<string> {
    return this._storage.get(this.HAS_SEEN_TUTORIAL).then((value) => {
      return value;
    });
  }
}
