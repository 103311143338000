import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HelperService } from "../../services/helper.service";

@Component({
  selector: "app-searchbar",
  templateUrl: "./searchbar.component.html",
  styleUrls: ["./searchbar.component.scss"],
})
export class SearchbarComponent implements OnInit {
  @Input() searchTerm: string = "";

  constructor(private router: Router, private helperServ: HelperService) {}

  ngOnInit() {}

  search() {
    if (!this.searchTerm || this.searchTerm.length < 3) {
      this.helperServ.presentToast("Please enter 3 or more characters");
      return;
    }

    this.router.navigate(["search-results"], { queryParams: { q: this.searchTerm } });
  }
}
