import { Injectable } from "@angular/core";
import { firestore, storage } from "firebase";
import { Observable, from } from "rxjs";
import { Chapter, Library, UserBook } from "../interfaces/firebase-interfaces";
import { getBackendData, getVolumeChaptersRef } from "./cloud-functions";
import { UserService } from "./user.service";
import { FirebaseService } from "./firebase.service";

@Injectable({
  providedIn: "root",
})
export class ChapterService {
  constructor(private userServ: UserService, private fireServ: FirebaseService) {}

  getResourceURL(resource: string, library: string) {
    let page = storage().ref();
    return page.child(library).child("_Resource_pdfs").child(resource).getDownloadURL();
  }

  async getChaptersInVolume(
    libraryId: string,
    volumeTitle: string
  ): Promise<{ title: string; url: string; id: string; libraryId: string; number: number }[]> {
    let chapterDocs: firebase.firestore.DocumentData[];
    const user = this.userServ.getFireUser();
    if (user && user.uid) {
      console.log("User IS logged in");
      let chaptersRef = (await getBackendData(
        `Collections/${libraryId}/Chapters`,
        [["volumeTitle", "==", volumeTitle]],
        ["data"]
      )) as firestore.DocumentData[];
      chapterDocs = chaptersRef.map((doc) => doc.data);
    } else {
      const functionsResult = await getVolumeChaptersRef({ libraryId, volumeTitle });
      console.log("User IS NOT logged in, Volume Chapters ref: ", functionsResult);
      chapterDocs = functionsResult.data;
    }

    console.log("chapterDocs: ", chapterDocs);
    if (chapterDocs.length > 0) {
      let chapterUrls = new Array<{ title: string; url: string; id: string; libraryId: string; number: number }>();

      for (let chapterDoc of chapterDocs) {
        //console.log(chapterDoc)
        //const chapter = chapterDoc.data() as FirebaseChapter
        const url = await this.getChapterURL(chapterDoc.id, libraryId);
        chapterUrls.push({ title: chapterDoc.title, url: url, id: chapterDoc.id, libraryId: libraryId, number: chapterDoc.chapterNumber });
      }
      console.log(chapterUrls);
      chapterUrls.sort((a, b) => a.number - b.number);
      return chapterUrls;
    } else {
      console.error("Can't find volume Doc, invalid volume: ", volumeTitle);
    }
  }

  async getMultipleChapters(customBook: UserBook): Promise<{ title: string; url: string; id: string; libraryId: string }[]> {
    const chapters = new Array<{ title: string; url: string; id: string; libraryId: string }>();
    const coverUrl = await storage().ref().child("Your_Unique_Cover.epub").getDownloadURL();
    chapters.push({ title: "Unique Cover", url: coverUrl, id: "Your_Unique_Cover", libraryId: "N/A" });
    for (let chapter of customBook.chapters) {
      console.log(chapter);

      const url = await this.getChapterURL(chapter.originalChapterId, chapter.libraryId);
      chapters.push({ title: chapter.title, url: url, id: chapter.originalChapterId, libraryId: chapter.libraryId });
    }
    return chapters;
  }

  async getChapterURL(chapterId: string, library: string): Promise<string> {
    // Implement as cloud function
    var page = storage().ref();
    console.log(chapterId, library);
    return await page
      .child(library)
      .child(chapterId + ".epub")
      .getDownloadURL();
  }

  getChapters(library: string, filter?: string): Observable<firestore.DocumentData[]> {
    return from(
      this.fireServ.getAllContent().then((libraries: Library[]) => {
        return libraries.find((lib: Library) => lib.id == library).chapters.map((chap) => {
          if (!filter) {
            if (chap.status == "public") {
              return chap;
            }
          } else {
            if (chap.volumeNumber == parseInt(filter) && chap.status == "public") {
              return chap;
            }
          }
        }).filter((data) => data);
      }
      // getBackendData(
      //   `Collections/${library}/Chapters`,
      //   !filter
      //     ? [["status", "==", "public"]]
      //     : [
      //         ["volumeNumber", "==", parseInt(filter)],
      //         ["status", "==", "public"],
      //       ],
      //   ["data", "id"]
      ).then(async (data: Chapter[]) => {
        return Promise.all(data.map( (doc) => {
          if (doc?.reviews?.length > 0) {
            doc["avgReview"] = this.fireServ.Libraries.find((lib) => library == lib.id).avgRating;
            // doc.data["avgReview"] = await this.fireServ.getAvgReview(doc.data.reviews);
          } else {
            doc["avgReview"] = 0;
          }
          doc["data"] = doc; // Ensures downstream code can still access attributes when data does not come from getBackendData
          return doc;
        })).then((completed) => {
          return completed;
        })
      })
    );
  }

  getChaptersFromPaths(paths: string[]): Observable<firestore.DocumentData[]> {
    return from(Promise.all(paths.map((path) => {
      console.log(`Collections/${path.split('/')[0]}/Chapters/${path.split('/')[1]}`)
      return getBackendData(
        `Collections/${path.split('/')[0]}/Chapters/${path.split('/')[1]}`,
        [],
        ["data", "id"]
      ).then((data: firestore.DocumentData[]) => {
        console.log(data);
        return data;
      });
    })));
  }

  async getChapterInfo(
    chapters: (Chapter | { id: string })[],
    library: string,
    specificResource: string = ""
  ): Promise<firestore.DocumentData[][]> {
    return Promise.all(
      chapters.map(async (chap) => {
        return (await getBackendData(
          `Collections/${library}/Chapters/${chap.id}/${specificResource}`,
          [],
          ["data", "id"]
        )) as firestore.DocumentData[];
      })
    );
  }
}
