import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ChapterService } from '../../services/chapters.service';

interface Resource {
  id: string,
  title: string,
  src: any
}

@Component({
  selector: 'resource-popover',
  templateUrl: './resource-popover.component.html',
  styleUrls: ['./resource-popover.component.scss'],
})
export class ResourcePopoverComponent implements OnInit {
  @Input() resource: Resource;

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public chapServ: ChapterService
  ) {}

  ngOnInit() {
  }

  close() {
    this.modalCtrl.dismiss()
  }
}
