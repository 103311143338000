import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-logo',
  templateUrl: './home-logo.component.html',
  styleUrls: ['./home-logo.component.scss'],
})
export class HomeLogoComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {}

  home() {
    this.router.navigateByUrl("/know-bitz");
  }
}
