import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Input } from '@angular/core';

@Component({
  selector: 'app-rating-popover',
  templateUrl: './rating-popover.component.html',
  styleUrls: ['./rating-popover.component.scss'],
})
export class RatingPopoverComponent implements OnInit {
  @Input() rating: number | null;
  preRated: boolean = false;
  constructor(
    private popoverCtrl: PopoverController) { }

  ngOnInit() {
    if (this.rating) {
      document.getElementById(`star${this.rating}`).setAttribute("checked", "true");
      this.preRated = true;
    }
  }

  async submit() {
    this.popoverCtrl.dismiss({"rating": this.rating});
  }
}
