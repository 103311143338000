import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-tos-popover',
  templateUrl: './tos-popover.component.html',
  styleUrls: ['./tos-popover.component.scss'],
})
export class TOSPopoverComponent {

  public disabled: boolean = true;

  constructor(private modalCtrl: PopoverController) { }

  async dismiss(agree: boolean) {
    this.modalCtrl.dismiss({"agree": agree})
  }
}
